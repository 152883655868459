import { createSlice } from "@reduxjs/toolkit";
import { deleteStation, editStation, getStationById, getStationByRegionalId, getStationList, getStationPage, postStation } from "../../action/master/stationAction";

const initialState = {
    dataStation: [],
    isOpenStation: false,
    stateFormStation: '',
    dataStationById: '',
    idStation: '',
    isLoadingStation: false,
};
const stationSlice = createSlice({
    name: "station",
    initialState,
    reducers: {
        openFormStation: (state, action) => {
            if(action.payload.open){
                if(action.payload.type !== 'add'){
                    state.dataStationById = action.payload.data
                    state.idStation = action.payload.id
                }
            }else{
                state.dataStationById = ''
                state.idStation = ''
            }
            state.stateFormStation = action.payload.type
            state.isOpenStation = action.payload.open;
        },
    },
    extraReducers: {
      [getStationPage.pending]: (state) => {
        state.isLoadingStation = true
      },
      [getStationPage.fulfilled]: (state, action) => {
        // console.log(action.payload.data.pageable.pageNumber);
        let page = action.payload.data.pageable.pageNumber.toString()
        let result = action.payload.data.content.map( (item,index) => Object.assign({}, item, { no: page === '0' ? index+1 : page+''+(index+1) }) );
        action.payload.data.content = result
        state.dataStation = action.payload.data
        state.isLoadingStation = false
      },
      [getStationPage.rejected]: (state) => {
        state.isLoadingStation = false
      },
      [getStationList.pending]: (state) => {
        state.isLoadingStation = true
      },
      [getStationList.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataStation = action.payload.data
        state.isLoadingStation = false
      },
      [getStationList.rejected]: (state) => {
        state.isLoadingStation = false
      },
      [getStationById.pending]: (state) => {
        state.isLoadingStation = true
      },
      [getStationById.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataStation = action.payload.data
        state.isLoadingStation = false
      },
      [getStationById.rejected]: (state) => {
        state.isLoadingStation = false
      },
      [getStationByRegionalId.pending]: (state) => {
        state.isLoadingStation = true
      },
      [getStationByRegionalId.fulfilled]: (state, action) => {
        // console.log(action.payload)
        state.dataStationByRegionalId = action.payload.data
        state.isLoadingStation = false
      },
      [getStationByRegionalId.rejected]: (state) => {
        state.isLoadingStation = false
      },
      [postStation.pending]: (state) => {
        state.isLoadingStation = true
      },
      [postStation.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.isOpenStation = false
        state.isLoadingStation = false
        state.stateFormStation = ''
      },
      [postStation.rejected]: (state) => {
        state.isLoadingStation = false
      },
      [editStation.pending]: (state) => {
        state.isLoadingStation = true
      },
      [editStation.fulfilled]: (state, action) => {
        state.isOpenStation = false
        state.isLoadingStation = false
        state.dataByIdStation = []
        state.idStation = 0
        state.stateFormStation = ''
      },
      [editStation.rejected]: (state) => {
        state.isLoadingStation = false
      },
      [deleteStation.pending]: (state) => {
        state.isLoadingStation = true
      },
      [deleteStation.fulfilled]: (state, action) => {
        state.isOpenStation = false
        state.isLoadingStation = false
        state.dataByIdStation = []
        state.idStation = 0
        state.stateFormStation = ''
      },
      [deleteStation.rejected]: (state) => {
        state.isLoadingStation = false
      },
    }
});

export const {  openFormStation } = stationSlice.actions;

export default stationSlice.reducer;