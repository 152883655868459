import { createSlice } from "@reduxjs/toolkit";
import { deleteBusProvider, editBusProvider, getBusProviderById, getBusProviderByRegionalId, getBusProviderList, getBusProviderPage, postBusProvider } from "../../action/master/busproviderAction";

const initialState = {
    dataBusProvider: [],
    isOpenBusProvider: false,
    stateFormBusProvider: '',
    dataBusProviderById: '',
    idBusProvider: '',
    isLoadingBusProvider: false,
};
const busproviderSlice = createSlice({
    name: "busprovider",
    initialState,
    reducers: {
        openFormBusProvider: (state, action) => {
            if(action.payload.open){
                if(action.payload.type !== 'add'){
                    state.dataBusProviderById = action.payload.data
                    state.idBusProvider = action.payload.id
                }
            }else{
                state.dataBusProviderById = ''
                state.idBusProvider = ''
            }
            state.stateFormBusProvider = action.payload.type
            state.isOpenBusProvider = action.payload.open;
        },
        openDetailBusRoute : (state, action) => {
          state.idBusProvider = action.payload.id
          if(action.payload.state === 'bus'){
            
          }else{

          }
        }
    },
    extraReducers: {
      [getBusProviderPage.pending]: (state) => {
        state.isLoadingBusProvider = true
      },
      [getBusProviderPage.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataBusProvider = action.payload.data
        state.isLoadingBusProvider = false
      },
      [getBusProviderPage.rejected]: (state) => {
        state.isLoadingBusProvider = false
      },
      [getBusProviderList.pending]: (state) => {
        state.isLoadingBusProvider = true
      },
      [getBusProviderList.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataBusProvider = action.payload.data
        state.isLoadingBusProvider = false
      },
      [getBusProviderList.rejected]: (state) => {
        state.isLoadingBusProvider = false
      },
      [getBusProviderById.pending]: (state) => {
        state.isLoadingBusProvider = true
      },
      [getBusProviderById.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataBusProviderById = action.payload.data
        state.isLoadingBusProvider = false
      },
      [getBusProviderById.rejected]: (state) => {
        state.isLoadingBusProvider = false
      },
      [getBusProviderByRegionalId.pending]: (state) => {
        state.isLoadingBusProvider = true
      },
      [getBusProviderByRegionalId.fulfilled]: (state, action) => {
        // console.log(action.payload)
        state.dataBusProviderByRegionalId = action.payload.data
        state.isLoadingBusProvider = false
      },
      [getBusProviderByRegionalId.rejected]: (state) => {
        state.isLoadingBusProvider = false
      },
      [postBusProvider.pending]: (state) => {
        state.isLoadingBusProvider = true
      },
      [postBusProvider.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.isOpenBusProvider = false
        state.isLoadingBusProvider = false
        state.stateFormBusProvider = ''
      },
      [postBusProvider.rejected]: (state) => {
        state.isLoadingBusProvider = false
      },
      [editBusProvider.pending]: (state) => {
        state.isLoadingBusProvider = true
      },
      [editBusProvider.fulfilled]: (state, action) => {
        state.isOpenBusProvider = false
        state.isLoadingBusProvider = false
        state.dataByIdBusProvider = []
        state.idBusProvider = 0
        state.stateFormBusProvider = ''
      },
      [editBusProvider.rejected]: (state) => {
        state.isLoadingBusProvider = false
      },
      [deleteBusProvider.pending]: (state) => {
        state.isLoadingBusProvider = true
      },
      [deleteBusProvider.fulfilled]: (state, action) => {
        state.isOpenBusProvider = false
        state.isLoadingBusProvider = false
        state.dataByIdBusProvider = []
        state.idBusProvider = 0
        state.stateFormBusProvider = ''
      },
      [deleteBusProvider.rejected]: (state) => {
        state.isLoadingBusProvider = false
      },
    }
});

export const {  openFormBusProvider, openDetailBusRoute } = busproviderSlice.actions;

export default busproviderSlice.reducer;