import { createSlice } from "@reduxjs/toolkit";
import { deleteEmployee, downloadTemplateEmployee, editEmployee, getEmployeeById, getEmployeeByRegionalId, getEmployeeList, getEmployeePage, getEmployeeSearch, postEmployee, uploadEmployee } from "../../action/master/employeeAction";

const initialState = {
    dataEmployee: [],
    isOpenEmployee: false,
    stateFormEmployee: '',
    dataEmployeeById: '',
    idEmployee: '',
    fileDownload: '',
    isLoadingEmployee: false,
};
const employeeSlice = createSlice({
    name: "employee",
    initialState,
    reducers: {
        openFormEmployee: (state, action) => {
            if(action.payload.open){
                if(action.payload.type !== 'add'){
                    state.dataEmployeeById = action.payload.data
                    state.idEmployee = action.payload.id
                }
            }else{
                state.dataEmployeeById = ''
                state.idEmployee = ''
            }
            state.stateFormEmployee = action.payload.type
            state.isOpenEmployee = action.payload.open;
        },
    },
    extraReducers: {
      [getEmployeePage.pending]: (state) => {
        state.isLoadingEmployee = true
      },
      [getEmployeePage.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataEmployee = action.payload.data
        state.isLoadingEmployee = false
      },
      [getEmployeePage.rejected]: (state) => {
        state.isLoadingEmployee = false
      },
      [getEmployeeList.pending]: (state) => {
        state.isLoadingEmployee = true
      },
      [getEmployeeList.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataEmployee = action.payload.data
        state.isLoadingEmployee = false
      },
      [getEmployeeList.rejected]: (state) => {
        state.isLoadingEmployee = false
      },
      [getEmployeeSearch.pending]: (state) => {
        state.isLoadingEmployee = true
      },
      [getEmployeeSearch.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataEmployee = action.payload.data
        state.isLoadingEmployee = false
      },
      [getEmployeeSearch.rejected]: (state) => {
        state.isLoadingEmployee = false
      },
      [getEmployeeById.pending]: (state) => {
        state.isLoadingEmployee = true
      },
      [getEmployeeById.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataEmployeeById = action.payload.data
        state.isLoadingEmployee = false
      },
      [getEmployeeById.rejected]: (state) => {
        state.isLoadingEmployee = false
      },
      [getEmployeeByRegionalId.pending]: (state) => {
        state.isLoadingEmployee = true
      },
      [getEmployeeByRegionalId.fulfilled]: (state, action) => {
        // console.log(action.payload)
        state.dataEmployeeByRegionalId = action.payload.data
        state.isLoadingEmployee = false
      },
      [getEmployeeByRegionalId.rejected]: (state) => {
        state.isLoadingEmployee = false
      },
      [postEmployee.pending]: (state) => {
        state.isLoadingEmployee = true
      },
      [postEmployee.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.isOpenEmployee = false
        state.isLoadingEmployee = false
        state.stateFormEmployee = ''
      },
      [postEmployee.rejected]: (state) => {
        state.isLoadingEmployee = false
      },
      [editEmployee.pending]: (state) => {
        state.isLoadingEmployee = true
      },
      [editEmployee.fulfilled]: (state, action) => {
        state.isOpenEmployee = false
        state.isLoadingEmployee = false
        state.dataByIdEmployee = []
        state.idEmployee = 0
        state.stateFormEmployee = ''
      },
      [editEmployee.rejected]: (state) => {
        state.isLoadingEmployee = false
      },
      [deleteEmployee.pending]: (state) => {
        state.isLoadingEmployee = true
      },
      [deleteEmployee.fulfilled]: (state, action) => {
        state.isOpenEmployee = false
        state.isLoadingEmployee = false
        state.dataByIdEmployee = []
        state.idEmployee = 0
        state.stateFormEmployee = ''
      },
      [deleteEmployee.rejected]: (state) => {
        state.isLoadingEmployee = false
      },

      [downloadTemplateEmployee.pending]: (state) => {
        state.isLoadingDownload = true
      },
      [downloadTemplateEmployee.fulfilled]: (state, action) => {
        // console.log(action.payload)
        const url = window.URL.createObjectURL(new Blob([action.payload]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `Template-Enrollment.xlsx`)

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
        // state.fileDownload = action.payload
        state.isLoadingDownload = false
      },
      [downloadTemplateEmployee.rejected]: (state) => {
        state.isLoadingDownload = false
      },

      [uploadEmployee.pending]: (state) => {
        state.isLoadingEmployee = true
      },
      [uploadEmployee.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.isOpenEmployee = false
        state.isLoadingEmployee = false
      },
      [uploadEmployee.rejected]: (state) => {
        state.isLoadingEmployee = false
      },
    }
});

export const {  openFormEmployee } = employeeSlice.actions;

export default employeeSlice.reducer;