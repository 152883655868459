import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_MASTER } from '../../../constants'
import { openMessage } from '../../slice/messageSlice'
// import { openMessage } from '../slice/messageSlice'
// import { openForm } from '../slice/api/routeSlice'

export const getRoutePage = createAsyncThunk('route/page', async (param, thunkAPI) => {
  const respone = await fetch(
    `${API_MASTER}/api/route/page?page=${param.page}&size=${param.size}&busProviderId=${param.filter[0].busProvider}&name=${param.filter[0].name}&source=${param.filter[0].source}&destination=${param.filter[0].destination}`,
    {
      method: 'get',
      headers: new Headers({
        Authorization: 'Bearer ' + param.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      }),
    },
  )
  const responeResult = await respone.json()
  // console.log("as")
  return responeResult
})
export const getRouteStationPage = createAsyncThunk('route-station/page', async (param, thunkAPI) => {
  const respone = await fetch(
    `${API_MASTER}/api/route/station/page?page=${param.page}&size=${param.size}&idRoute=${param.filter[0].route}&stationName=${param.filter[0].name}`,
    {
      method: 'get',
      headers: new Headers({
        Authorization: 'Bearer ' + param.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      }),
    },
  )
  const responeResult = await respone.json()
  // console.log("as")
  return responeResult
})
export const getRouteById = createAsyncThunk('route/byId', async (param, thunkAPI) => {
  // console.log(param);
  const response = await fetch(`${API_MASTER}/api/route/${param.id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + param.token,
    },
  })
  if (response.status === 200) {
    const responseJson = await response.json()
    return responseJson
  }else{
    return {data: ''}
  }
})
export const getRouteStationById = createAsyncThunk('route/station/byId', async (param, thunkAPI) => {
  // console.log(param);
  const response = await fetch(`${API_MASTER}/api/route/station/${param.id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + param.token,
    },
  })
  if (response.status === 200) {
    const responseJson = await response.json()
    return responseJson
  }
})
export const getRouteByRegionalId = createAsyncThunk('route/regionalId', async (param, thunkAPI) => {
  // console.log(param);
  const response = await fetch(`${API_MASTER}/api/route?regionalId=${param.regional}`, {
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
  })
  if (response.status === 200) {
    const responseJson = await response.json()
    return responseJson
  }
})
export const getRouteList = createAsyncThunk('route/list', async (param, thunkAPI) => {
  const respone = await fetch(`${API_MASTER}/api/route/`, {
    method: 'get',
    headers: new Headers({
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    }),
  })
  const responeResult = await respone.json()
  // console.log("as")
  return responeResult
})
export const getRouteListByIdBusProvider = createAsyncThunk('route/list/byId/busProvider', async (param, thunkAPI) => {
  const respone = await fetch(`${API_MASTER}/api/route/providerId/${param.idBusProvider}`, {
    // const respone = await fetch(`${API_MASTER}/api/route/`, {
    method: 'get',
    headers: new Headers({
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    }),
  })
  const responeResult = await respone.json()
  // console.log("as")
  return responeResult
})
export const postRoute = createAsyncThunk('route/post', async (param, thunkAPI) => {
  // console.log(param);
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
    body: JSON.stringify(param.data),
  }
  let response = await fetch(`${API_MASTER}/api/route`, requestOptions)
  if (response.status === 200) {
    console.log(response.status)
    const responseJson = await response.json()
    let bodyStation = []
    for(let i = 0 ; i < param.stationData.length ; i++){
      bodyStation.push({
        idRoute : responseJson.data.id,
        idStation : param.stationData[i],
        orderNumber : i+1,
        geoFencingStart : param.station[i].geoFencingStart,
        geoFencingEnd : param.station[i].geoFencingEnd,
      })
    }
    const requestOptionsStation = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + param.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      },
      body: JSON.stringify(bodyStation),
    }
    let responseStation = await fetch(`${API_MASTER}/api/route/station`, requestOptionsStation)
    if (responseStation.status === 200) {
      thunkAPI.dispatch(
        openMessage({
          message: 'Success Insert Route!',
          type: 'success',
          open: true,
        }),
      )
      thunkAPI.dispatch(getRoutePage(param))
      return responseJson
    }
    if (responseStation.status === 400) {
      thunkAPI.dispatch(openMessage({ message: 'Insert Route Failed', type: 'danger', open: true }))
    }
  }
  if (response.status === 400) {
    thunkAPI.dispatch(openMessage({ message: 'Insert Route Failed', type: 'danger', open: true }))
  }
})
export const editRoute = createAsyncThunk('route/update', async (param, thunkAPI) => {
  console.log(param.oldStationData);
  let oldArr = []
  param.oldStationData.map((key,index)=>{
    oldArr.push(key.idStation)
  })
  let arr2 = []
  for (let i = 0; i < oldArr.length; i++) {
    // Memeriksa apakah elemen tersebut tidak ada di array2
    if (param.stationData.indexOf(oldArr[i]) === -1) {
      var result = param.oldStationData.filter(function(item) {
        return item.idStation === oldArr[i];
      });
      console.log(param.oldStationData[i])
      const requestOptions = {
        method: 'Delete',
        headers: {
          Authorization: 'Bearer ' + param.token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
        },
      }
      await fetch(`${API_MASTER}/api/route/station/${result[0].id}`, requestOptions)
    }
  }

  // Mengecek setiap elemen dalam array2
  for (let j = 0; j < param.stationData.length; j++) {
    // Memeriksa apakah elemen tersebut tidak ada di array1
    if (oldArr.indexOf(param.stationData[j]) === -1) {
      arr2.push(param.stationData[j]);
    }
  }
  let bodyStation = []
  for(let i = 0 ; i < arr2.length ; i++){
    let poseidon = param.station.filter(item => item.id === arr2[i])
    bodyStation.push({
      idRoute : param.id,
      idStation : arr2[i],
      orderNumber : i+1,
      geoFencingStart : poseidon[0].geoFencingStart,
      geoFencingEnd : poseidon[0].geoFencingEnd,
    })
  }
  console.log(bodyStation)
  const requestOptionsStation = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
    body: JSON.stringify(bodyStation),
  }
  await fetch(`${API_MASTER}/api/route/station`, requestOptionsStation)

  const requestOptions = {
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
    body: JSON.stringify(param.data),
  }
  let response = await fetch(`${API_MASTER}/api/route/${param.id}`, requestOptions)
  if (response.status === 200) {
    thunkAPI.dispatch(
      openMessage({ message: 'Edit Route Successfull', type: 'success', open: true })
    );
    thunkAPI.dispatch(getRoutePage(param))
  }
  // .then(getApi())
})
export const deleteRoute = createAsyncThunk('route/delete', async (param, thunkAPI) => {
  // console.log(param);
  const requestOptions = {
    method: 'Delete',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
  }
  let response = await fetch(`${API_MASTER}/api/route/${param.id}`, requestOptions)
  if (response.status === 200) {
    console.log(response.status)
    const responseJson = await response.json()

    thunkAPI.dispatch(
      openMessage({
        message: 'Success Delete Route!',
        type: 'success',
        open: true,
      }),
    )
    thunkAPI.dispatch(getRoutePage(param))
    return responseJson
  }
  if (response.status === 400) {
    thunkAPI.dispatch(openMessage({ message: 'Delete Route Failed', type: 'danger', open: true }))
  }
  // .then(getApi())
})
export const getRouteStationByIdRoute = createAsyncThunk('route/station/routeId', async (param, thunkAPI) => {
  console.log(param);
  const response = await fetch(`${API_MASTER}/api/route/station/routeId/${param.routeId}`, {
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
  })
  if (response.status === 200) {
    const responseJson = await response.json()
    return responseJson
  }
})
export const editRouteStation = createAsyncThunk('route/station/edit/id', async (param, thunkAPI) => {
  // console.log(param);
  const requestOptions = {
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
    body: JSON.stringify(param.body),
  }
  let response = await fetch(`${API_MASTER}/api/route/station/${param.id}`, requestOptions)
  if (response.status === 200) {
    // thunkAPI.dispatch(
    //   openMessage({ message: 'Edit Site Successfull', type: 'success', open: true })
    // );
  }
  // .then(getApi())
})