import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ModalComponent from '../../../../components/Content/Modal'
import { openFormDevice } from '../../../../redux/slice/master/deviceSlice'
import * as Yup from 'yup'
import { Formik, useFormikContext } from 'formik'
import { Col, FormFeedback, FormGroup, Input, Label } from 'reactstrap'
import { useDispatch } from 'react-redux'
import Alert from '../../../../components/Content/Alert'
import { deleteDevice, editDevice, postDevice } from '../../../../redux/action/master/deviceAction'
import Select from 'react-select'
import { useEffect } from 'react'
import { getBusProviderById, getBusProviderList } from '../../../../redux/action/master/busproviderAction'
import { useTranslation } from 'react-i18next'
import { getBusById, getBusList } from '../../../../redux/action/master/busAction'
import styled, { css, keyframes } from 'styled-components'
import { getRouteById, getRouteList } from '../../../../redux/action/master/routeAction'

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;
const Content = styled.div`
${props => props.expanded ?
    `height: auto;` :
    `height: 0;`
}
${props =>
    props.expanded
    ? css`
        animation: ${fadeIn} 0.3s ease;
    `
    : css`
        animation: ${fadeOut} 0.3s ease;
        opacity: 0;
    `};
`;
const FormDevice = ({param}) => {
    const { isOpenDevice, idDevice, dataDeviceById, stateFormDevice, isLoadingDevice } = useSelector((state)=>state.device)
    const { dataBus, dataBusById } = useSelector((state)=>state.bus)
    const { dataRoute, dataRouteById } = useSelector((state)=>state.route)
    const [selected, setSelected] = useState([{bus: '', route: ''}])
    const [dataSelect, setSelect] = useState([{bus: '', route: ''}])
    const [expanded, setExpanded] = useState(false)
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const [catSelected, setCatSelected] = useState()
    const handleFormSubmit = (values) =>{
        // console.log(values)
        console.log(values)
        param.data = values
        if(stateFormDevice === 'add'){
            dispatch(postDevice(param))
        }else if(stateFormDevice === 'edit'){
            param.id = idDevice
            dispatch(editDevice(param))
        }else{
            param.id = idDevice
            dispatch(deleteDevice(param))
        }
    }
    const initialValues = {
        name: idDevice ==='' ? '': dataDeviceById.name,
        category: idDevice ==='' ? '': dataDeviceById.category,
        serialNumber: idDevice ==='' ? '': dataDeviceById.serialNumber,
        descr: idDevice ==='' ? '': dataDeviceById.descr,
        idBus: idDevice ==='' ? '': dataDeviceById.idBus,
        noSim: idDevice ==='' ? '': dataDeviceById.noSim,
        masaBerlaku: idDevice ==='' ? '': dataDeviceById.masaBerlaku,
        jumlahQuota: idDevice ==='' ? '': dataDeviceById.jumlahQuota,
        idRoute: idDevice === '' ? '': dataBusById.idRoute,
    }
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required!'),
        serialNumber: Yup.string().required('Serial Number is required!'),
        category: Yup.string().required('Category is required!'),
        descr: Yup.string().required('Description is required!'),
        idBus: Yup.string().required('Bus is required!'),
        idRoute: Yup.string().required('Route is required!'),
    })
    useEffect(()=>{
        let arr = [...selected]
        if(dataDeviceById !== ''){
            // console.log(dataDeviceById.category)
            setCatSelected({label: dataDeviceById.category, value: dataDeviceById.category})
        }
        if (dataBusById !== '') {
            if(stateFormDevice === 'edit'){
                arr[0].bus = {label: dataBusById.vehicleLicensePlate+' - '+dataBusById.providerName, value: dataBusById.id+'-'+dataBusById.idRoute}
            }
            if(dataBusById.idRoute !== null){
                let routeByBusId = dataSelect[0].route.find(item => item.value === dataBusById.idRoute)
                arr[0].route = routeByBusId
                console.log(routeByBusId)
            }
        }
        setSelected(arr)
    },[dataDeviceById, dataBusById])
      
    useEffect(()=>{ 
        dispatch(getBusList(param))
        dispatch(getRouteList(param))
    },[])

    useEffect(() => {
        if(stateFormDevice === 'edit'){
            param.id = dataDeviceById.idBus
            dispatch(getBusById(param))
        }else{
            let arr = [...selected]
            arr[0].bus = ''
            arr[0].route = ''
            setSelected(arr)
        }
    },[isOpenDevice])
    useEffect(() => {
        let arr = [...dataSelect]
        if (dataBus.length > 0) {
            let newArr = dataBus.map(({ id, providerName, vehicleLicensePlate, idRoute }) => ({ value: id+'-'+idRoute, label: vehicleLicensePlate+' - '+providerName }))
            // console.log(newArr)
            arr[0].bus = newArr
        }
        if (dataRoute.length > 0){
            let newArr = dataRoute.map(({ id, name }) => ({ value: id, label: name }))
            // console.log(newArr)
            arr[0].route = newArr
        }

        setSelect(arr)
    },[dataBus, dataRoute])
    return stateFormDevice === 'delete' ? (
        <Alert 
            isOpen={isOpenDevice} 
            action={handleFormSubmit}
            text={t("Are You Sure for Delete This Data ?")}
            toggle={()=>{dispatch(openFormDevice({open: !isOpenDevice, type: ''}))}} 
            close={()=>{dispatch(openFormDevice({open: !isOpenDevice, type: ''}))}} />
    ) : (
        <ModalComponent 
            isOpen={isOpenDevice} 
            close={()=>{dispatch(openFormDevice({open: !isOpenDevice, type: ''}))}}
            title={"Form Data "+t('Device')}
            idForm="device-form"
            size="lg"
            toggle={()=>{dispatch(openFormDevice({open: true, type: 'add'}))}}
            isLoading={isLoadingDevice}
        >
            <Formik 
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit} id="device-form">
                        <div className='row'>
                            <Col xs={6} sm={6} lg={6} md={6}>
                                <FormGroup className='mb-3'>
                                    <Label>{t('Name')}</Label>
                                    <Input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.name && !!errors.name}
                                    />
                                    <FormFeedback>{errors.name}</FormFeedback>
                                </FormGroup>
                                <FormGroup className='mb-3'>
                                    <Label>{t('Serial Number')}</Label>
                                    <Input
                                        type="text"
                                        id="serialNumber"
                                        name="serialNumber"
                                        value={values.serialNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.serialNumber && !!errors.serialNumber}
                                    />
                                    <FormFeedback>{errors.serialNumber}</FormFeedback>
                                </FormGroup>
                                <FormGroup className='mb-3'>
                                    <Label>{t("Bus")}</Label>
                                    <Select
                                        styles={{
                                            control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor:
                                                touched.idBus && errors.idBus
                                                ? 'red' : 'rgba(0,0,0,.24)',
                                            width: '100%',
                                            appearance: 'auto',
                                            }),
                                        }}
                                        onChange={(e)=>{
                                            let arr = [...selected]
                                            let route = e.value.split('-')[1]
                                            let bus = e.value.split('-')[0]
                                            handleChange({
                                                target: { name: 'idBus', value: bus },
                                            })
                                            if(route !== 'null'){
                                                handleChange({
                                                    target: { name: 'idRoute', value: route },
                                                })
                                                dispatch(getBusById({id: e.value.split('-')[0], token: param.token}))
                                            }else{
                                                handleChange({
                                                    target: { name: 'idRoute', value: '' },
                                                })
                                                arr[0].route = ''
                                            }
                                            console.log(arr)
                                            arr[0].bus = e
                                            setSelected(arr)
                                            // getRouteByBusId(e.value, handleChange)
                                            // console.log(labelRuas)
                                            // console.log(e.value)
                                            // handleChange(e)
                                        }}
                                        name="idBus"
                                        id="idBus"
                                        value={selected[0].bus}
                                        options={dataSelect[0].bus}
                                    />
                                </FormGroup>
                                <div className='row'>
                                    <Col xs={6} sm={6} lg={6} md={6}>
                                        <FormGroup className='mb-3'>
                                            <Input
                                                type="text"
                                                id="vehicleLicense"
                                                name="vehicleLicense"
                                                value={selected[0].bus !== '' ? selected[0].bus.label.split(' - ')[0] : ''}
                                                placeholder='Vehicle License'
                                                readOnly
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6} sm={6} lg={6} md={6}>
                                        <FormGroup className='mb-3'>
                                            <Input
                                                type="text"
                                                id="provider"
                                                name="provider"
                                                value={selected[0].bus !== '' ? selected[0].bus.label.split(' - ')[1] : ''}
                                                placeholder='Provider Bus'
                                                readOnly
                                            />
                                        </FormGroup>
                                    </Col>
                                </div>
                                <FormGroup className='mb-3'>
                                    <Label>{t('Description')}</Label>
                                    <Input
                                        type="text"
                                        id="descr"
                                        name="descr"
                                        value={values.descr}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.descr && !!errors.descr}
                                    />
                                    <FormFeedback>{errors.descr}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col xs={6} sm={6} lg={6} md={6}>
                                <FormGroup className='mb-3'>
                                    <Label>{t("Route")}</Label>
                                    <Select
                                        styles={{
                                            control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor:
                                                touched.idRoute && errors.idRoute
                                                ? 'red' : 'rgba(0,0,0,.24)',
                                            width: '100%',
                                            appearance: 'auto',
                                            }),
                                        }}
                                        onChange={(e)=>{
                                            let arr = [...selected]
                                            handleChange({
                                                target: { name: 'idRoute', value: e.value },
                                            })
                                            arr[0].route = e
                                            setSelected(arr)
                                            // console.log(labelRuas)
                                            // console.log(e.value)
                                            // handleChange(e)
                                        }}
                                        name="idRoute"
                                        id="idRoute"
                                        value={selected[0].route}
                                        options={dataSelect[0].route}
                                    />
                                </FormGroup>
                                <FormGroup className='mb-3'>
                                    <Label>{t('Category')}</Label>
                                    <Select
                                        styles={{
                                            control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor:
                                                touched.category && errors.category
                                                ? 'red' : 'rgba(0,0,0,.24)',
                                            width: '100%',
                                            appearance: 'auto',
                                            }),
                                        }}
                                        onChange={(e)=>{
                                            handleChange({
                                                target: { name: 'category', value: e.value },
                                            })
                                            setCatSelected(e)
                                            if(e.value === 'Bus Device'){
                                                setExpanded(true)
                                            }else{
                                                setExpanded(false)
                                            }
                                            // console.log(labelRuas)
                                            // console.log(e.value)
                                            // handleChange(e)
                                        }}
                                        name="category"
                                        id="category"
                                        value={catSelected}
                                        options={[{label: 'ALPR', value: 'ALPR'}, {label: 'Bus Device', value: 'Bus Device'}]}
                                    />
                                </FormGroup>
                                
                                <Content expanded={expanded}>    
                                    <FormGroup className='mb-3'>
                                        <Label>{t('No. SIM')}</Label>
                                        <Input
                                            type="text"
                                            id="noSim"
                                            name="noSim"
                                            value={values.noSim}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={touched.noSim && !!errors.noSim}
                                        />
                                        <FormFeedback>{errors.noSim}</FormFeedback>
                                    </FormGroup>

                                    <FormGroup className='mb-3'>
                                        <Label>{t('Expired Time')}</Label>
                                        <Input
                                            type="date"
                                            id="masaBerlaku"
                                            name="masaBerlaku"
                                            value={values.masaBerlaku}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={touched.masaBerlaku && !!errors.masaBerlaku}
                                        />
                                        <FormFeedback>{errors.masaBerlaku}</FormFeedback>
                                    </FormGroup>

                                    <FormGroup className='mb-3'>
                                        <Label>{t('Quota')}</Label>
                                        <Input
                                            type="text"
                                            id="jumlahQuota"
                                            name="jumlahQuota"
                                            value={values.jumlahQuota}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={touched.jumlahQuota && !!errors.jumlahQuota}
                                        />
                                        <FormFeedback>{errors.jumlahQuota}</FormFeedback>
                                    </FormGroup>
                                </Content>
                            </Col>
                        </div>
                    </form>
                )}
            </Formik>
        </ModalComponent>
    )
}

export default FormDevice