import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_BUS, API_MASTER } from '../../constants'
// import { openMessage } from '../slice/messageSlice'
// import { openForm } from '../slice/api/areaSlice'

export const getNotificationBus = createAsyncThunk('bus/notif', async (param, thunkAPI) => {
  // console.log(param)
  const respone = await fetch(
    `${API_BUS}/api/push-notif/all?page=0&sort=createdAt,desc`,
    // `${API_MASTER}/api/map?routeId=13&providerId=8`,
    {
      method: 'get',
      headers: new Headers({
        Authorization: 'Bearer ' + param.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      }),
    },
  )
  const responeResult = await respone.json()
  // console.log("as")
  return responeResult
})
export const getNotificationBusList = createAsyncThunk('bus/notif/list', async (param, thunkAPI) => {
  // console.log(param)
  const respone = await fetch(
    `${API_BUS}/api/push-notif/all?page=${param.page}&size=${param.size}&sort=createdAt,desc`,
    // `${API_MASTER}/api/map?routeId=13&providerId=8`,
    {
      method: 'get',
      headers: new Headers({
        Authorization: 'Bearer ' + param.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      }),
    },
  )
  const responeResult = await respone.json()
  // console.log("as")
  return responeResult
})
export const updateStatusRead = createAsyncThunk('bus/update/status', async (param, thunkAPI) => {
  // console.log(param)
  const respone = await fetch(
    `${API_BUS}/api/push-notif/read/${param.id}`,
    // `${API_MASTER}/api/map?routeId=13&providerId=8`,
    {
      method: 'get',
      headers: new Headers({
        Authorization: 'Bearer ' + param.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      }),
    },
  )
  const responeResult = await respone.json()
  // console.log("as")
  thunkAPI.dispatch(getNotificationBus(param))
  thunkAPI.dispatch(getNotificationBusList(param))
  return responeResult
})