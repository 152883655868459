import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ModalComponent from '../../../../components/Content/Modal'
import { openFormBus } from '../../../../redux/slice/master/busSlice'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Col, FormFeedback, FormGroup, Input, Label } from 'reactstrap'
import { useDispatch } from 'react-redux'
import Alert from '../../../../components/Content/Alert'
import { deleteBus, editBus, postBus } from '../../../../redux/action/master/busAction'
import Select from 'react-select'
import { getRouteById, getRouteList } from '../../../../redux/action/master/routeAction'
import { getBusProviderById, getBusProviderList } from '../../../../redux/action/master/busproviderAction'
import { useTranslation } from 'react-i18next'

const FormBus = ({param}) => {
    const {t} = useTranslation()
    const { isOpenBus, idBus, dataBusById, stateFormBus, isLoadingBus } = useSelector((state)=>state.bus)
    const { dataBusProvider, dataBusProviderById } = useSelector((state)=>state.busProvider)
    const { dataRoute, dataRouteById } = useSelector((state)=>state.route)

    const dispatch = useDispatch()
    const handleFormSubmit = (values) =>{
        // console.log(values)
        param.data = values
        if(stateFormBus === 'add'){
            dispatch(postBus(param))
        }else if(stateFormBus === 'edit'){
            param.id = idBus
            dispatch(editBus(param))
        }else{
            param.id = idBus
            dispatch(deleteBus(param))
        }
    }
    const [selected, setSelected] = useState([{busProvider: '', route: ''}])
    const [dataSelect, setSelect] = useState([{busProvider: '', route: ''}])
    const initialValues = {
        vehicleLicensePlate: idBus ==='' ? '': dataBusById.vehicleLicensePlate,
        capacityNumber: idBus ==='' ? '': dataBusById.capacityNumber,
        manufactur: idBus ==='' ? '': dataBusById.manufactur,
        idBusProvider: idBus ==='' ? '': dataBusById.idBusProvider,
        idRoute: idBus === '' ? '': dataBusById.idRoute
    }
    console.log(selected)
    const validationSchema = Yup.object().shape({
        vehicleLicensePlate: Yup.string().required('Vehicle License is required!'),
        capacityNumber: Yup.string().required('Capacity Number is required!'),
        manufactur: Yup.string().required('Manufactur is required!'),
        idBusProvider: Yup.string().required('Bus Provider is required!'),
        idRoute: Yup.string().required('Route is required!'),
      })
    useEffect(()=>{
        dispatch(getBusProviderList(param))
        dispatch(getRouteList(param))
    },[])

    useEffect(() => {
        let arr = [...dataSelect]
        if (dataBusProvider.length > 0) {
            let newArr = dataBusProvider.map(({ id, name }) => ({ value: id, label: name }))
            // console.log(newArr)
            arr[0].busProvider = newArr
        }
        if (dataRoute.length > 0){
            let newArr = dataRoute.map(({ id, name }) => ({ value: id, label: name }))
            // console.log(newArr)
            arr[0].route = newArr
        }
        setSelect(arr)
    },[dataBusProvider, dataRoute])
    useEffect(() => {
        if(stateFormBus === 'edit'){
            param.id = dataBusById.idBusProvider
            dispatch(getBusProviderById(param))
            if(dataRouteById !== null){
                param.id = dataBusById.idRoute
                dispatch(getRouteById(param))
            }
        }else{
            let arr = [...selected]
            arr[0].busProvider = ''
            arr[0].route = ''
            setSelected(arr)
        }
    },[isOpenBus])
    useEffect(() => {
        let arr = [...selected]
        if (dataBusProviderById !== '') {
            if(stateFormBus === 'edit'){
                arr[0].busProvider = {label: dataBusProviderById.name, value: dataBusProviderById.id}
            }else{
                arr[0].busProvider = ''
            }
        }
        if (dataRouteById !== '') {
            if(stateFormBus === 'edit'){
                arr[0].route = {label: dataRouteById.name, value: dataRouteById.id}
            }else{
                arr[0].route = ''
            }
        }

        setSelected(arr)
    },[dataBusProviderById, dataRouteById])
    return stateFormBus === 'delete' ? (
        <Alert 
            isOpen={isOpenBus} 
            action={handleFormSubmit}
            text={t("Are You Sure for Delete This Data ?")}
            toggle={()=>{dispatch(openFormBus({open: !isOpenBus, type: ''}))}} 
            close={()=>{dispatch(openFormBus({open: !isOpenBus, type: ''}))}} />
    ) : (
        <ModalComponent 
            isOpen={isOpenBus} 
            close={()=>{dispatch(openFormBus({open: !isOpenBus, type: ''}))}}
            title={"Form Data "+t("Bus")}
            idForm="bus-form"
            toggle={()=>{dispatch(openFormBus({open: true, type: 'add'}))}}
            isLoading={isLoadingBus}
        >
            <Formik 
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit} id="bus-form">
                        <div className='row'>
                            <Col xs={12} sm={12} lg={12} md={12}>
                                <FormGroup className='mb-3'>
                                    <Label>{t("Vehicle License")}</Label>
                                    <Input
                                        type="text"
                                        id="vehicleLicensePlate"
                                        name="vehicleLicensePlate"
                                        value={values.vehicleLicensePlate}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.vehicleLicensePlate && !!errors.vehicleLicensePlate}
                                    />
                                    <FormFeedback>{errors.vehicleLicensePlate}</FormFeedback>
                                </FormGroup>
                                <FormGroup className='mb-3'>
                                    <Label>{t("Capacity")}</Label>
                                    <Input
                                        type="number"
                                        id="capacityNumber"
                                        name="capacityNumber"
                                        value={values.capacityNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.capacityNumber && !!errors.capacityNumber}
                                    />
                                    <FormFeedback>{errors.capacityNumber}</FormFeedback>
                                </FormGroup>
                                <FormGroup className='mb-3'>
                                    <Label>{t("Manufactur")}</Label>
                                    <Input
                                        type="text"
                                        id="manufactur"
                                        name="manufactur"
                                        value={values.manufactur}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.manufactur && !!errors.manufactur}
                                    />
                                    <FormFeedback>{errors.manufactur}</FormFeedback>
                                </FormGroup>
                                <FormGroup className='mb-3'>
                                    <Label>{t("Bus Provider")}</Label>
                                    <Select
                                        styles={{
                                            control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor:
                                                touched.idBusProvider && errors.idBusProvider
                                                ? 'red' : 'rgba(0,0,0,.24)',
                                            width: '100%',
                                            appearance: 'auto',
                                            }),
                                        }}
                                        onChange={(e)=>{
                                            let arr = [...selected]
                                            handleChange({
                                                target: { name: 'idBusProvider', value: e.value },
                                            })
                                            arr[0].busProvider = e
                                            setSelected(arr)
                                            // console.log(labelRuas)
                                            // console.log(e.value)
                                            // handleChange(e)
                                        }}
                                        name="idBusProvider"
                                        id="idBusProvider"
                                        value={selected[0].busProvider}
                                        options={dataSelect[0].busProvider}
                                    />
                                </FormGroup>
                                <FormGroup className='mb-3'>
                                    <Label>{t("Route")}</Label>
                                    <Select
                                        styles={{
                                            control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor:
                                                touched.idRoute && errors.idRoute
                                                ? 'red' : 'rgba(0,0,0,.24)',
                                            width: '100%',
                                            appearance: 'auto',
                                            }),
                                        }}
                                        onChange={(e)=>{
                                            let arr = [...selected]
                                            handleChange({
                                                target: { name: 'idRoute', value: e.value },
                                            })
                                            arr[0].route = e
                                            setSelected(arr)
                                            // console.log(labelRuas)
                                            // console.log(e.value)
                                            // handleChange(e)
                                        }}
                                        name="idRoute"
                                        id="idRoute"
                                        value={selected[0].route}
                                        options={dataSelect[0].route}
                                    />
                                </FormGroup>
                                {/* <FormGroup className='mb-3'>
                                    <Label>{t('Route')}</Label>
                                    <Select
                                        styles={{
                                            control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor:
                                                touched.idRoute && errors.idRoute
                                                ? 'red' : 'rgba(0,0,0,.24)',
                                            width: '100%',
                                            appearance: 'auto',
                                            }),
                                        }}
                                        onChange={(e)=>{
                                            setRoute(e)
                                            // console.log(labelRuas)
                                            // console.log(e.value)
                                            // handleChange(e)
                                        }}
                                        name="idRoute"
                                        id="idRoute"
                                        value={idRoutes}
                                        options={routeData}
                                    />
                                </FormGroup> */}
                            </Col>
                        </div>
                    </form>
                )}
            </Formik>
        </ModalComponent>
    )
}

export default FormBus