import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_BUS, API_MASTER } from '../../constants'
// import { openMessage } from '../slice/messageSlice'
// import { openForm } from '../slice/api/areaSlice'

export const getMapStationDashboard = createAsyncThunk('dashboard/map/station', async (param, thunkAPI) => {
  const respone = await fetch(
    `${API_MASTER}/api/map?routeId=${param.idRoute}&providerId=${param.idBusProvider}`,
    // `${API_MASTER}/api/map?routeId=13&providerId=8`,
    {
      method: 'get',
      headers: new Headers({
        Authorization: 'Bearer ' + param.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      }),
    },
  )
  const responeResult = await respone.json()
  // console.log("as")
  return responeResult
})
export const getPieChartDashboard = createAsyncThunk('dashboard/chart/pie', async (param, thunkAPI) => {
    const respone = await fetch(
        `${API_MASTER}/api/dashboard/bus/chart`,
        // `${API_MASTER}/api/map?routeId=13&providerId=8`,
        {
        method: 'get',
        headers: new Headers({
            Authorization: 'Bearer ' + param.token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
        }),
        },
    )
    const responeResult = await respone.json()
    // console.log("as")
    return responeResult
})
export const getBarChartDashboard = createAsyncThunk('dashboard/chart/bar', async (param, thunkAPI) => {
    const respone = await fetch(
        `${API_MASTER}/api/dashboard/route/chart`,
        // `${API_MASTER}/api/map?routeId=13&providerId=8`,
        {
        method: 'get',
        headers: new Headers({
            Authorization: 'Bearer ' + param.token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
        }),
        },
    )
    const responeResult = await respone.json()
    // console.log("as")
    return responeResult
})
export const getBarDashboard = createAsyncThunk('dashboard/bar', async (param, thunkAPI) => {
    const respone = await fetch(
        `${API_MASTER}/api/dashboard/bar`,
        // `${API_MASTER}/api/map?routeId=13&providerId=8`,
        {
        method: 'get',
        headers: new Headers({
            Authorization: 'Bearer ' + param.token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
        }),
        },
    )
    const responeResult = await respone.json()
    // console.log("as")
    return responeResult
})
export const getBusShiftDashboard = createAsyncThunk('dashboard/bus/shift', async (param, thunkAPI) => {
    const respone = await fetch(
        `${API_BUS}/api/dashboard/bus/shift?date=${param.date}`,
        // `${API_MASTER}/api/map?routeId=13&providerId=8`,
        {
            method: 'get',
            headers: new Headers({
                Authorization: 'Bearer ' + param.token,
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': 'true',
            }),
        },
    )
    const responeResult = await respone.json()
    // console.log("as")
    return responeResult
})
export const getEmployeeShiftDashboard = createAsyncThunk('dashboard/employee/shift', async (param, thunkAPI) => {
    const respone = await fetch(
        `${API_BUS}/api/dashboard/employee/shift?date=${param.date}`,
        // `${API_MASTER}/api/map?routeId=13&providerId=8`,
        {
            method: 'get',
            headers: new Headers({
                Authorization: 'Bearer ' + param.token,
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': 'true',
            }),
        },
    )
    const responeResult = await respone.json()
    // console.log("as")
    return responeResult
})