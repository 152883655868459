import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_MASTER } from '../../../constants'
import { openMessage } from '../../slice/messageSlice'
// import { openMessage } from '../slice/messageSlice'
// import { openForm } from '../slice/api/areaSlice'

export const getAreaPage = createAsyncThunk('area/page', async (param, thunkAPI) => {
  // console.log(param)
  const respone = await fetch(
    `${API_MASTER}/api/area/page?page=${param.page}&size=${param.size}&idFactory=${param.filter[0].factory}&name=${param.filter[0].name}`,
    {
      method: 'get',
      headers: new Headers({
        Authorization: 'Bearer ' + param.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      }),
    },
  )
  const responeResult = await respone.json()
  // console.log("as")
  return responeResult
})
export const getAreaById = createAsyncThunk('area/byId', async (param, thunkAPI) => {
  // console.log(param);
  const response = await fetch(`${API_MASTER}/api/area/${param.id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + param.token,
    },
  })
  if (response.status === 200) {
    const responseJson = await response.json()
    return responseJson
  }
})
export const getAreaByRegionalId = createAsyncThunk('area/regionalId', async (param, thunkAPI) => {
  // console.log(param);
  const response = await fetch(`${API_MASTER}/api/area?regionalId=${param.regional}`, {
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
  })
  if (response.status === 200) {
    const responseJson = await response.json()
    return responseJson
  }
})
export const getAreaList = createAsyncThunk('area/list', async (param, thunkAPI) => {
  const respone = await fetch(`${API_MASTER}/api/area/`, {
    method: 'get',
    headers: new Headers({
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    }),
  })
  const responeResult = await respone.json()
  // console.log("as")
  return responeResult
})
export const postArea = createAsyncThunk('area/post', async (param, thunkAPI) => {
  // console.log(param);
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
    body: JSON.stringify(param.data),
  }
  let response = await fetch(`${API_MASTER}/api/area`, requestOptions)
  if (response.status === 200) {
    // console.log(response.status)
    const responseJson = await response.json()

    thunkAPI.dispatch(
      openMessage({
        message: 'Success Insert Area!',
        type: 'success',
        open: true,
      }),
    )
    thunkAPI.dispatch(getAreaPage(param))
    return responseJson
  }
  if (response.status === 400) {
    thunkAPI.dispatch(openMessage({ message: 'Insert Area Failed', type: 'danger', open: true }))
  }
})
export const editArea = createAsyncThunk('area/update/test', async (param, thunkAPI) => {
  // console.log(param.data);
  const requestOptions = {
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
    body: JSON.stringify(param.data),
  }
  let response = await fetch(`${API_MASTER}/api/area/${param.id}`, requestOptions)
  if (response.status === 200) {
    thunkAPI.dispatch(
      openMessage({ message: 'Edit Area Successfull', type: 'success', open: true })
    );
    thunkAPI.dispatch(getAreaPage(param))
  }
  // .then(getApi())
})
export const deleteArea = createAsyncThunk('area/delete', async (param, thunkAPI) => {
  // console.log(param);
  const requestOptions = {
    method: 'Delete',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
  }
  let response = await fetch(`${API_MASTER}/api/area/${param.id}`, requestOptions)
  if (response.status === 200) {
    // console.log(response.status)
    const responseJson = await response.json()

    thunkAPI.dispatch(
      openMessage({
        message: 'Success Delete Area!',
        type: 'success',
        open: true,
      }),
    )
    thunkAPI.dispatch(getAreaPage(param))
    return responseJson
  }
  if (response.status === 400) {
    thunkAPI.dispatch(openMessage({ message: 'Delete Area Failed', type: 'danger', open: true }))
  }
  // .then(getApi())
})
