import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_MASTER } from '../../../constants'
import { openMessage } from '../../slice/messageSlice'
// import { openMessage } from '../slice/messageSlice'
// import { openForm } from '../slice/api/shiftSlice'

export const getShiftPage = createAsyncThunk('shift/page', async (param, thunkAPI) => {
  const respone = await fetch(
    `${API_MASTER}/api/shift/page?page=${param.page}&size=${param.size}&name=${param.filter[0].name}&category=${param.filter[0].category}`,
    {
      method: 'get',
      headers: new Headers({
        Authorization: 'Bearer ' + param.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      }),
    },
  )
  const responeResult = await respone.json()
  // console.log("as")
  return responeResult
})
export const getShiftById = createAsyncThunk('shift/byId', async (param, thunkAPI) => {
  // console.log(param);
  const response = await fetch(`${API_MASTER}/api/shift/${param.id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + param.token,
    },
  })
  if (response.status === 200) {
    const responseJson = await response.json()
    return responseJson
  }
})
export const getShiftByRegionalId = createAsyncThunk('shift/regionalId', async (param, thunkAPI) => {
  // console.log(param);
  const response = await fetch(`${API_MASTER}/api/shift?regionalId=${param.regional}`, {
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
  })
  if (response.status === 200) {
    const responseJson = await response.json()
    return responseJson
  }
})
export const getShiftList = createAsyncThunk('shift/list', async (param, thunkAPI) => {
  const respone = await fetch(`${API_MASTER}/api/shift/`, {
    method: 'get',
    headers: new Headers({
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    }),
  })
  const responeResult = await respone.json()
  // console.log("as")
  return responeResult
})
export const postShift = createAsyncThunk('shift/post', async (param, thunkAPI) => {
  // console.log(param);
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
    body: JSON.stringify(param.data),
  }
  let response = await fetch(`${API_MASTER}/api/shift`, requestOptions)
  if (response.status === 200) {
    console.log(response.status)
    const responseJson = await response.json()

    thunkAPI.dispatch(
      openMessage({
        message: 'Success Insert Shift!',
        type: 'success',
        open: true,
      }),
    )
    thunkAPI.dispatch(getShiftPage(param))
    return responseJson
  }
  if (response.status === 400) {
    thunkAPI.dispatch(openMessage({ message: 'Insert Shift Failed', type: 'danger', open: true }))
  }
})
export const editShift = createAsyncThunk('shift/update', async (param, thunkAPI) => {
  // console.log(param);
  const requestOptions = {
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
    body: JSON.stringify(param.data),
  }
  let response = await fetch(`${API_MASTER}/api/shift/${param.id}`, requestOptions)
  if (response.status === 200) {
    console.log(response.status)
    const responseJson = await response.json()

    thunkAPI.dispatch(
      openMessage({
        message: 'Success Update Shift!',
        type: 'success',
        open: true,
      }),
    )
    thunkAPI.dispatch(getShiftPage(param))
    return responseJson
  }
  if (response.status === 400) {
    thunkAPI.dispatch(openMessage({ message: 'Update Shift Failed', type: 'danger', open: true }))
  }
  // .then(getApi())
})
export const deleteShift = createAsyncThunk('shift/delete', async (param, thunkAPI) => {
  // console.log(param);
  const requestOptions = {
    method: 'Delete',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
  }
  let response = await fetch(`${API_MASTER}/api/shift/${param.id}`, requestOptions)
  if (response.status === 200) {
    console.log(response.status)
    const responseJson = await response.json()

    thunkAPI.dispatch(
      openMessage({
        message: 'Success Delete Shift!',
        type: 'success',
        open: true,
      }),
    )
    thunkAPI.dispatch(getShiftPage(param))
    return responseJson
  }
  if (response.status === 400) {
    thunkAPI.dispatch(openMessage({ message: 'Delete Shift Failed', type: 'danger', open: true }))
  }
  // .then(getApi())
})
