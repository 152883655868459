import { createSlice } from "@reduxjs/toolkit";
import { getBusTracking } from "../action/busTrackingAction";

const initialState = {
    dataBusTracking: [],
    isLoadingBusTracking : false
};
const busLogSlice = createSlice({
    name: "busTracking",
    initialState,
    reducers: {
    },
    extraReducers: {
        [getBusTracking.pending]: (state) => {
            state.isLoadingBusTracking   = true
        },
        [getBusTracking.fulfilled]: (state, action) => {
        // console.log(action.payload);
            state.dataBusTracking = action.payload.data
            state.isLoadingBusTracking   = false
        },
        [getBusTracking.rejected]: (state) => {
            state.isLoadingBusTracking   = false
        },
    }
});

export const {  } = busLogSlice.actions;

export default busLogSlice.reducer;