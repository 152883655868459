import React, { Component, useEffect, useRef, useState } from "react"
import Leaflet from "leaflet"
import { Map, TileLayer, Marker, Popup } from "react-leaflet"
import "leaflet/dist/leaflet.css"
import { Card, CardBody, CardHeader, Col, Collapse, FormGroup, Label } from "reactstrap";
import marker from 'leaflet/dist/images/marker-icon.png'
import L from 'leaflet'
import { useDispatch } from "react-redux";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import BingLayer from "../Dashboard/BingLayer";
import { getBusProviderList } from "../../redux/action/master/busproviderAction";
import { getMapStationDashboard } from "../../redux/action/dashboardAction";
import { getRouteListByIdBusProvider } from "../../redux/action/master/routeAction";
import { getBusTracking } from "../../redux/action/busTrackingAction";
import DetailTracking from "./DetailTracking";

Leaflet.Icon.Default.imagePath = "../node_modules/leaflet"

delete Leaflet.Icon.Default.prototype._getIconUrl

Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
})
let markers = L.icon({
  iconUrl: marker, // Ubah ukuran ikon menjadi 32x32 piksel
})
const MapTracking = ({param}) => {
  var BING_KEY = 'AuhiCJHlGzhg93IqUH_oCpl_-ZUrIE6SPftlyGYUvr9Amx5nzA-WqGcPquyFZl4L'
  const position = [35.8039419, 10.5639663]
  const {t} = useTranslation()
  //
  const { dataBusProvider } = useSelector((state)=>state.busProvider)
  const { dataRoute } = useSelector((state)=>state.route)
  const { dataBusTracking } = useSelector((state)=> state.busTracking)
  const dispatch = useDispatch();
  //
  const [dataSelect, setSelect] = useState([{busProvider: '', route: ''}])
  const [dataLiveMap, setLiveMap] = useState([])
  const [selected, setSelected] = useState([{busProvider: '', route: ''}])
  const [collapse, setCollapse] = useState(false)
  const [dataDetail, setDataDetail] = useState([{plat: '', employees: []}])
  const [openModal, setOpenModal] = useState(false)
  //
  const myInterval = useRef()
  const _getIntervalData = () => {
    myInterval.current = setInterval(()=>{
      dispatch(getBusTracking(param))
    },5000)
  }
  const openDetail = (plat, employee) => {
    if(!openModal){
      const arr = [...dataDetail]
      arr[0].plat = plat
      arr[0].employees = employee
      setDataDetail(arr)
      setOpenModal(!openModal)
    }else{
      const arr = [...dataDetail]
      arr[0].plat = ''
      arr[0].employees = []
      setDataDetail(arr)
      setOpenModal(!openModal)
    }
  }
  //
  useEffect(()=>{
    dispatch(getBusProviderList(param))
  },[])

  useEffect(()=>{
    setLiveMap(dataBusTracking)
  },[dataBusTracking])

  useEffect(()=>{
    if(param.roles === 'ADMINISTRATOR' || param.roles === 'STAFF'){
    // console.log('chuak')
        if(selected[0].busProvider !== '' && selected[0].route === ''){
            param.idBusProvider = selected[0].busProvider.value
            dispatch(getRouteListByIdBusProvider(param))
        }
        if(selected[0].busProvider !== '' && selected[0].route !== ''){
            param.idBusProvider = selected[0].busProvider.value
            param.idRoute = selected[0].route === '' ? '' : selected[0].route.value
            dispatch(getBusTracking(param))
            _getIntervalData()
        }
    }else{
        param.idBusProvider = ''
        param.idRoute = selected[0].route
        dispatch(getBusTracking(param))
        _getIntervalData()
    }

    return () => {
        clearInterval(myInterval.current);
        myInterval.current = null;
    }
  },[selected])
  useEffect(() => {
    let arr = [...dataSelect]
    let selectedArr = [...selected]
    if (dataBusProvider.length > 0) {
        let newArr = dataBusProvider.map(({ id, name }) => ({ value: id, label: name }))
        // console.log(newArr)
        arr[0].busProvider = newArr
        selectedArr[0].busProvider = newArr[0]
    }
    
    setSelected(selectedArr)
    setSelect(arr)
  },[dataBusProvider])
  useEffect(()=>{
    let arr = [...dataSelect]
    let selectedArr = [...selected]
    if(param.roles === 'ADMINISTRATOR' || param.roles === 'STAFF'){
        if (dataRoute.length > 0) {
            let newArr = dataRoute.map(({ id, name }) => ({ value: id, label: name }))
            // console.log(newArr)
            arr[0].route = newArr
            selectedArr[0].route = newArr[0]
        }
        setSelected(selectedArr)
    }else{
        selectedArr[0].route = param.idRoute
    }
    setSelect(arr)
  },[dataRoute])
  const timestamp = (datetime, state) =>{
    let date = datetime.split('T')[0]
    let time = datetime.split('T')[1]
    if(state==='date'){
      return date
    }else{
      return time
    }
  }
  const detailTooltipIn = (e) => {
    // console.log('in')
      e.target.openPopup()
  }
  const detailTooltipOut = (e) => {
    e.target.closePopup()
  }
  return (
      <React.Fragment>
          <Card>
            {param.roles === 'ADMINISTRATOR' || param.roles === 'STAFF' ? (
            <CardHeader style={{padding: '20px 17px'}}>
                <div className="row">
                <Col lg={6} md={6} sm={12} xs={12}>
                    <FormGroup className='mb-3'>
                        <Label>{t('Bus Provider')}</Label> 
                        <Select
                            styles={{
                                control: (baseStyles, state) => ({
                                ...baseStyles,
                                width: '100%',
                                appearance: 'auto',
                                }),
                            }}
                            onChange={(e)=>{
                                let arr = [...selected]
                                arr[0].busProvider = e
                                arr[0].route = ''
                                setSelected(arr)
                                param.idBusProvider = e.value
                                dispatch(getRouteListByIdBusProvider(param))
                                // console.log(labelRuas)
                                // console.log(e.value)
                                // handleChange(e)
                            }}
                            name="busProviderId"
                            id="busProviderId"
                            value={selected[0].busProvider}
                            options={dataSelect[0].busProvider}
                        />
                    </FormGroup>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                    <FormGroup className='mb-3'>
                        <Label>{t('Route')}</Label> 
                        <Select
                            styles={{
                                control: (baseStyles, state) => ({
                                ...baseStyles,
                                width: '100%',
                                appearance: 'auto',
                                }),
                            }}
                            onChange={(e)=>{
                                let arr = [...selected]
                                arr[0].route = e
                                setSelected(arr)
                                // console.log(labelRuas)
                                // console.log(e.value)
                                // handleChange(e)
                            }}
                            name="routeId"
                            id="routeId"
                            value={selected[0].route}
                            options={dataSelect[0].route}
                        />
                    </FormGroup>
                </Col>
                </div>
            </CardHeader>) : null}
              <CardBody>
                <div className="row">
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div id="simple-gmaps" className="gmaps" style={{ position: "relative", zIndex: 0, height: 583 }}>
                      <Map
                      center={position}
                      zoom={9.25}
                      style={{ height: "583px" }}
                      zoomControl={true}
                      >
                        <BingLayer bingkey={BING_KEY} type="RoadOnDemand" />

                        {dataLiveMap.map((key, index) =>
                          <Marker
                            key={index}
                            position={[key.latitude, key.longitude]}
                            icon={markers}
                            eventHandlers={{
                              mouseover: (e) => detailTooltipIn(e),
                              mouseout: (e) => detailTooltipOut(e),
                            }}
                          >
                            <Popup>
                              <table style={{ width: '100%' }}>
                                <tbody>
                                  <tr>
                                    <th>{t('Vehicle License')}&nbsp;</th>
                                    <td> : </td>
                                    <td>&nbsp;{key.numberPlat}</td>
                                  </tr>
                                  <tr>
                                    <th>{t('Date')}&nbsp;</th>
                                    <td> : </td>
                                    <td>&nbsp;{timestamp(key.timestamp,'date')}</td>
                                  </tr>
                                  <tr>
                                    <th>{t('Time')}&nbsp;</th>
                                    <td> : </td>
                                    <td>&nbsp;{timestamp(key.timestamp,'time')}</td>
                                  </tr>
                                  <tr>
                                    <th>{t('Total Employee On Bus')}&nbsp;</th>
                                    <td> : </td>
                                    <td>&nbsp;{key.employees.length} {t('Employee')}</td>
                                  </tr>
                                  {/* Tambahkan baris lain jika diperlukan */}
                                </tbody>
                              </table>
                              <button onClick={()=>openDetail(key.numberPlat, key.employees)} className="btn btn-sm btn-primary"> {t('See Detail Employee')}</button>
                            </Popup>
                          </Marker>
                        )}
                      </Map>
                    </div>
                  </Col>
                </div>
              </CardBody>
          </Card>
          <DetailTracking isOpen={openModal} data={dataDetail} close={()=>openDetail()} />
      </React.Fragment>
  )
}

export default MapTracking