import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_EMPLOYEES } from '../../../constants'
import { openMessage } from '../../slice/messageSlice'
// import { openMessage } from '../slice/messageSlice'
// import { openForm } from '../slice/api/employeesSlice'

export const getEmployeePage = createAsyncThunk('employees/page', async (param, thunkAPI) => {
  const respone = await fetch(
    `${API_EMPLOYEES}/api/employees/page?page=${param.page}&size=${param.size}&nik=${param.filter[0].matricule}&routeId=${param.filter[0].route}&stationName=${param.filter[0].station}&areaId=${param.filter[0].area}&name=${param.filter[0].name}`,
    {
      method: 'get',
      headers: new Headers({
        Authorization: 'Bearer ' + param.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      }),
    },
  )
  const responeResult = await respone.json()
  // console.log("as")
  return responeResult
})
export const getEmployeeById = createAsyncThunk('employees/byId', async (param, thunkAPI) => {
  // console.log(param);
  const response = await fetch(`${API_EMPLOYEES}/api/employees/${param.id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + param.token,
    },
  })
  if (response.status === 200) {
    const responseJson = await response.json()
    return responseJson
  }
})
export const getEmployeeByRegionalId = createAsyncThunk('employees/regionalId', async (param, thunkAPI) => {
  // console.log(param);
  const response = await fetch(`${API_EMPLOYEES}/api/employees?regionalId=${param.regional}`, {
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
  })
  if (response.status === 200) {
    const responseJson = await response.json()
    return responseJson
  }
})
export const getEmployeeList = createAsyncThunk('employees/list', async (param, thunkAPI) => {
  const respone = await fetch(`${API_EMPLOYEES}/api/employees/`, {
    method: 'get',
    headers: new Headers({
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    }),
  })
  const responeResult = await respone.json()
  // console.log("as")
  return responeResult
})
export const getEmployeeSearch = createAsyncThunk('employees/list/search', async (param, thunkAPI) => {
  const respone = await fetch(`${API_EMPLOYEES}/api/employees/filter?search=${param.name}`, {
    method: 'get',
    headers: new Headers({
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    }),
  })
  const responeResult = await respone.json()
  // console.log("as")
  return responeResult
})
export const postEmployee = createAsyncThunk('employees/post', async (param, thunkAPI) => {
  // console.log(param);
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
    body: JSON.stringify(param.data),
  }
  let response = await fetch(`${API_EMPLOYEES}/api/employees`, requestOptions)
  if (response.status === 200) {
    // console.log(response.status)
    const responseJson = await response.json()

    thunkAPI.dispatch(
      openMessage({
        message: 'Success Insert Employee!',
        type: 'success',
        open: true,
      }),
    )
    thunkAPI.dispatch(getEmployeePage(param))
    return responseJson
  }
  if (response.status === 400) {
    thunkAPI.dispatch(openMessage({ message: 'Insert Employee Failed', type: 'danger', open: true }))
  }
})
export const editEmployee = createAsyncThunk('employees/update', async (param, thunkAPI) => {
  // console.log(param);
  const requestOptions = {
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
    body: JSON.stringify(param.data),
  }
  let response = await fetch(`${API_EMPLOYEES}/api/employees/${param.id}`, requestOptions)
  if (response.status === 200) {
    // thunkAPI.dispatch(
    //   openMessage({ message: 'Edit Site Successfull', type: 'success', open: true })
    // );
    thunkAPI.dispatch(getEmployeePage(param))
  }
  // .then(getApi())
})
export const deleteEmployee = createAsyncThunk('employees/delete', async (param, thunkAPI) => {
  // console.log(param);
  const requestOptions = {
    method: 'Delete',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
  }
  let response = await fetch(`${API_EMPLOYEES}/api/employees/${param.id}`, requestOptions)
  if (response.status === 200) {
    // console.log(response.status)
    const responseJson = await response.json()

    thunkAPI.dispatch(
      openMessage({
        message: 'Success Delete Employee!',
        type: 'success',
        open: true,
      }),
    )
    thunkAPI.dispatch(getEmployeePage(param))
    return responseJson
  }
  if (response.status === 400) {
    thunkAPI.dispatch(openMessage({ message: 'Delete Employee Failed', type: 'danger', open: true }))
  }
  // .then(getApi())
})

export const downloadTemplateEmployee = createAsyncThunk(
  'employees/download/template',
  async (param, thunkAPI) => {
    // console.log(param)
    const respone = await fetch(`${API_EMPLOYEES}/api/rfid-employee/download`, {
      method: 'get',
      headers: new Headers({  
        Authorization: 'Bearer ' + param.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      }),
    })
    const responeResult = await respone.blob()
    // console.log(await respone.blob())
    return responeResult
  },
)
export const uploadEmployee = createAsyncThunk(
  'employees/upload',
  async (param, thunkAPI) => {
    // console.log(param.data)

    const formData = new FormData();
    formData.append('file', param.data.file);

    const respone = await fetch(`${API_EMPLOYEES}/api/rfid-employee/upload`, {
      method: 'post',
      headers: new Headers({  
        Authorization: 'Bearer ' + param.token,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      }),
      body: formData,
    })
    if (respone.status === 200) {
      // console.log(respone.status)
      const responseJson = await respone.json()
      if(responseJson.message === 'Failed'){
        thunkAPI.dispatch(
          openMessage({
            message: 'Failed Upload Employee!',
            type: 'danger',
            open: true,
          }),
        )
      }else{
        thunkAPI.dispatch(
          openMessage({
            message: 'Success Upload Employee!',
            type: 'success',
            open: true,
          }),
        )
      }
      thunkAPI.dispatch(getEmployeePage(param))
      return responseJson
    }
  },
)
