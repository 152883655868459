import {createSlice} from "@reduxjs/toolkit";
import {
    editEnrollment,
    getEnrollmentById,
    getEnrollmentList,
    getEnrollmentPage,
    createEnrollment, deleteEnrollment
} from "../../action/master/enrollmentAction";

const initialState = {
    dataList: [],
    isOpenForm: false,
    stateForm: '',
    dataById: '',
    id: '',
    isLoading: false,
};

const enrollmentSlice = createSlice({
    name: "enrollment",
    initialState,
    reducers: {
        openFormEnrollment: (state, action) => {
            if (action.payload.open) {
                if (action.payload.type !== 'add') {
                    state.dataById = action.payload.data;
                    state.id = action.payload.id;
                }
            } else {
                state.dataById = '';
                state.id = '';
            }
            state.stateForm = action.payload.type;
            state.isOpenForm = action.payload.open;
        }
    },
    extraReducers: {
        // Enrollment Page
        [getEnrollmentPage.pending]: (state) => {
            state.isLoading = true;
        },
        [getEnrollmentPage.fulfilled]: (state, action) => {
            state.dataList = action.payload.data;
            state.isLoading = false;
        },
        [getEnrollmentPage.rejected]: (state) => {
            state.isLoading = false;
        },
        // Enrollment List
        [getEnrollmentList.pending]: (state) => {
            state.isLoading = true;
        },
        [getEnrollmentList.fulfilled]: (state, action) => {
            state.dataList = action.payload.data;
            state.isLoading = false;
        },
        [getEnrollmentList.rejected]: (state) => {
            state.isLoading = false;
        },
        // Enrollment By Id
        [getEnrollmentById.pending]: (state) => {
            state.isLoading = true;
        },
        [getEnrollmentById.fulfilled]: (state, action) => {
            state.dataList = action.payload.data;
            state.isLoading = false;
        },
        [getEnrollmentById.rejected]: (state) => {
            state.isLoading = false;
        },
        // Create Enrollment
        [createEnrollment.pending]: (state) => {
            state.isLoading = true;
        },
        [createEnrollment.fulfilled]: (state, action) => {
            state.isOpenForm = false;
            state.isLoading = false;
            state.stateForm = '';
        },
        [createEnrollment.rejected]: (state) => {
            state.isLoading = false;
        },
        // Edit Enrollment
        [editEnrollment.pending]: (state) => {
            state.isLoading = true;
        },
        [editEnrollment.fulfilled]: (state, action) => {
            state.isOpenForm = false;
            state.isLoading = false;
            state.dataById = [];
            state.id = 0;
            state.stateForm = '';
        },
        [editEnrollment.rejected]: (state) => {
            state.isLoading = false;
        },
        // Delete Enrollment
        [deleteEnrollment.pending]: (state) => {
            state.isLoading = true;
        },
        [deleteEnrollment.fulfilled]: (state, action) => {
            state.isOpenForm = false;
            state.isLoading = false;
            state.dataById = [];
            state.id = 0;
            state.stateForm = '';
        },
        [deleteEnrollment.rejected]: (state) => {
            state.isLoading = false;
        }
    }
});

export const {  openFormEnrollment } = enrollmentSlice.actions;

export default enrollmentSlice.reducer;