import userEvent from "@testing-library/user-event"
import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { Pie } from "react-chartjs-2"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { getBarChartDashboard, getPieChartDashboard } from "../../redux/action/dashboardAction"

const PieChart = () => {
  const { dataBarChart } = useSelector((state)=>state.dashboard)
  const [datas, setDatas] = useState([])
  const [labels, setLabels] = useState([])
  const [colors, setColors] = useState([])
  const dispatch = useDispatch()
  useEffect(()=>{
    if(dataBarChart.length > 0){
      let dataBar = []
      let titleBar = []
      let colorBar = []
      dataBarChart.map((key, index)=>{
          let num = Math.round(0xffffff * Math.random());
          let r = num >> 16;
          let g = num >> 8 & 255;
          let b = num & 255;
          dataBar.push(key.routeCount)
          titleBar.push(key.alias)
          colorBar.push('rgba(' + r + ', ' + g + ', ' + b + ', 0.75)')
        }
      )
      // console.log(colorBar)
      setDatas(dataBar)
      setLabels(titleBar)
      setColors(colorBar)
    }
  },[dataBarChart])
  const series = datas
  console.log(labels)
  const options = {
      labels: labels,
      chart: {
          toolbar: {
              show: false
          },
          zoom: {
              enabled: false
          }
      },
      colors: colors,
      dataLabels: {
          enabled: false,
      },
      stroke: {
          curve: 'smooth',
          width: '3',
          dashArray: [0],
      },
  
      markers: {
          size: 3
      },
      xaxis: {
          categories: labels,
          title: {
              text: 'Month'
          }
      },
  
      fill: {
          type: 'solid',
          opacity: [1],
      },
  
      legend: {
          position: 'bottom',
          horizontalAlign: 'center',
      }
  }
  const { user } = useSelector((state) => state.auth)
  useEffect(()=>{
    dispatch(getBarChartDashboard({token: user.access_token}))
  },[])
  return (
    // <div style={{ height: "100%" }}>
    <ReactApexChart
      options={options}
      series={series}
      height="260"
      type="donut"
      className="apex-charts"
    />
    // </div>
  )
}

export default PieChart
