import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  height: 213px;
  overflow-y: scroll;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: gray;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent;
  }
`

export default Wrapper
