import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ModalComponent from '../../../../components/Content/Modal'
import { openFormRoute, openRouteStation } from '../../../../redux/slice/master/routeSlice'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Col, FormFeedback, FormGroup, Input, Label, Table } from 'reactstrap'
import { useDispatch } from 'react-redux'
import Alert from '../../../../components/Content/Alert'
import { deleteRoute, editRoute, editRouteStation, getRouteStationByIdRoute, postRoute } from '../../../../redux/action/master/routeAction'
import Select from 'react-select'
import { getBusProviderById, getBusProviderList } from '../../../../redux/action/master/busproviderAction'
import { getFactoryById, getFactoryList } from '../../../../redux/action/master/factoryAction'
import { DualListBox } from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { getStationList } from '../../../../redux/action/master/stationAction'
import Wrapper from '../../../../components/Content/Wrapper'
import FormStation from './FormStation'
import { useTranslation } from 'react-i18next'

const FormRoute = ({param}) => {
    const { isOpenRoute, idRoute, dataRouteById, stateFormRoute, isLoadingRoute, dataRouteStation } = useSelector((state)=>state.route)
    const { dataBusProvider, dataBusProviderById } = useSelector((state)=>state.busProvider)
    const { dataFactory, dataFactoryById } = useSelector((state)=>state.factory)
    const { dataStation } = useSelector((state)=>state.station)
    const [selectedList, setSelectedList] = useState([]);
    const [selectedBefore, setSelectedBefore] = useState([])
    const [routeStation, setRouteStation] = useState([])
    const [listRouteStation, setListRouteStation] = useState([])
    const {t} = useTranslation()
    // console.log(selectedList)
    const dispatch = useDispatch()
    const handleFormSubmit = (values) =>{
        param.data = values
        param.stationData = selectedList
        param.station =  listRouteStation
        if(stateFormRoute === 'add'){
            dispatch(postRoute(param))
        }else if(stateFormRoute === 'edit'){
            param.id = idRoute
            param.oldStationData = selectedBefore
            dispatch(editRoute(param))
        }else{
            param.id = idRoute
            dispatch(deleteRoute(param))
        }
    }
    const [selected, setSelected] = useState([{busProvider: '', factory: ''}])
    const [dataSelect, setSelect] = useState([{busProvider: '', factory: '', station: ''}])
    const initialValues = {
        name: idRoute ==='' ? '': dataRouteById.name,
        source: idRoute ==='' ? '': dataRouteById.source,
        destination: idRoute ==='' ? '': dataRouteById.destination,
        distance: idRoute ==='' ? '': dataRouteById.distance,
        factoryId: idRoute ==='' ? '': dataRouteById.factoryId,
        busProviderId: idRoute ==='' ? '': dataRouteById.busProviderId,
    }
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required!'),
        source: Yup.string().required('Source is required!'),
        destination: Yup.string().required('Destination is required!'),
        distance: Yup.string().required('Distance is required!'),
        factoryId: Yup.string().required('Factory is required!'),
        busProviderId: Yup.string().required('Bus Provider is required!'),
      })
    const changeGeoFencing = (type, value, id, idStation) =>{
        // console.log(value)
        if(id !== ''){
            if(type==='start'){
                param.body = {geoFencingStart: value}
                param.id = id
                // console.log(param.body)
                dispatch(editRouteStation(param))
            }else{
                param.body = {geoFencingEnd: value}
                param.id = id
                // console.log(param.body)
                dispatch(editRouteStation(param))
            }
        }else{
            if(type==='start'){
                setListRouteStation(prevData => {
                    return prevData.map(item => {
                      if (item.idStation === idStation) {
                        return { ...item, geoFencingStart: value};
                      }
                      return item;
                    });
                  });
            }else{
                setListRouteStation(prevData => {
                    return prevData.map(item => {
                      if (item.idStation === idStation) {
                        return { ...item, geoFencingEnd: value};
                      }
                      return item;
                    });
                  });
            }
        }
    }
    useEffect(()=>{
        dispatch(getBusProviderList(param))
        dispatch(getFactoryList(param))
        dispatch(getStationList(param))
    },[])

    useEffect(() => {
        let arr = [...dataSelect]
        if (dataBusProvider.length > 0) {
            let newArr = dataBusProvider.map(({ id, name }) => ({ value: id, label: name }))
            // console.log(newArr)
            arr[0].busProvider = newArr
        }
        if (dataFactory.length > 0) {
            let newArr = dataFactory.map(({ id, name }) => ({ value: id, label: name }))
            // console.log(newArr)
            arr[0].factory = newArr
        }
        if (dataStation.length > 0) {
            let newArr = dataStation.map(({ id, name }) => ({ value: id, label: name }))
            // console.log(newArr)
            arr[0].station = newArr
        }

        if (dataRouteStation.length > 0) {
            let newArr = []
            let oldArr = []
            let arrRouteStation = []
            dataRouteStation.map((key) => {
                newArr.push(key.idStation)
                oldArr.push({id:key.id, idStation: key.idStation})
                const matchingElements = dataStation.filter(item => item.id === key.idStation);
                if (matchingElements.length > 0) {
                    arrRouteStation.push({no:key.orderNumber ,id:key.id, name:matchingElements[0].name, geoFencingEnd: key.geoFencingEnd, geoFencingStart: key.geoFencingStart})
                }
            })
            // console.log(newArr)
            setSelectedList(newArr)
            setSelectedBefore(oldArr)
            setListRouteStation(arrRouteStation)
        }

        setSelect(arr)
    },[dataBusProvider,dataFactory,dataStation,dataRouteStation])
    useEffect(() => {
        if(stateFormRoute === 'edit'){
            param.routeId = dataRouteById.id
            dispatch(getRouteStationByIdRoute(param))
            param.id = dataRouteById.busProviderId
            dispatch(getBusProviderById(param))
            param.id = dataRouteById.factoryId
            dispatch(getFactoryById(param))
            
        }
    },[isOpenRoute])
    useEffect(() => {
        let arr = [...selected]
        if (dataFactoryById.length !== '') {
            if(stateFormRoute === 'edit'){
                arr[0].factory = {label: dataFactoryById.name, value: dataFactoryById.id}
            }
        }
        if (dataBusProviderById !== '') {
            if(stateFormRoute === 'edit'){
                arr[0].busProvider = {label: dataBusProviderById.name, value: dataBusProviderById.id}
            }
        }

        setSelected(arr)
    },[dataBusProviderById,dataFactoryById])
    const changeList = (selected) => {
        let newArr = []
        for(let i = 0 ; i < selected.length ; i++){
            if(stateFormRoute === 'edit'){
                let matchingRouteStation = dataRouteStation.filter(item => item.idStation === selected[i]);
                let matchingStation = dataStation.filter(item => item.id === selected[i]);
                if(matchingRouteStation.length > 0 && matchingStation.length > 0 ){
                    newArr.push({no:matchingRouteStation[0].orderNumber ,id:matchingRouteStation[0].id, name:matchingStation[0].name, geoFencingEnd: matchingRouteStation[0].geoFencingEnd, geoFencingStart: matchingRouteStation[0].geoFencingStart, idStation: matchingStation[0].id})
                }else if(matchingRouteStation.length === 0 && matchingStation.length > 0){
                    newArr.push({no:'' ,id:'', name:matchingStation[0].name, geoFencingEnd: false, geoFencingStart:false, idStation: matchingStation[0].id})
                }
            }else{
                let matchingStation = dataStation.filter(item => item.id === selected[i]);
                if(matchingStation.length > 0){
                    newArr.push({no:'' ,id:'', name:matchingStation[0].name, geoFencingEnd: false, geoFencingStart:false, idStation: matchingStation[0].id})
                }
            }
            
        }
        setListRouteStation(newArr)
    }
    // useEffect(()=>{
        
    // },[selectedList])
    // console.log(listRouteStation)
    return stateFormRoute === 'delete' ? (
        <Alert 
            isOpen={isOpenRoute} 
            action={handleFormSubmit}
            text={t("Are You Sure for Delete This Data ?")}
            toggle={()=>{dispatch(openFormRoute({open: !isOpenRoute, type: ''}))}} 
            close={()=>{dispatch(openFormRoute({open: !isOpenRoute, type: ''}))}} />
    ) : (
        <>
            <ModalComponent 
                isOpen={isOpenRoute} 
                close={()=>{dispatch(openFormRoute({open: !isOpenRoute, type: ''}))}}
                title={"Form Data "+t("Route")}
                idForm="route-form"
                size="xl"
                toggle={()=>{dispatch(openFormRoute({open: true, type: 'add'}))}}
                isLoading={isLoadingRoute}
            >
                <Formik 
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit} id="route-form">
                            <Wrapper style={{height: 450}}>
                                <div className="row" style={{width: '100%'}}>
                                    <Col xs={12} sm={12} lg={12} md={12}>
                                        <div className='row'>
                                            <Col xs={12} sm={12} lg={12} md={12}>
                                                <FormGroup className='mb-3'>
                                                    <Label>{t('Name')}</Label>
                                                    <Input
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        invalid={touched.name && !!errors.name}
                                                    />
                                                    <FormFeedback>{errors.name}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} sm={12} lg={4} md={4}>
                                                <FormGroup className='mb-3'>
                                                    <Label>{t('Source')}</Label>
                                                    <Input
                                                        type="text"
                                                        id="source"
                                                        name="source"
                                                        value={values.source}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        invalid={touched.source && !!errors.source}
                                                    />
                                                    <FormFeedback>{errors.source}</FormFeedback>
                                                </FormGroup>
                                            </Col>

                                            <Col xs={12} sm={12} lg={4} md={4}>
                                                <FormGroup className='mb-3'>
                                                    <Label>{t('Destination')}</Label>
                                                    <Input
                                                        type="text"
                                                        id="destination"
                                                        name="destination"
                                                        value={values.destination}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        invalid={touched.destination && !!errors.destination}
                                                    />
                                                    <FormFeedback>{errors.destination}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} sm={12} lg={4} md={4}>
                                                <FormGroup className='mb-3'>
                                                    <Label>{t('Distance')}</Label>
                                                    <Input
                                                        type="text"
                                                        id="distance"
                                                        name="distance"
                                                        value={values.distance}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        invalid={touched.distance && !!errors.distance}
                                                    />
                                                    <FormFeedback>{errors.distance}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} sm={12} lg={6} md={6}>
                                                <FormGroup className='mb-3'>
                                                    <Label>{t('Bus Provider')}</Label>
                                                    <Select
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            borderColor:
                                                                touched.busProviderId && errors.busProviderId
                                                                ? 'red' : 'rgba(0,0,0,.24)',
                                                            width: '100%',
                                                            appearance: 'auto',
                                                            }),
                                                        }}
                                                        onChange={(e)=>{
                                                            let arr = [...selected]
                                                            handleChange({
                                                                target: { name: 'busProviderId', value: e.value },
                                                            })
                                                            arr[0].busProvider = e
                                                            setSelected(arr)
                                                            // console.log(labelRuas)
                                                            // console.log(e.value)
                                                            // handleChange(e)
                                                        }}
                                                        name="busProviderId"
                                                        id="busProviderId"
                                                        value={selected[0].busProvider}
                                                        options={dataSelect[0].busProvider}
                                                    />
                                                    <FormFeedback>{errors.busProviderId}</FormFeedback>
                                                </FormGroup>
                                            </Col>

                                            <Col xs={12} sm={12} lg={6} md={6}>

                                                <FormGroup className='mb-3'>
                                                    <Label>{t('Factory')}</Label>
                                                    <Select
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            borderColor:
                                                                touched.factoryId && errors.factoryId
                                                                ? 'red' : 'rgba(0,0,0,.24)',
                                                            width: '100%',
                                                            appearance: 'auto',
                                                            }),
                                                        }}
                                                        onChange={(e)=>{
                                                            let arr = [...selected]
                                                            handleChange({
                                                                target: { name: 'factoryId', value: e.value },
                                                            })
                                                            arr[0].factory = e
                                                            setSelected(arr)
                                                            // console.log(labelRuas)
                                                            // console.log(e.value)
                                                            // handleChange(e)
                                                        }}
                                                        name="factoryId"
                                                        id="factoryId"
                                                        value={selected[0].factory}
                                                        options={dataSelect[0].factory}
                                                    />
                                                    <FormFeedback>{errors.factoryId}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        </div>
                                    </Col>
                                    <Col xs={6} sm={6} lg={6} md={6}>
                                        <Label>{t('Station')} {t('List')}</Label>
                                    </Col>
                                    <Col xs={6} sm={6} lg={6} md={6} style={{textAlign: 'right'}}>
                                        <a 
                                            href="#"
                                            onClick={()=> dispatch(openRouteStation({open: true}))}
                                            className="btn btn-primary"
                                        >
                                            Edit Route Station
                                        </a >
                                    </Col>
                                    <Col xs={12} sm={12} lg={12} md={12} style={{marginTop: 15}}>
                                    <Table
                                        striped
                                    >
                                        <thead>
                                            <tr>
                                                <th rowspan={2} style={{verticalAlign: 'middle'}}>
                                                    #
                                                </th>
                                                <th rowspan={2}  style={{verticalAlign: 'middle'}}>
                                                    {t('Station')} {t('Name')}e
                                                </th>
                                                <th colspan={2} style={{textAlign: 'center'}}>
                                                    {t('Geo Fencing')}
                                                </th>
                                            </tr>
                                            <tr>
                                                <th style={{textAlign: 'center'}}>
                                                    {t('Start')}
                                                </th>
                                                <th style={{textAlign: 'center'}}>
                                                    {t('End')}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listRouteStation.map((key, index)=>(
                                                <tr>
                                                    <td>{key.no}</td>
                                                    <td>{key.name}</td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <Input 
                                                            type="checkbox" 
                                                            onChange=
                                                                {(e)=>
                                                                    changeGeoFencing('start',e.target.checked,key.id, key.idStation) 
                                                                }
                                                        />
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <Input 
                                                            type="checkbox" 
                                                            onChange=
                                                                {(e)=>
                                                                    changeGeoFencing('end',e.target.checked,key.id,key.idStation) 
                                                                }
                                                        />
                                                    </td>
                                                </tr>
                                            ))} 
                                        </tbody>
                                    </Table>
                                    </Col>
                                </div>
                            </Wrapper>
                        </form>
                    )}
                </Formik>
            </ModalComponent>
            <FormStation 
                dataStation={dataSelect[0].station}
                selectedStation={selectedList}
                onChange={newSelected =>{
                    setSelectedList(newSelected)
                    changeList(newSelected)
                }} 
            />
        </>
    )
}

export default FormRoute