import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Col, FormGroup, Label, Row } from 'reactstrap'
import DatatableComponent from '../../components/Content/Datatable'
import Datatable from '../../components/Content/Datatable'
import ModalComponent from '../../components/Content/Modal'
import { getBusPage } from '../../redux/action/master/busAction'
import { openFormBus } from '../../redux/slice/master/busSlice'
import FormBus from './util/Bus/FormBus'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
import { useTranslation } from 'react-i18next'
import FilterSelect from '../../components/Content/FilterSelect'

const Bus = () => {
  const {t} = useTranslation()
  const { isOpenBus, dataBus, isLoadingBus } = useSelector((state)=>state.bus)
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState('')
  const [filter, setFilter] = useState([{vehicleLicense: '', manufactur: '', busProvider: '', route: ''}])
  const countPerPage = 10

  const { dataBusProvider, idBusProvider } = useSelector((state)=>state.busProvider)
  const { dataRoute, idRoute } = useSelector((state)=>state.route)

  const [dataSelect, setSelect] = useState([{busProvider: [], route: []}])
  const onChange = (e, field) => {
    let arr = [...filter]
    if (field === 'vehicleLicense') {
      arr[0].vehicleLicense = e.target.value
    }
    if (field === 'manufactur') {
      arr[0].manufactur = e.target.value
    }
    if (field === 'busProvider') {
      arr[0].busProvider = e
    }
    if (field === 'route') {
      arr[0].route = e
    }
    setFilter(arr)
  }

  const columns = [
    {
      name: (
        <div className='row' style={{width: '100%', marginBottom: '13px'}}>
          <Col lg={12} md={12} sm={12} xs={12} className='mb-2'>
            {t("Vehicle License")}
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <input type='text' onChange={(e)=> onChange(e, 'vehicleLicense')} className='form-control' placeholder={'Search By '+ t("Vehicle License")} />
          </Col>
        </div>
      ),
      selector: (row) => row.vehicleLicensePlate,
    },
    {
        
      name: <>{t("Capacity")}</>,
      selector: (row) => row.capacityNumber,
    },
    {
        name: (
          <div className='row' style={{width: '100%', marginBottom: '13px'}}>
            <Col lg={12} md={12} sm={12} xs={12} className='mb-2'>
              {t("Manufactur")}
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <input type='text' onChange={(e)=> onChange(e, 'manufactur')} className='form-control' placeholder={'Search By '+ t("Manufactur")} />
            </Col>
          </div>
        ),
        selector: (row) => row.manufactur,
    },
    {
        name: (
          <div className='row' style={{width: '100%', marginBottom: '13px'}}>
            <Col lg={12} md={12} sm={12} xs={12} className='mb-2'>
              {t("Bus Provider")}
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <FilterSelect 
                options={dataSelect[0].busProvider.length > 0 ? dataSelect[0].busProvider : []} 
                onChange={(e)=>onChange(e,'busProvider')} 
                defaultValue={idBusProvider} 
              />
            </Col>
          </div>
        ),
        selector: (row) => row.providerName,
    },
    {
      name: (
        <div className='row' style={{width: '100%', marginBottom: '13px'}}>
          <Col lg={12} md={12} sm={12} xs={12} className='mb-2'>
            {t("Route")}
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <FilterSelect options={dataSelect[0].route.length > 0 ? dataSelect[0].route : []} onChange={(e)=>onChange(e,'route')}  />
          </Col>
        </div>
      ),
      selector: (row) => row.routeName,
    },
    {
      name: <>{t("Action")}</>,
      cell: (row) => (
        <div style={{ padding: '10px 0' }}>
          <button 
            className='btn btn-outline-secondary btn-sm' 
            onClick={()=>{
              dispatch(openFormBus({open: !isOpenBus, type: 'edit',data: row, id: row.id}))
            }} 
            style={{marginRight: 5}}>
              {t("Edit")}
            </button> 
            <button 
            className='btn btn-danger btn-sm' 
            onClick={()=>{
              dispatch(openFormBus({open: !isOpenBus, type: 'delete',data: row, id: row.id}))
            }} 
            style={{marginRight: 5}}>
              {t("Delete")}
            </button>
        </div>
      ),
    },
  ]
  const { user } = useSelector((state) => state.auth)
  const param = {
    token: user.access_token,
    sort: sort,
    size: countPerPage,
    filter: filter,
    page: page,
  }
  useEffect(() => {
    let arr = [...dataSelect]
    if (dataBusProvider.length > 0) {
        let newArr = dataBusProvider.map(({ id, name }) => ({ value: id, label: name }))
        // console.log(newArr)
        arr[0].busProvider = newArr
    }
    if (dataRoute.length > 0) {
      let newArr = dataRoute.map(({ id, name }) => ({ value: id, label: name }))
      // console.log(newArr)
      arr[0].route = newArr
    }
      // let value = newArr.find(({ value }) => value === idBusProvider);
      // console.log(value)
      // selectedArr[0].busProvider = valuebusProvider
      setSelect(arr)
  },[dataBusProvider, dataRoute])
  useEffect(()=>{
    if(idBusProvider!==''){
      onChange(idBusProvider,'busProvider') 
    }
  },[idBusProvider])
  useEffect(()=>{
    dispatch(getBusPage(param))
  },[page, filter, sort])
  return (
    <React.Fragment>
        <div className="page-content">
            {/* Render Breadcrumb */}
            <Breadcrumbs title={t("Master Data")} breadcrumbItem={t("Bus")} />
            {/* Content */}
            <Row>
                <div className="col-6 mb-3">
                    <button className="btn btn-primary waves-effect waves-light" style={{marginRight: 5}}
                        onClick={()=>{dispatch(openFormBus({open: !isOpenBus, type: 'add'}))}}
                        data-toggle="modal"
                        data-target=".bs-example-modal-center">
                          + {t("Add Data")}
                    </button>
                </div>
                <div className="col-6 mb-3" style={{textAlign: 'right'}}>
                {isLoadingBus ?
                (
                  <div>
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <button className="btn btn-primary waves-effect waves-light"
                      onClick={()=>setFilter([{vehicleLicense: '', capacity: '', manufactur: '', busProvider: '', route: ''}])}>
                        <i className="mdi mdi-refresh"></i>  Reset Filter
                  </button>
                )}
                </div>
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            {/* ( */}
                            <DatatableComponent  
                                data={dataBus.content}
                                page={page}
                                countPerPage={countPerPage}
                                totalElements={dataBus.totalElements}
                                columns={columns}
                                setPage={setPage}
                                columnFilter
                                columnSorter
                                pagination
                                tableProps={{
                                    hover: true,
                                }} 
                            />
                        </div>
                    </div>
                </div>
            </Row>
        </div>
        <FormBus isOpen={isOpenBus} onClose={()=> dispatch(openFormBus({open: !isOpenBus, type: ''}))} param={param} />
    </React.Fragment>

  )
}

export default Bus