// import { MDBDataTable, MDBDataTableV5 } from 'mdbreact'
import React from 'react'
import DataTable from 'react-data-table-component'

const DatatableComponent = ({ props, columns, data,totalElements,setPage, countPerPage }) => {
    return (
      <DataTable
        columns={columns}
        data={data}
        pagination
        paginationServer
        paginationTotalRows={totalElements}
        paginationPerPage={countPerPage}
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
        onChangePage={(page) => setPage(page - 1)}
        striped
        dense
        // customStyles={isDarkMode ? TableDarkStyle : ''}
        {...props}
      />
    )
}

export default DatatableComponent