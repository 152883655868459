import React, { useEffect, useState } from 'react'
import { Modal } from 'reactstrap'
import { useTranslation } from 'react-i18next'

const ModalComponent = ({isOpen, close, children,idForm='', toggle, title, isLoading, size="md", footer=true}) => {
    useEffect(()=>{
        if(isOpen){
            document.body.style.overflow = 'hidden';
        }else{
            document.body.style.overflow = null;
        }
        document.body.classList.add("no_padding")
    },[isOpen])
    const {t} = useTranslation()
    return (
        <Modal
            isOpen={isOpen}
            centered={true}
            toggle={()=>toggle()}
            size={size}
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">{title}</h5>
                <button
                    type="button"
                    onClick={() => {
                        close()
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
               {children}
            </div>
            {footer ? (
                <div className="modal-footer">
                {idForm !== '' ? isLoading ? 
                    (
                        <div class="spinner-grow text-light" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                    ) : 
                (<button className="btn btn-primary" type="submit" form={idForm}>{t("Save")}</button>): null}
                <button className="btn btn-outline-secondary" onClick={() => {close()}}>{t("Close")}</button>
                </div>
            ) : null}
        </Modal>
    )
}

export default ModalComponent