import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const SidebarContent = props => {
    const ref = useRef()
    const { user } = useSelector((state) => state.auth)
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="vertical-simplebar" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            
            {user.roles.includes('STAFF') || user.roles.includes('ADMINISTRATOR')? (
              <li>
                <Link to="/#" className="waves-effect">
                  <i className="mdi mdi-airplay"></i>{/*<span className="badge rounded-pill bg-info float-end">2</span>*/}
                  <span>{props.t("Dashboard")}</span>
                </Link>
                {/* <ul className="sub-menu">
                  <li><Link to="/dashboard">{props.t("Dashboard")} 1</Link></li>
                  <li><Link to="/#">{props.t("Dashboard")} 2</Link></li>
                </ul> */}
              </li>
            ):null}
            {user.roles.includes('ADMINISTRATOR') ? (
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-newspaper"></i>
                <span>{props.t("Master Data")}</span>
              </Link>
              <ul className="sub-menu">
                <li><Link to="/master/area">{props.t("Area")} </Link></li>
                <li><Link to="/master/bus">{props.t("Bus")} </Link></li>
                <li><Link to="/master/bus-provider">{props.t("Bus Provider")}</Link></li>
                <li><Link to="/master/device">{props.t("Device")} </Link></li>
                <li><Link to="/master/factory">{props.t("Factory")} </Link></li>
                <li><Link to="/master/route">{props.t("Route")} </Link></li>
                <li><Link to="/master/shift">{props.t("Shift")} </Link></li>
                <li><Link to="/master/station">{props.t("Station")} </Link></li>
              </ul>
            </li>
            ) : null}
            
            
            {user.roles.includes('STAFF') || user.roles.includes('ADMINISTRATOR') ? (
              <>
                <li>
                  <Link to="/master/weekly-schedule" className=" waves-effect">
                    <i className="mdi mdi-calendar-month"></i>
                    <span>{props.t("Weekly Schedule")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/bus-log" className=" waves-effect">
                    <i className="mdi mdi-bus-side"></i>
                    <span>{props.t("Bus Log")}</span>
                  </Link>
                </li>
                
                <li>
                  <Link to="/event-log" className=" waves-effect">
                    <i className="mdi mdi-clipboard-list-outline"></i>
                    <span>{props.t("Event Log")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-account-multiple-outline"></i>
                    <span>{props.t("User Management")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li><Link to="/user-mgt/user">{props.t("User")} </Link></li>
                    <li><Link to="/user-mgt/role">{props.t("Role")} </Link></li>
                    <li><Link to="/user-mgt/enrollment">{props.t("Enrollment")} </Link></li>
                  </ul>
                </li>
              </>
            
            ): null}
            
            <li>
                <Link to="/bus-tracking" className=" waves-effect">
                  <i className="mdi mdi-account-circle-outline"></i>
                <span>{props.t("Bus Tracking")}</span>
              </Link>
            </li>
            
            
            
            {/* <li>
              <Link to="/under-construct" className=" waves-effect">
                <i className="mdi mdi-billboard"></i>
                <span>{props.t("Report")}</span>
              </Link>
            </li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))