import React from 'react'
import ModalComponent from '../../components/Content/Modal'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Wrapper from '../../components/Content/Wrapper'

const BodyTable = styled.div`
height: 250px; 
overflow-y: scroll;
`

const DetailTracking = ({isOpen, data, close}) => {

    const {t} = useTranslation()
    return (
        <ModalComponent 
            isOpen={isOpen} 
            close={close}
            title={t('Detail Tracking Bus')+ ' : ' + data[0].plat}
            // idForm="weeklySchedule-form"
            footer={false}
            size="lg"
            toggle={close}
        >

            <Wrapper style={{height: 300}}>
                <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{t('Employee Name')}</th>
                            <th>{t('RFID Card')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data[0].employees.length > 0 ? data[0].employees.map((key,index)=>(
                            <tr>
                                <td>{index+1}</td>
                                <td>{key.employeeName}</td>
                                <td>{key.rfidCard}</td>
                            </tr>
                        )) : (
                            <tr>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Wrapper>
        </ModalComponent>
    )
}

export default DetailTracking