import { useEffect, useRef } from "react";
import 'select2/dist/css/select2.min.css';
import $ from 'jquery';
import 'select2/dist/js/select2.full.min.js';

const FilterSelect = ({ options, onChange, defaultValue='' }) => {
    const selectRef = useRef(null);
    const prevValue = useRef(null);
    useEffect(() => {
        $(selectRef.current).select2();
        if(defaultValue !== ''){
            $(selectRef.current).val(defaultValue).trigger('change');
        }
        $(selectRef.current).on('change', (event) => {
          const selectedValue = event.target.value;
          if (prevValue.current !== selectedValue) {
            prevValue.current = selectedValue;
            onChange(selectedValue);
          }
        });
      return () => {
        $(selectRef.current).select2('destroy');
      };
    }, [options, onChange]);
  
    return (
        <select ref={selectRef} style={{ width: '100%' }}>
            <option value="">Select...</option>
            {options.map((option, index) => (
            <option key={index} value={option.value}>
                {option.label}
            </option>
            ))}
        </select>
    );
  };
export default FilterSelect