import { createSlice } from "@reduxjs/toolkit";
import { deleteShift, editShift, getShiftById, getShiftByRegionalId, getShiftList, getShiftPage, postShift } from "../../action/master/shiftAction";

const initialState = {
    dataShift: [],
    isOpenShift: false,
    stateFormShift: '',
    dataShiftById: '',
    idShift: '',
    isLoadingShift: false,
};
const shiftSlice = createSlice({
    name: "shift",
    initialState,
    reducers: {
        openFormShift: (state, action) => {
            if(action.payload.open){
                if(action.payload.type !== 'add'){
                    state.dataShiftById = action.payload.data
                    state.idShift = action.payload.id
                }
            }else{
                state.dataShiftById = ''
                state.idShift = ''
            }
            state.stateFormShift = action.payload.type
            state.isOpenShift = action.payload.open;
        },
    },
    extraReducers: {
      [getShiftPage.pending]: (state) => {
        state.isLoadingShift = true
      },
      [getShiftPage.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataShift = action.payload.data
        state.isLoadingShift = false
      },
      [getShiftPage.rejected]: (state) => {
        state.isLoadingShift = false
      },
      [getShiftList.pending]: (state) => {
        state.isLoadingShift = true
      },
      [getShiftList.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataShift = action.payload.data
        state.isLoadingShift = false
      },
      [getShiftList.rejected]: (state) => {
        state.isLoadingShift = false
      },
      [getShiftById.pending]: (state) => {
        state.isLoadingShift = true
      },
      [getShiftById.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataShift = action.payload.data
        state.isLoadingShift = false
      },
      [getShiftById.rejected]: (state) => {
        state.isLoadingShift = false
      },
      [getShiftByRegionalId.pending]: (state) => {
        state.isLoadingShift = true
      },
      [getShiftByRegionalId.fulfilled]: (state, action) => {
        // console.log(action.payload)
        state.dataShiftByRegionalId = action.payload.data
        state.isLoadingShift = false
      },
      [getShiftByRegionalId.rejected]: (state) => {
        state.isLoadingShift = false
      },
      [postShift.pending]: (state) => {
        state.isLoadingShift = true
      },
      [postShift.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.isOpenShift = false
        state.isLoadingShift = false
        state.stateFormShift = ''
      },
      [postShift.rejected]: (state) => {
        state.isLoadingShift = false
      },
      [editShift.pending]: (state) => {
        state.isLoadingShift = true
      },
      [editShift.fulfilled]: (state, action) => {
        state.isOpenShift = false
        state.isLoadingShift = false
        state.dataByIdShift = []
        state.idShift = 0
        state.stateFormShift = ''
      },
      [editShift.rejected]: (state) => {
        state.isLoadingShift = false
      },
      [deleteShift.pending]: (state) => {
        state.isLoadingShift = true
      },
      [deleteShift.fulfilled]: (state, action) => {
        state.isOpenShift = false
        state.isLoadingShift = false
        state.dataByIdShift = []
        state.idShift = 0
        state.stateFormShift = ''
      },
      [deleteShift.rejected]: (state) => {
        state.isLoadingShift = false
      },
    }
});

export const {  openFormShift } = shiftSlice.actions;

export default shiftSlice.reducer;