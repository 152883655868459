import React from 'react'
import { useSelector } from 'react-redux'
import ModalComponent from '../../../../components/Content/Modal'
import { openFormFactory } from '../../../../redux/slice/master/factorySlice'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Col, FormFeedback, FormGroup, Input, Label } from 'reactstrap'
import { useDispatch } from 'react-redux'
import Alert from '../../../../components/Content/Alert'
import { deleteFactory, editFactory, postFactory } from '../../../../redux/action/master/factoryAction'
import { useTranslation } from 'react-i18next'

const FormFactory = ({param}) => {
    const { isOpenFactory, idFactory, dataFactoryById, stateFormFactory, isLoadingFactory } = useSelector((state)=>state.factory)
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const handleFormSubmit = (values) =>{
        // console.log(values)
        param.data = values
        if(stateFormFactory === 'add'){
            dispatch(postFactory(param))
        }else if(stateFormFactory === 'edit'){
            param.id = idFactory
            dispatch(editFactory(param))
        }else{
            param.id = idFactory
            dispatch(editFactory(param))
        }
    }
    const initialValues = {
        name: idFactory ==='' ? '': dataFactoryById.name,
        location: idFactory ==='' ? '': dataFactoryById.location,
        longitude: idFactory ==='' ? '': dataFactoryById.longitude,
        latitude: idFactory ==='' ? '': dataFactoryById.latitude,
        phone: idFactory ==='' ? '': dataFactoryById.phone,
        description: idFactory ==='' ? '': dataFactoryById.description,
        isActive: idFactory ==='' ? '': dataFactoryById.isActive
    }
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required!'),
        location: Yup.string().required('Location is required!'),
        longitude: Yup.string().required('Longitude is required!'),
        latitude: Yup.string().required('Latitude is required!'),
        description: Yup.string().required('Description is required!'),
      })
    return stateFormFactory === 'delete' ? (
        <Alert 
            isOpen={isOpenFactory} 
            text={t("Are You Sure for Delete This Data ?")}
            toggle={()=>{dispatch(openFormFactory({open: !isOpenFactory, type: ''}))}} 
            close={()=>{dispatch(openFormFactory({open: !isOpenFactory, type: ''}))}} />
    ) : (
        <ModalComponent 
            isOpen={isOpenFactory} 
            close={()=>{dispatch(openFormFactory({open: !isOpenFactory, type: ''}))}}
            title={"Form Data "+t("Factory")}
            idForm="factory-form"
            toggle={()=>{dispatch(openFormFactory({open: true, type: 'add'}))}}
            isLoading={isLoadingFactory}
        >
            <Formik 
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit} id="factory-form">
                        <div className='row'>
                            <Col xs={12} sm={12} lg={6} md={6}>
                                <FormGroup className='mb-3'>
                                    <Label>{t('Name')}</Label>
                                    <Input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.name && !!errors.name}
                                    />
                                    <FormFeedback>{errors.name}</FormFeedback>
                                </FormGroup>
                                <FormGroup className='mb-3'>
                                    <Label>{t('Location')}</Label>
                                    <Input
                                        type="text"
                                        id="location"
                                        name="location"
                                        value={values.location}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.location && !!errors.location}
                                    />
                                    <FormFeedback>{errors.location}</FormFeedback>
                                </FormGroup>

                                <FormGroup className='mb-3'>
                                    <Label>{t('Phone')}</Label>
                                    <Input
                                        type="text"
                                        id="phone"
                                        name="phone"
                                        value={values.phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.phone && !!errors.phone}
                                    />
                                    <FormFeedback>{errors.phone}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={12} lg={6} md={6}>
                                <FormGroup className='mb-3'>
                                    <Label>{t('Longitude')}</Label>
                                    <Input
                                        type="text"
                                        id="longitude"
                                        name="longitude"
                                        value={values.longitude}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.longitude && !!errors.longitude}
                                    />
                                    <FormFeedback>{errors.longitude}</FormFeedback>
                                </FormGroup>
                                <FormGroup className='mb-3'>
                                    <Label>{t('Latitude')}</Label>
                                    <Input
                                        type="text"
                                        id="latitude"
                                        name="latitude"
                                        value={values.latitude}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.latitude && !!errors.latitude}
                                    />
                                    <FormFeedback>{errors.latitude}</FormFeedback>
                                </FormGroup>
                                <FormGroup className='mb-3'>
                                    <Label>{t('Description')}</Label>
                                    <Input
                                        type="text"
                                        id="description"
                                        name="description"
                                        value={values.description}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.description && !!errors.description}
                                    />
                                    <FormFeedback>{errors.description}</FormFeedback>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            name="isActive"
                                            value={values.isActive}
                                            onChange={handleChange}
                                            checked={values.isActive}
                                        />
                                        Is Active ? 
                                    </Label>
                                </FormGroup>
                            </Col>
                        </div>
                    </form>
                )}
            </Formik>
        </ModalComponent>
    )
}

export default FormFactory