import React, { useEffect, useRef, useState } from 'react'
import PropTypes from "prop-types"
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Col, FormGroup, Label, Row } from 'reactstrap'
import DatatableComponent from '../../components/Content/Datatable'
import Datatable from '../../components/Content/Datatable'
import ModalComponent from '../../components/Content/Modal'
import { getAreaPage } from '../../redux/action/master/areaAction'
import { openFormArea } from '../../redux/slice/master/areaSlice'
import FormArea from './util/Area/FormArea'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getBusByProviderId } from '../../redux/action/master/busAction'
import Select from "react-select";
import { getFactoryById, getFactoryList } from '../../redux/action/master/factoryAction'
import { useTranslation } from 'react-i18next'
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.full.min.js';// Import CSS Select2
import $ from 'jquery'; // Import jQuery
import FilterSelect from '../../components/Content/FilterSelect'

const Area = () => {
  const { isOpenArea, dataArea, isLoadingArea } = useSelector((state)=>state.area)
  const selectRef = useRef(null);
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState('')
  const [filter, setFilter] = useState([{name: '', factory: ''}])
  const countPerPage = 10
  const { dataFactory } = useSelector((state)=>state.factory)

  const [dataSelect, setSelect] = useState([{factory: ''}])
  const [selected, setSelected] = useState([{factory: ''}])
  const onChange = (e, field) => {
    let arr = [...filter]
    if (field === 'name') {
      arr[0].name = e.target.value
    }
    if (field === 'factory') {
      arr[0].factory = e
    }
    setFilter(arr)
  }
  const columns = [
    {
        name: (
          <div className='row' style={{width: '100%', marginBottom: '13px'}}>
            <Col lg={12} md={12} sm={12} xs={12} className='mb-2'>
              {t("Name")}
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <input type='text' onChange={(e)=> onChange(e, 'name')} className='form-control' placeholder={'Search By '+ t("Name")} />
            </Col>
          </div>
        ),
        selector: (row) => row.name,
    },
    {
        name: (
          <div className='row' style={{width: '100%', marginBottom: '13px'}}>
            <Col lg={12} md={12} sm={12} xs={12} className='mb-2'>
              {t("Factory")}
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <FilterSelect options={dataSelect[0].factory} onChange={(e)=>onChange(e,'factory')} />
            </Col>
          </div>
        ),
        selector: (row) => row.factoryName,
    },
    {
      name: (<>{t("Action")}</>),
      cell: (row) => (
        <div style={{ padding: '10px 0' }}>
          <button 
            className='btn btn-outline-secondary btn-sm' 
            onClick={()=>{
              dispatch(openFormArea({open: !isOpenArea, type: 'edit',data: row, id: row.id}))
            }} 
            style={{marginRight: 5}}>
              {t("Edit")}
            </button> 
            <button 
            className='btn btn-danger btn-sm' 
            onClick={()=>{
              dispatch(openFormArea({open: !isOpenArea, type: 'delete',data: row, id: row.id}))
            }} 
            style={{marginRight: 5}}>
              {t("Delete")}
            </button>
        </div>
      ),
    },
  ]
  const { user } = useSelector((state) => state.auth)
  const param = {
    token: user.access_token,
    sort: sort,
    size: countPerPage,
    select: selected,
    filter: filter,
    page: page,
  }

  useEffect(() => {
    let arr = [...dataSelect]
    let selectedArr = [...selected]
    if (dataFactory.length > 0) {
        let newArr = dataFactory.map(({ id, name }) => ({ value: id, label: name }))
        // console.log(newArr)
        arr[0].factory = newArr
    }
    selectedArr[0].factory = arr[0].factory[0]
    setSelect(arr)
    setSelected(selectedArr)
  },[dataFactory])
  
  useEffect(()=>{
    if(selected[0].factory !== ''){
      dispatch(getAreaPage(param))
    }
  },[page, filter, selected, sort])
  useEffect(()=>{
    dispatch(getFactoryList(param))
  },[])

  useEffect(() => {
    $(selectRef.current).select2(); // Inisialisasi Select2 pada komponen yang di-refer oleh selectRef
    return () => {
      $(selectRef.current).select2('destroy'); // Hancurkan Select2 ketika komponen di-unmount
    };
  }, []);
  return (
    <React.Fragment>
        <div className="page-content">
            {/* Render Breadcrumb */}
            <Breadcrumbs title={t("Master Data")} breadcrumbItem={t("Area")} />
            {/* Content */}
            <Row>
                <div className="col-6 mb-3">
                    <button className="btn btn-primary waves-effect waves-light" style={{marginRight: 5}}
                        onClick={()=>{dispatch(openFormArea({open: !isOpenArea, type: 'add'}))}}
                        data-toggle="modal"
                        data-target=".bs-example-modal-center">
                          + {t("Add Data")}
                    </button>
                </div>
                <div className="col-6 mb-3" style={{textAlign: 'right'}}>
                  {isLoadingArea ?
                  (
                    <div>
                      <div className="spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      <div className="spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      <div className="spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <button className="btn btn-primary waves-effect waves-light"
                        onClick={()=>setFilter([{name: '', factory: ''}])}>
                          <i className="mdi mdi-refresh"></i> Reset Filter
                    </button>)}
                </div>
                <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <DatatableComponent  
                            data={dataArea.content}
                            page={page}
                            countPerPage={countPerPage}
                            columns={columns}
                            totalElements={dataArea.totalElements}
                            setPage={setPage}
                            columnFilter
                            columnSorter
                            pagination
                            tableProps={{
                                hover: true,
                            }} 
                        />
                      </div>
                    </div>
                </div>
            </Row>
        </div>
        <FormArea isOpen={isOpenArea} onClose={()=> dispatch(openFormArea({open: !isOpenArea, type: ''}))} param={param} />
    </React.Fragment>

  )
}
export default Area