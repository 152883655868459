import React from 'react'
import { useSelector } from 'react-redux'
import ModalComponent from '../../../../components/Content/Modal'
import { openFormBusProvider } from '../../../../redux/slice/master/busproviderSlice'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Col, FormFeedback, FormGroup, Input, Label } from 'reactstrap'
import { useDispatch } from 'react-redux'
import Alert from '../../../../components/Content/Alert'
import { deleteBusProvider, editBusProvider, postBusProvider } from '../../../../redux/action/master/busproviderAction'
import { useTranslation } from 'react-i18next'

const FormBusProvider = ({param}) => {
    const { isOpenBusProvider, idBusProvider, dataBusProviderById, stateFormBusProvider, isLoadingBusProvider } = useSelector((state)=>state.busProvider)
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const handleFormSubmit = (values) =>{
        // console.log(values)
        param.data = values
        if(stateFormBusProvider === 'add'){
            dispatch(postBusProvider(param))
        }else if(stateFormBusProvider === 'edit'){
            param.id = idBusProvider
            dispatch(editBusProvider(param))
        }else{
            param.id = idBusProvider
            dispatch(deleteBusProvider(param))
        }
    }
    const initialValues = {
        name: idBusProvider ==='' ? '': dataBusProviderById.name,
        alias: idBusProvider ==='' ? '': dataBusProviderById.alias,
        descr: idBusProvider ==='' ? '': dataBusProviderById.descr,
    }
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required!'),
        alias: Yup.string().required('Alias is required!'),
        descr: Yup.string().required('Description is required!'),
      })
    return stateFormBusProvider === 'delete' ? (
        <Alert 
            isOpen={isOpenBusProvider} 
            action={handleFormSubmit}
            text={t("Are You Sure for Delete This Data ?")}
            toggle={()=>{dispatch(openFormBusProvider({open: !isOpenBusProvider, type: ''}))}} 
            close={()=>{dispatch(openFormBusProvider({open: !isOpenBusProvider, type: ''}))}} />
    ) : (
        <ModalComponent 
            isOpen={isOpenBusProvider} 
            close={()=>{dispatch(openFormBusProvider({open: !isOpenBusProvider, type: ''}))}}
            title={"Form Data "+t("Bus Provider")}
            idForm="busprovider-form"
            toggle={()=>{dispatch(openFormBusProvider({open: true, type: 'add'}))}}
            isLoading={isLoadingBusProvider}
        >
            <Formik 
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit} id="busprovider-form">
                        <div className='row'>
                            <Col xs={12} sm={12} lg={12} md={12}>
                                <FormGroup className='mb-3'>
                                    <Label>{t('Name')}</Label>
                                    <Input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.name && !!errors.name}
                                    />
                                    <FormFeedback>{errors.name}</FormFeedback>
                                </FormGroup>
                                <FormGroup className='mb-3'>
                                    <Label>{t('Alias')}</Label>
                                    <Input
                                        type="text"
                                        id="alias"
                                        name="alias"
                                        value={values.alias}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.alias && !!errors.alias}
                                    />
                                    <FormFeedback>{errors.alias}</FormFeedback>
                                </FormGroup>
                                <FormGroup className='mb-3'>
                                    <Label>{t('Description')}</Label>
                                    <Input
                                        type="text"
                                        id="descr"
                                        name="descr"
                                        value={values.descr}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.descr && !!errors.descr}
                                    />
                                    <FormFeedback>{errors.descr}</FormFeedback>
                                </FormGroup>
                            </Col>
                        </div>
                    </form>
                )}
            </Formik>
        </ModalComponent>
    )
}

export default FormBusProvider