import React, { Component, useEffect, useState } from "react"
import Leaflet from "leaflet"
import { Map, TileLayer, Marker, Popup } from "react-leaflet"
import "leaflet/dist/leaflet.css"
import BingLayer from "./BingLayer";
import { Card, CardBody, CardHeader, Col, Collapse, FormGroup, Label } from "reactstrap";
import marker from 'leaflet/dist/images/marker-icon.png'
import L from 'leaflet'
import { useDispatch } from "react-redux";
import { getBusProviderList } from "../../redux/action/master/busproviderAction";
import Select from "react-select";
import { useSelector } from "react-redux";
import { getRouteListByIdBusProvider } from "../../redux/action/master/routeAction";
import { getMapStationDashboard } from "../../redux/action/dashboardAction";
import { useTranslation } from "react-i18next";

Leaflet.Icon.Default.imagePath = "../node_modules/leaflet"

delete Leaflet.Icon.Default.prototype._getIconUrl

Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
})
let markers = L.icon({
  iconUrl: marker, // Ubah ukuran ikon menjadi 32x32 piksel
})
const SimpleMap = ({param}) => {
  var BING_KEY = 'AuhiCJHlGzhg93IqUH_oCpl_-ZUrIE6SPftlyGYUvr9Amx5nzA-WqGcPquyFZl4L'
  const position = [35.8039419, 10.5639663]
  const {t} = useTranslation()
  //
  const { dataBusProvider } = useSelector((state)=>state.busProvider)
  const { dataRoute } = useSelector((state)=>state.route)
  const { dataMap } = useSelector((state)=> state.dashboard)
  const dispatch = useDispatch();
  //
  const [dataSelect, setSelect] = useState([{busProvider: '', route: ''}])
  const [selected, setSelected] = useState([{busProvider: '', route: ''}])
  const [collapse, setCollapse] = useState(false)
  //
  useEffect(()=>{
    dispatch(getBusProviderList(param))
  },[])
  useEffect(()=>{
    if(selected[0].busProvider !== ''){
      param.idBusProvider = selected[0].busProvider.value
      param.idRoute = selected[0].route === '' ? '' : selected[0].route.value
      dispatch(getMapStationDashboard(param))
      dispatch(getRouteListByIdBusProvider(param))
    }
  },[selected])
  useEffect(() => {
    let arr = [...dataSelect]
    let selectedArr = [...selected]
    if (dataBusProvider.length > 0) {
        let newArr = dataBusProvider.map(({ id, name }) => ({ value: id, label: name }))
        // console.log(newArr)
        arr[0].busProvider = newArr
        selectedArr[0].busProvider = newArr[0]
    }
    
    setSelected(selectedArr)
    setSelect(arr)
  },[dataBusProvider])
  useEffect(()=>{
    let arr = [...dataSelect]
    if (dataRoute.length > 0) {
      let newArr = dataRoute.map(({ id, name }) => ({ value: id, label: name }))
      // console.log(newArr)
      arr[0].route = newArr
    }
    setSelect(arr)
  },[dataRoute])
  const detailTooltipIn = (e) => {
    // console.log('in')
      e.target.openPopup()
  }
  const detailTooltipOut = (e) => {
    e.target.closePopup()
  }
  return (
      <React.Fragment>
          <Card>
            <Collapse isOpen={collapse}>
                <CardHeader style={{padding: '20px 17px'}}>
                  <div className="row">
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <FormGroup className='mb-3'>
                          <Label>{t('Bus Provider')}</Label> 
                          <Select
                              styles={{
                                  control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  width: '100%',
                                  appearance: 'auto',
                                  }),
                              }}
                              onChange={(e)=>{
                                  let arr = [...selected]
                                  arr[0].busProvider = e
                                  arr[0].route = ''
                                  setSelected(arr)
                                  param.idBusProvider = e.value
                                  dispatch(getRouteListByIdBusProvider(param))
                                  // console.log(labelRuas)
                                  // console.log(e.value)
                                  // handleChange(e)
                              }}
                              name="busProviderId"
                              id="busProviderId"
                              value={selected[0].busProvider}
                              options={dataSelect[0].busProvider}
                          />
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <FormGroup className='mb-3'>
                          <Label>{t('Route')}</Label> 
                          <Select
                              styles={{
                                  control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  width: '100%',
                                  appearance: 'auto',
                                  }),
                              }}
                              onChange={(e)=>{
                                  let arr = [...selected]
                                  arr[0].route = e
                                  setSelected(arr)
                                  // console.log(labelRuas)
                                  // console.log(e.value)
                                  // handleChange(e)
                              }}
                              name="routeId"
                              id="routeId"
                              value={selected[0].route}
                              options={dataSelect[0].route}
                          />
                      </FormGroup>
                    </Col>
                  </div>
                </CardHeader>
              </Collapse>
              <CardBody>
                <div className="row">
                  <Col lg={8} md={8} sm={8} xs={8}>
                    <span style={{fontSize: '14px'}}>{t('Provider')} : <b>{selected[0].busProvider === '' ? '' : selected[0].busProvider.label}</b></span>
                    <br></br>
                    <span style={{fontSize: '14px'}}>{t('Route')} : <b>{selected[0].route === '' ? '-' : selected[0].route.label}</b></span>
                  </Col>
                  <Col lg={4} md={4} sm={4} xs={4} style={{textAlign: 'right'}}>
                    <button className="btn btn-light" onClick={()=>setCollapse(!collapse)}><i className="fas fa-filter" style={{fontSize: '12px'}}></i></button>
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12} style={{marginTop: '10px'}}>
                    <div id="simple-gmaps" className="gmaps" style={{ position: "relative", zIndex: 0, height: 583 }}>
                      <Map
                      center={position}
                      zoom={9.25}
                      style={{ height: "583px" }}
                      zoomControl={true}
                      >
                        <BingLayer bingkey={BING_KEY} type="RoadOnDemand" />

                        {dataMap.map((key, index) =>
                          <Marker
                            key={index}
                            position={[key.latitude, key.longitude]}
                            icon={markers}
                            eventHandlers={{
                              mouseover: (e) => detailTooltipIn(e),
                              mouseout: (e) => detailTooltipOut(e),
                            }}
                          >
                            <Popup>
                              <table style={{ width: '100%' }}>
                                <tbody>
                                  <tr>
                                    <th>{t('Station')}&nbsp;</th>
                                    <td> : </td>
                                    <td>&nbsp;{key.stationName}</td>
                                  </tr>
                                  <tr>
                                    <th>{t('Provider')}&nbsp;</th>
                                    <td> : </td>
                                    <td>&nbsp;{key.providerName}</td>
                                  </tr>
                                  <tr>
                                    <th>{t('Route')}&nbsp;</th>
                                    <td> : </td>
                                    <td>&nbsp;{key.routeName}</td>
                                  </tr>
                                  {/* Tambahkan baris lain jika diperlukan */}
                                </tbody>
                              </table>
                            </Popup>
                          </Marker>
                        )}
                      </Map>
                    </div>
                  </Col>
                </div>
              </CardBody>
          </Card>
      </React.Fragment>
  )
}

export default SimpleMap
