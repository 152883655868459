import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_EMPLOYEES, API_MASTER } from '../../../constants'
import { openMessage } from '../../slice/messageSlice'
// import { openMessage } from '../slice/messageSlice'
// import { openForm } from '../slice/api/weeklyScheduleSlice'

export const getWeeklySchedulePage = createAsyncThunk('weekly/schedule/page', async (param, thunkAPI) => {
  const respone = await fetch(
    `${API_EMPLOYEES}/api/weekly-schedule/page?page=${param.page}&size=${param.size}&sort=${param.select},${param.sort}&${param.select}=${param.filter}`,
    {
      method: 'get',
      headers: new Headers({
        Authorization: 'Bearer ' + param.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      }),
    },
  )
  const responeResult = await respone.json()
  // console.log("as")
  return responeResult
})
export const getEmployeeSchedule = createAsyncThunk('weekly/schedule/employee-schedule', async (param, thunkAPI) => {
  console.log(param)
  const respone = await fetch(
    `${API_EMPLOYEES}/api/employee-schedule/count?idWeekly=${param.id}`,
    {
      method: 'get',
      headers: new Headers({
        Authorization: 'Bearer ' + param.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      }),
    },
  )
  const responeResult = await respone.json()
  // console.log("as")
  return responeResult
})
export const getWeeklyScheduleById = createAsyncThunk('weekly/schedule/byId', async (param, thunkAPI) => {
  // console.log(param);
  const response = await fetch(`${API_MASTER}/api/weekly/schedule/${param.id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + param.token,
    },
  })
  if (response.status === 200) {
    const responseJson = await response.json()
    return responseJson
  }
})
export const getWeeklyScheduleByRegionalId = createAsyncThunk('weekly/schedule/regionalId', async (param, thunkAPI) => {
  // console.log(param);
  const response = await fetch(`${API_MASTER}/api/weeklySchedule?regionalId=${param.regional}`, {
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
  })
  if (response.status === 200) {
    const responseJson = await response.json()
    return responseJson
  }
})
export const getWeeklyScheduleList = createAsyncThunk('weekly/schedule/list', async (param, thunkAPI) => {
  const respone = await fetch(`${API_MASTER}/api/weekly/schedule/`, {
    method: 'get',
    headers: new Headers({
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    }),
  })
  const responeResult = await respone.json()
  // console.log("as")
  return responeResult
})
export const postWeeklySchedule = createAsyncThunk('weekly/schedule/post', async (param, thunkAPI) => {
  // console.log(param);
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
    body: JSON.stringify(param.data),
  }
  let response = await fetch(`${API_MASTER}/api/weekly/schedule`, requestOptions)
  if (response.status === 200) {
    console.log(response.status)
    const responseJson = await response.json()

    thunkAPI.dispatch(
      openMessage({
        message: 'Success Insert Weekly Schedule!',
        type: 'success',
        open: true,
      }),
    )
    thunkAPI.dispatch(getWeeklySchedulePage(param))
    return responseJson
  }
  if (response.status === 400) {
    thunkAPI.dispatch(openMessage({ message: 'Insert Weekly Schedule Failed', type: 'danger', open: true }))
  }
})
export const editWeeklySchedule = createAsyncThunk('weekly/schedule/update', async (param, thunkAPI) => {
  // console.log(param);
  const requestOptions = {
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
    body: JSON.stringify(param.data),
  }
  let response = await fetch(`${API_MASTER}/api/weekly/schedule/${param.id}`, requestOptions)
  if (response.status === 200) {
    // thunkAPI.dispatch(
    //   openMessage({ message: 'Edit Site Successfull', type: 'success', open: true })
    // );
    thunkAPI.dispatch(getWeeklySchedulePage(param))
  }
  // .then(getApi())
})
export const deleteWeeklySchedule = createAsyncThunk('weekly/schedule/delete', async (param, thunkAPI) => {
  // console.log(param);
  const requestOptions = {
    method: 'Delete',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
  }
  let response = await fetch(`${API_MASTER}/api/weekly/schedule/${param.id}`, requestOptions)
  if (response.status === 200) {
    console.log(response.status)
    const responseJson = await response.json()

    thunkAPI.dispatch(
      openMessage({
        message: 'Success Delete Weekly Schedule!',
        type: 'success',
        open: true,
      }),
    )
    thunkAPI.dispatch(getWeeklySchedulePage(param))
    return responseJson
  }
  if (response.status === 400) {
    thunkAPI.dispatch(openMessage({ message: 'Delete Weekly Schedule Failed', type: 'danger', open: true }))
  }
  // .then(getApi())
})

export const getEmployeeScheduleByIdWeekly = createAsyncThunk('employee-schedule/idWeekly', async (param, thunkAPI) => {
  // console.log(param);
  const response = await fetch(`${API_EMPLOYEES}/api/employee-schedule?idWeekly=${param.idWeekly}&idShift=${param.idShift}`, {
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
  })
  if (response.status === 200) {
    const responseJson = await response.json()
    return responseJson
  }
})
export const getEmployeeScheduleByIdWeeklyPage = createAsyncThunk('employee-schedule/idWeekly/page', async (param, thunkAPI) => {
  // console.log(param);
  const response = await fetch(`${API_EMPLOYEES}/api/employee-schedule/weekly?idWeekly=${param.idWeekly}&idShift=${param.idShift}&name=${param.name}`, {
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
  })
  if (response.status === 200) {
    const responseJson = await response.json()
    return responseJson
  }
})
export const editEmployeeSchedule = createAsyncThunk('employee-schedule/update', async (param, thunkAPI) => {
  let oldArr = []
  param.oldStationData.map((key,index)=>{
    oldArr.push(key.idEmployee)
  })
  let arr2 = []
  for (let i = 0; i < oldArr.length; i++) {
    // Memeriksa apakah elemen tersebut tidak ada di array2
    if (param.stationData.indexOf(oldArr[i]) === -1) {
      var result = param.oldStationData.filter(function(item) {
        return item.idEmployee === oldArr[i];
      });
      console.log(result[0].id)
    //   console.log(param.oldStationData[i])
      const requestOptions = {
        method: 'Delete',
        headers: {
          Authorization: 'Bearer ' + param.token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
        },
      }
      await fetch(`${API_EMPLOYEES}/api/employee-schedule/${result[0].id}`, requestOptions)
    }
  }

  // Mengecek setiap elemen dalam array2
  for (let j = 0; j < param.stationData.length; j++) {
    // Memeriksa apakah elemen tersebut tidak ada di array1
    if (oldArr.indexOf(param.stationData[j]) === -1) {
      arr2.push(param.stationData[j]);
    }
  }
  let bodyStation = []
  for(let i = 0 ; i < arr2.length ; i++){
    // let poseidon = param.station.filter(item => item.id === arr2[i])
    bodyStation.push({
      idEmployee : arr2[i],
      idWeeklySchedule : param.idWeekly,
      idShift : param.idShift,
      descr : "test add by postman"
    })
  }
  console.log(bodyStation)
  const requestOptionsStation = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
    body: JSON.stringify(bodyStation),
  }
  const response = await fetch(`${API_EMPLOYEES}/api/employee-schedule`, requestOptionsStation)
  if (response.status === 200) {
    // thunkAPI.dispatch(getEmployeeScheduleByIdWeeklyPage(param.param))
    param.param.id = param.idWeeklySchedule
    console.log(param.param.idWeekly)
    thunkAPI.dispatch(getEmployeeSchedule(param.param))
    thunkAPI.dispatch(
      openMessage({ message: 'Update Employee Schedule', type: 'success', open: true })
    );
    return {state: 'detailEmployee', dataShiftById: param.dataByTanggal}
    // thunkAPI.dispatch(changeViewModal({state: 'detailEmployee', dataShiftById: param.dataByTanggal}))
  }
  // console.log('asu')
  // .then(getApi())
})
export const deleteEmployeeSchedule = createAsyncThunk('employe-schedule/delete', async (param, thunkAPI) => {
  // console.log(param);
  for(let i = 0 ; i < param.data.length ; i++){
    const requestOptions = {
      method: 'Delete',
      headers: {
        Authorization: 'Bearer ' + param.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      },
    }
    var response = await fetch(`${API_EMPLOYEES}/api/employee-schedule/${param.data[i]}`, requestOptions)
  }
  thunkAPI.dispatch(
    openMessage({
      message: 'Success Delete Weekly Schedule!',
      type: 'success',
      open: true,
    }),
  )
  thunkAPI.dispatch(getEmployeeScheduleByIdWeeklyPage(param))
  // .then(getApi())
})
export const downloadTemplateWeekly = createAsyncThunk(
  'weekly/download/template',
  async (param, thunkAPI) => {
    // console.log(param)
    const respone = await fetch(`${API_EMPLOYEES}/api/weekly/download`, {
      method: 'get',
      headers: new Headers({  
        Authorization: 'Bearer ' + param.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      }),
    })
    const responeResult = await respone.blob()
    // console.log(await respone.blob())
    return responeResult
  },
)
export const uploadWeekly = createAsyncThunk(
  'weekly/upload',
  async (param, thunkAPI) => {
    console.log(param.data)

    const formData = new FormData();
    formData.append('file', param.data.file);

    const respone = await fetch(`${API_EMPLOYEES}/api/weekly/upload`, {
      method: 'post',
      headers: new Headers({  
        Authorization: 'Bearer ' + param.token,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      }),
      body: formData,
    })
    if (respone.status === 200) {
      console.log(respone.status)
      const responseJson = await respone.json()
      if(responseJson.message === 'Failed'){
        thunkAPI.dispatch(
          openMessage({
            message: 'Failed Upload Employee!',
            type: 'danger',
            open: true,
          }),
        )
      }else{
        thunkAPI.dispatch(
          openMessage({
            message: 'Success Upload Employee!',
            type: 'success',
            open: true,
          }),
        )
      }
      thunkAPI.dispatch(getWeeklySchedulePage(param))
      return responseJson
    }
  },
)
