import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import styled, { css, keyframes } from 'styled-components';
import { array } from 'yup';
import { openDetailExpand } from '../../../../redux/slice/master/weeklyScheduleSlice';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;
const Content = styled.div`
padding: 15px 0 15px 24px;
${props =>
    props.expanded
    ? css`
        animation: ${fadeIn} 0.3s ease;
    `
    : css`
        animation: ${fadeOut} 0.3s ease;
        opacity: 0;
    `};
`;
const EmployeeSchedule = ({data, expanded}) => {
    const { isLoadingEmployeeSchedule, idWeeklySchedule } = useSelector((state)=>state.weeklySchedule)
    const [value, setValue] = useState([])
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const setDataExist = () =>{
        let arr = []
        data.map((key, index)=>{
            let count = 0
            key.schedules.map((keys, indexs)=> {
                count = count + keys.employees
            })
            if(count > 0){
                arr.push(key)
            }
        })
        setValue(arr)
    }
    useEffect(()=>{
        if(data.length > 0){
            setDataExist()
        }
    },[data])
    return (
        <Content id="coba" expanded={expanded}>
            <span>{t('Employee Schedule')}</span>
            <table className='table table-borderless'>
                <thead>
                    <tr style={{fontSize: '13px'}}>
                        <th>{t('Shift')}</th>
                        {value.length > 0 ? (
                            value[0].schedules.map((key, index)=>(
                                <th>{key.tanggal}</th>
                            ))
                        ):(
                            <th></th>
                        )}
                        <th>{t('Action')}</th>
                    </tr>
                </thead>
                {!isLoadingEmployeeSchedule ? (
                    <tbody>
                    {
                        value.map((key, index)=>(
                            <tr style={{fontSize: '13px'}}>
                                <td>{key.shift}</td>
                                {key.schedules.map((keys, indexs)=>(

                                    <td>{keys.employees}</td>
                                ))}
                                <td>
                                    <button className='btn btn-outline-secondary btn-sm' 
                                    onClick={()=>{
                                      dispatch(openDetailExpand({open: 'open',data: key, id: key.id}))
                                    }} 
                                    style={{marginRight: 5}}>
                                    {t('Edit')}
                                    </button>           
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                ) : (
                    <div>
                        <div className="spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                        </div>
                        <div className="spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                        </div>
                        <div className="spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}
            </table>
        </Content>
    )
}

export default EmployeeSchedule