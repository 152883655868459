import { createSlice } from "@reduxjs/toolkit";
import { deleteEmployeeSchedule, deleteWeeklySchedule, downloadTemplateWeekly, editEmployeeSchedule, editWeeklySchedule, getEmployeeSchedule, getEmployeeScheduleByIdWeekly, getEmployeeScheduleByIdWeeklyPage, getWeeklyScheduleById, getWeeklyScheduleByRegionalId, getWeeklyScheduleList, getWeeklySchedulePage, postWeeklySchedule, uploadWeekly } from "../../action/master/weeklyScheduleAction";

const initialState = {
    dataWeeklySchedule: [],
    isOpenWeeklySchedule: false,
    stateFormWeeklySchedule: '',
    dataEmployeeScheduleById: [], 
    dataEmployeeScheduleByIdShift: '',
    dataEmployeeScheduleByTanggal: '',
    dataEmployeeScheduleByIdWeekly: [],
    dataWeeklyScheduleById: '',
    idWeeklySchedule: '',
    stateModal: 'employee',
    isLoadingWeeklySchedule: false,
    isLoadingEmployeeSchedule: false,
    stateShift: 'new'
};
const weeklyScheduleSlice = createSlice({
    name: "weeklySchedule",
    initialState,
    reducers: {
        openFormWeeklySchedule: (state, action) => {
            if(action.payload.open){
                if(action.payload.type !== 'add'){
                    state.dataWeeklyScheduleById = action.payload.data
                    state.idWeeklySchedule = action.payload.id
                    state.stateModal = 'employee'
                }
            }else{
                state.dataWeeklyScheduleById = ''
                state.idWeeklySchedule = ''
            }
            state.stateFormWeeklySchedule = action.payload.type
            state.isOpenWeeklySchedule = action.payload.open;
        },
        openExpand: (state, action) => {
          if ((action.payload.open)) {
            state.dataWeeklyScheduleById = action.payload.dataRow
            state.idWeeklySchedule = action.payload.id
          } else {
            state.dataWeeklyScheduleById = ''
            state.idWeeklySchedule = ''
          }
        },

        openDetailExpand: (state, action) => {
          if ((action.payload.state = 'open')) {
            state.dataEmployeeScheduleByIdShift = action.payload.data
            state.idWeeklySchedule = action.payload.id
            state.stateModal = 'shift'
            state.isOpenWeeklySchedule = action.payload.open;
          }
        },
        changeViewModal: (state, action) => {
          // console.log(action.payload.state )
          if (action.payload.state === 'shift') {
            // state.dataWeeklyScheduleById = action.payloa d.data
            if(action.payload.dataShiftById !== ''){
              state.dataEmployeeScheduleByIdShift = action.payload.dataShiftById
              state.stateShift = 'exist'
            }else{
              state.dataEmployeeScheduleByIdShift = ''
              state.stateShift = 'new'
            }
            state.stateModal = action.payload.state
          }else if(action.payload.state === 'addShift') {
            state.stateModal = action.payload.state
          }else if(action.payload.state === 'detailEmployee') {
            state.dataEmployeeScheduleByTanggal = action.payload.dataShiftById
            state.stateModal = action.payload.state
          } else {
            state.dataEmployeeScheduleByIdShift = []
            state.stateModal = action.payload.state
          }
        },
        changeStateNewShift: (state, action) => {
          state.dataEmployeeScheduleByIdShift = action.payload.data
        },

        backAction: (state, action) => {
          state.stateModal = action.payload.state
        }
    },
    extraReducers: {
      [getWeeklySchedulePage.pending]: (state) => {
        state.isLoadingWeeklySchedule = true
      },
      [getWeeklySchedulePage.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataWeeklySchedule = action.payload.data
        state.isLoadingWeeklySchedule = false
      },
      [getWeeklySchedulePage.rejected]: (state) => {
        state.isLoadingWeeklySchedule = false
      },
      [getEmployeeSchedule.pending]: (state) => {
        state.isLoadingEmployeeSchedule = true
      },
      [getEmployeeSchedule.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataEmployeeScheduleById = action.payload.data
        state.isLoadingEmployeeSchedule = false
      },
      [getEmployeeSchedule.rejected]: (state) => {
        state.isLoadingEmployeeSchedule = false
      },
      [getWeeklyScheduleList.pending]: (state) => {
        state.isLoadingWeeklySchedule = true
      },
      [getWeeklyScheduleList.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataWeeklySchedule = action.payload.data
        state.isLoadingWeeklySchedule = false
      },
      [getWeeklyScheduleList.rejected]: (state) => {
        state.isLoadingWeeklySchedule = false
      },
      [getEmployeeScheduleByIdWeekly.pending]: (state) => {
        state.isLoadingEmployeeSchedule = true
      },
      [getEmployeeScheduleByIdWeekly.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataEmployeeScheduleByIdWeekly = action.payload.data
        state.isLoadingEmployeeSchedule = false
      },
      [getEmployeeScheduleByIdWeekly.rejected]: (state) => {
        state.isLoadingEmployeeSchedule = false
      },
      [getEmployeeScheduleByIdWeeklyPage.pending]: (state) => {
        state.isLoadingEmployeeSchedule = true
      },
      [getEmployeeScheduleByIdWeeklyPage.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataEmployeeScheduleByIdWeekly = action.payload.data
        state.isLoadingEmployeeSchedule = false
      },
      [getEmployeeScheduleByIdWeeklyPage.rejected]: (state) => {
        state.isLoadingEmployeeSchedule = false
      },
      [getWeeklyScheduleById.pending]: (state) => {
        state.isLoadingWeeklySchedule = true
      },
      [getWeeklyScheduleById.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataWeeklySchedule = action.payload.data
        state.isLoadingWeeklySchedule = false
      },
      [getWeeklyScheduleById.rejected]: (state) => {
        state.isLoadingWeeklySchedule = false
      },
      [getWeeklyScheduleByRegionalId.pending]: (state) => {
        state.isLoadingWeeklySchedule = true
      },
      [getWeeklyScheduleByRegionalId.fulfilled]: (state, action) => {
        // console.log(action.payload)
        state.dataWeeklyScheduleByRegionalId = action.payload.data
        state.isLoadingWeeklySchedule = false
      },
      [getWeeklyScheduleByRegionalId.rejected]: (state) => {
        state.isLoadingWeeklySchedule = false
      },
      [postWeeklySchedule.pending]: (state) => {
        state.isLoadingWeeklySchedule = true
      },
      [postWeeklySchedule.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.isOpenWeeklySchedule = false
        state.isLoadingWeeklySchedule = false
        state.stateFormWeeklySchedule = ''
      },
      [postWeeklySchedule.rejected]: (state) => {
        state.isLoadingWeeklySchedule = false
      },
      [editWeeklySchedule.pending]: (state) => {
        state.isLoadingWeeklySchedule = true
      },
      [editWeeklySchedule.fulfilled]: (state, action) => {
        state.isOpenWeeklySchedule = false
        state.isLoadingWeeklySchedule = false
        state.dataByIdWeeklySchedule = []
        state.idWeeklySchedule = 0
        state.stateFormWeeklySchedule = ''
      },
      [editWeeklySchedule.rejected]: (state) => {
        state.isLoadingWeeklySchedule = false
      },
      [editEmployeeSchedule.pending]: (state) => {
        state.isLoadingWeeklySchedule = true
      },
      [editEmployeeSchedule.fulfilled]: (state, action) => {
        state.isLoadingWeeklySchedule = false
        state.dataEmployeeScheduleByTanggal = action.payload.dataShiftById
        state.stateModal = action.payload.state
      },
      [editEmployeeSchedule.rejected]: (state) => {
        state.isLoadingWeeklySchedule = false
      },
      [deleteWeeklySchedule.pending]: (state) => {
        state.isLoadingWeeklySchedule = true
      },
      [deleteWeeklySchedule.fulfilled]: (state, action) => {
        state.isOpenWeeklySchedule = false
        state.isLoadingWeeklySchedule = false
        state.dataByIdWeeklySchedule = []
        state.idWeeklySchedule = 0
        state.stateFormWeeklySchedule = ''
      },
      [deleteWeeklySchedule.rejected]: (state) => {
        state.isLoadingWeeklySchedule = false
      },
      [deleteEmployeeSchedule.pending]: (state) => {
        state.isLoadingEmployeeSchedule = true
      },
      [deleteEmployeeSchedule.fulfilled]: (state, action) => {
        state.isLoadingEmployeeSchedule = false
      },
      [deleteEmployeeSchedule.rejected]: (state) => {
        state.isLoadingEmployeeSchedule = false
      },
      [downloadTemplateWeekly.pending]: (state) => {
        state.isLoadingDownload = true
      },
      [downloadTemplateWeekly.fulfilled]: (state, action) => {
        // console.log(action.payload)
        const url = window.URL.createObjectURL(new Blob([action.payload]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `Template-Employee.xlsx`)

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
        state.isLoadingDownload = false
      },
      [downloadTemplateWeekly.rejected]: (state) => {
        state.isLoadingDownload = false
      },

      [uploadWeekly.pending]: (state) => {
        state.isLoadingWeekly = true
      },
      [uploadWeekly.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.isOpenWeekly = false
        state.isLoadingWeekly = false
      },
      [uploadWeekly.rejected]: (state) => {
        state.isLoadingWeekly = false
      },
    }
});

export const {  openFormWeeklySchedule, openExpand, changeViewModal, backAction, openDetailExpand, changeStateNewShift } = weeklyScheduleSlice.actions;

export default weeklyScheduleSlice.reducer;