import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ModalComponent from '../../../components/Content/Modal'
import { openModalRole, openModalUser } from '../../../redux/slice/master/userSlice'
import { Col } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { DualListBox } from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { useTranslation } from 'react-i18next'

const FormRoleList = ({ dataListRole, selectedRole, onChange }) => {
    
    const {isOpenModalRole} = useSelector((state)=>state.user)
    const dispatch = useDispatch()
    const {t} = useTranslation()

    return  (
        <ModalComponent 
            isOpen={isOpenModalRole} 
            close={() => { dispatch(openModalRole({open: !isOpenModalRole}))}}
            title={"Data "+t("Role")}
            size="md"
            toggle={() => { dispatch(openModalRole({open: true, type: 'add'}))}}
        >
            <div className="row">
                <Col xs={12} sm={12} lg={12} md={12}>
                    <DualListBox
                        options={dataListRole}
                        selected={selectedRole}
                        onChange={onChange}
                        canFilter
                        showOrderButtons
                        filterPlaceholder="Filter..."
                        icons={{
                        moveLeft: <i className="fa fa-angle-left" />,
                        moveAllLeft: <i className="fa fa-angle-double-left" />,
                        moveRight: <i className="fa fa-angle-right" />,
                        moveAllRight: <i className="fa fa-angle-double-right" />,
                        moveDown: <i className="fa fa-angle-down" />,
                        moveUp: <i className="fa fa-angle-up" />,
                        }}
                    />
                </Col>
            </div>
        </ModalComponent> 
    )
}

export default FormRoleList