import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Row } from 'reactstrap'
import DatatableComponent from '../../../components/Content/Datatable'
import { getUserPage } from '../../../redux/action/master/userAction'
import { openModalUser } from '../../../redux/slice/master/userSlice'
import FormUser from './FormUser'

const User = () => {
  const { isOpenUser, dataUserPage, isLoadingUser } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState('')
  const [select, setSelect] = useState('')
  const [filter, setFilter] = useState('')
  const countPerPage = 10
  const {t} = useTranslation()

  const columns = [
    {
      name: (<>{t("Active")}</>),
      cell: (row) => (<i className={row.isActive == true ? 'fa fa-check text-primary fa-lg' : 'fa fa-times text-danger fa-lg'}></i>),
    },
    {
      name: (<>{t("Username")}</>),
      selector: (row) => row.username,
    },
    {
      name: (<>{t("Email")}</>),
      selector: (row) => row.email,
    },
    {
      name: (<>{t("Role Count")}</>),
      selector: (row) => row.roleId.length,
    },
    {
      right: true,
      cell: (row) => (
        <div className="py-3 d-flex justify-content-end">
          <button
            className='btn btn-outline-secondary btn-sm'
            onClick={() => {
              dispatch(openModalUser({ open: !isOpenUser, type: 'edit', data: row, id: row.id }))
            }}
            style={{ marginRight: 5 }}>
            {t("Edit")}
          </button>
          <button
            className='btn btn-danger btn-sm'
            onClick={() => {
              dispatch(openModalUser({ open: !isOpenUser, type: 'delete', data: row, id: row.id }))
            }}
            style={{ marginRight: 5 }}>
            
            {t("Delete")}
          </button>
        </div>
      ),
    },
  ]
  const { user } = useSelector((state) => state.auth)
  const param = {
    token: user.access_token,
    sort: sort,
    size: countPerPage,
    select: select,
    filter: filter,
    page: page,
  }

  useEffect(() => {
    dispatch(getUserPage(param))
  }, [page, filter, select, sort])

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">{t("User")}</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">ETMS LEONI {t("User")}</li>
                </ol>
              </div>

            </div>
          </div>

        </Row>
        <Row>
          <div className="col-6 mb-3">
            <button className="btn btn-primary waves-effect waves-light"
              onClick={() => { dispatch(openModalUser({ open: !isOpenUser, type: 'add' })) }}
              data-toggle="modal"
              data-target=".bs-example-modal-center">
              + {t("Add Data")}
            </button>
          </div>
          {isLoadingUser ?
            (
              <div className="col-6 mb-3" style={{ textAlign: 'right' }}>
                <div>
                  <div className="spinner-grow text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div className="spinner-grow text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div className="spinner-grow text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : null}
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <DatatableComponent
                  data={dataUserPage.content}
                  page={page}
                  countPerPage={countPerPage}
                  columns={columns}
                  totalElements={dataUserPage.totalElements}
                  setPage={setPage}
                  columnFilter
                  columnSorter
                  pagination
                  tableProps={{
                    hover: true,
                  }}
                />
              </div>
            </div>
          </div>
        </Row>
      </div>
      <FormUser isOpen={isOpenUser} onClose={() => dispatch(openModalUser({ open: !isOpenUser, type: '' }))} param={param} />
    </React.Fragment>

  )
}

export default User