import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, CardHeader } from "reactstrap"

import SimpleMap from "./SimpleMap";
import SimpleGoogleMap from "./SimpleGoogleMap";
import SimpleBingMap from "./SimpleBingMap";
import PolarChart from "./polarchart";
import PieChart from "./piechart";
import BarChart from "./barChart";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getBarDashboard } from "../../redux/action/dashboardAction";
import styled from "styled-components";
import BusShift from "./BusShift";
import EmployeeShift from "./EmployeeShift";
import { useTranslation } from "react-i18next";

const CardDash = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  color: white;
  word-wrap: break-word;
  background-color: rgba(40,61,146,255);
  background-clip: border-box;
  border: 0 solid #f6f6f6;
  border-radius: 0.25rem;
  margin-bottom: 24px;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
`
const CardDashBody = styled.div`
  flex: 1 1 auto;
  padding: 1.25rem 1.25rem;
  color: white;
`
const CardDashIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`
const Dashboard = () => {
  const { user } = useSelector((state) => state.auth)
  const { dataBar } = useSelector((state) => state.dashboard)
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const param = {
    token: user.access_token,
  }
  const CompCardDash = ({title, count, icon}) => {
    return (
      <CardDash>
        <CardDashBody>
          <span style={{fontSize: '12px'}}>{title}</span>
          <h3 style={{color: 'white'}}>{count}</h3>
        </CardDashBody>
        <CardDashIcon>
          <i className={icon} style={{color: 'rgba(255,255,255,0.1)', fontSize: '70px'}}></i>
        </CardDashIcon>
      </CardDash>
    )
  }
  useEffect(()=>{
    
    dispatch(getBarDashboard(param))
  },[])
  return (
    <React.Fragment>
      <div className="page-content">
  
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">{t('Dashboard')}</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">ETMS LEONI {t('Dashboard')}</li>
                </ol>
              </div>

            </div>
          </div>
        </Row>

        <Row>
          <Col lg={2} md={4} sm={4} xs={6}>
            <CompCardDash title={t("Total Providers")} count={dataBar !== '' ? dataBar.busProvider: 0} icon="fas fa-globe" />
          </Col>
          <Col lg={2} md={4} sm={4} xs={6}>
            <CompCardDash title={t("Total Routes")} count={dataBar !== '' ? dataBar.route: 0} icon="fas fa-route" />
          </Col>
          <Col lg={2} md={4} sm={4} xs={6}>
            <CompCardDash title={t("Total Stations")} count={dataBar !== '' ? dataBar.station: 0} icon="fas fa-building" />
          </Col>
          <Col lg={2} md={4} sm={4} xs={6}>
            <CompCardDash title={t("Total Buses")} count={dataBar !== '' ? dataBar.buses: 0} icon="fas fa-bus-alt" />
          </Col>
          <Col lg={2} md={4} sm={4} xs={6}>
            <CompCardDash title={t("Total Devices")} count={dataBar !== '' ? dataBar.devices: 0} icon="fas fa-hdd" />
          </Col>
          <Col lg={2} md={4} sm={4} xs={6}>
            <CompCardDash title={t("Total Employees")} count={dataBar !== '' ? dataBar.employee: 0} icon="fas fa-id-badge" />
          </Col>
        </Row>
        {/* <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row id="mini-widget" className="ps-3">
                  <Col md={2} className="top-row-dashboard">
                    <div className="mdi mdi-bus-multiple mb-1"><span>Total Bus Providers</span></div>
                    <h3>{dataBar !== '' ? dataBar.busProvider: 0}</h3>
                  </Col>
                  <Col md={2} className="top-row-dashboard">
                    <div className="mdi mdi-road-variant"><span>Total Routes</span></div>
                    <h3>{dataBar !== '' ? dataBar.route: 0}</h3>
                  </Col>
                  <Col md={2} className="top-row-dashboard">
                    <div className="mdi mdi-map-marker-outline"><span>Total Stations</span></div>
                    <h3>{dataBar !== '' ? dataBar.station: 0}</h3>
                  </Col>
                  <Col md={2} className="top-row-dashboard">
                    <div className="mdi mdi-bus-side"><span>Total Buses</span></div>
                    <h3>{dataBar !== '' ? dataBar.buses: 0}</h3>
                  </Col>
                  <Col md={2} className="top-row-dashboard">
                    <div className="mdi mdi-access-point-network"><span>Total Devices</span></div>
                    <h3>{dataBar !== '' ? dataBar.devices: 0}</h3>
                  </Col>
                  <Col md={2} className="top-row-dashboard">
                    <div className="mdi mdi-account"><span>Total Employees</span></div>
                    <h3>{dataBar !== '' ? dataBar.employee: 0}</h3>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row> */}

        <Row>
          <Col lg={7}>
            {/* <SimpleMap /> */}
            {/* <SimpleGoogleMap /> */}
            <SimpleBingMap param={param} />
          </Col>
          <Col lg={5}>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">{t("Number of Buses")}</CardTitle>
                    <div id="number-of-buses" className="number-of-buses" style={{ position: "relative" }}>
                      <BarChart />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                  <Card>
                    <CardBody>
                    <CardTitle className="h4 mb-4">{t('Bus Provider Route Coverage')}</CardTitle>
                    <div id="bus-coverage" className="bus-coverage" style={{ position: "relative" }}>
                      <PieChart />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
              </Col>
            </Row>
          </Col>
          <Col lg={12}>
              <BusShift param={param} />
          </Col>
          <Col lg={12}>
              <EmployeeShift param={param} />
          </Col>
        </Row>

        {/* <Row>
          <Col lg={6}>
            <Card>
              <CardBody>
                <h4 className="card-title mb-4">Bus Counting</h4>
                <div id="bus-counting" className="bus-counting" style={{ position: "relative" }}>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col lg={6}>
            <Card>
              <CardBody>
                <h4 className="card-title mb-4">Bus Counting</h4>
                <div id="bus-counting" className="bus-counting" style={{ position: "relative" }}>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg={6}>
            <Card>
              <CardBody>
                <h4 className="card-title mb-4">Bus Counting</h4>
                <div id="bus-counting" className="bus-counting" style={{ position: "relative" }}>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col lg={6}>
            <Card>
              <CardBody>
                <h4 className="card-title mb-4">Bus Counting</h4>
                <div id="bus-counting" className="bus-counting" style={{ position: "relative" }}>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row> */}

        <Row></Row>
      </div>
    </React.Fragment>
  )
}

export default Dashboard