import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_ACCOUNT } from '../../../constants'
import { openMessage } from '../../slice/messageSlice'

export const getUserPage = createAsyncThunk('user/page', async (param, thunkAPI) => {
  const respone = await fetch(`${API_ACCOUNT}/api/auth/page?page=${param.page}&size=${param.size}`,
    {
      method: 'get',
      headers: new Headers({
        Authorization: 'Bearer ' + param.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      }),
    },
  )
  const responeResult = await respone.json()
  // console.log("--------------page param:", `${API_ACCOUNT}/api/auth/page?page=${param.page}&size=${param.size}`)
  return responeResult
})

export const getUserList = createAsyncThunk('user/list', async (param, thunkAPI) => {
  const respone = await fetch(`${API_ACCOUNT}/api/auth`, {
    method: 'get',
    headers: new Headers({
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    }),
  })
  const responeResult = await respone.json()
  // console.log("as")
  return responeResult
})

export const getUserById = createAsyncThunk('api/auth/byId', async (param, thunkAPI) => {
  // console.log('------------------getUserById:',param);
  const response = await fetch(`${API_ACCOUNT}/api/auth/${param.id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + param.token,
    },
  })
  if (response.status === 200) {
    const responseJson = await response.json()
    return responseJson
  }
})

export const getUserByRegionalId = createAsyncThunk('api/auth/regionalId', async (param, thunkAPI) => {
  // console.log(param);
  const response = await fetch(`${API_ACCOUNT}/api/auth?regionalId=${param.regional}`, {
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
  })
  if (response.status === 200) {
    const responseJson = await response.json()
    return responseJson
  }
})

export const postUser = createAsyncThunk('api/auth/post', async (param, thunkAPI) => {
  // console.log(param);
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
    body: JSON.stringify(param.data),
  }
  let response = await fetch(`${API_ACCOUNT}/api/auth/register`, requestOptions)
  if (response.status === 200) {
    // console.log(response.status)
    const responseJson = await response.json()
    console.log(responseJson)
    if(responseJson.message === 'Success'){
      thunkAPI.dispatch(
        openMessage({
          message: 'Success Insert User!',
          type: 'success',
          open: true,
        }),
      )
      thunkAPI.dispatch(getUserPage(param))
      return responseJson
    }else{
      thunkAPI.dispatch(
        openMessage({
          message: responseJson.message,
          type: 'danger',
          open: true,
        }),
      )
    }
  }
  if (response.status === 400) {
    thunkAPI.dispatch(openMessage({ message: 'Insert User Failed', type: 'danger', open: true }))
  }
})

export const editUser = createAsyncThunk('api/auth/update', async (param, thunkAPI) => {
  // console.log('-------------------paramsx:',param);
  const requestOptions = {
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
    body: JSON.stringify(param.data),
  }
  let response = await fetch(`${API_ACCOUNT}/api/auth/${param.id}`, requestOptions)
  if (response.status === 200) {
    // thunkAPI.dispatch(
    //   openMessage({ message: 'Edit Site Successfull', type: 'success', open: true })
    // );
    thunkAPI.dispatch(getUserPage(param))
  }
  // .then(getApi())
})

export const deleteUser = createAsyncThunk('api/auth/delete', async (param, thunkAPI) => {
  // console.log(param);
  const requestOptions = {
    method: 'Delete',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
  }
  let response = await fetch(`${API_ACCOUNT}/api/auth/${param.id}`, requestOptions)
  if (response.status === 200) {
    // console.log(response.status)
    const responseJson = await response.json()

    thunkAPI.dispatch(
      openMessage({
        message: 'Success Delete User!',
        type: 'success',
        open: true,
      }),
    )
    thunkAPI.dispatch(getUserPage(param))
    return responseJson
  }
  if (response.status === 400) {
    thunkAPI.dispatch(openMessage({ message: 'Delete User Failed', type: 'danger', open: true }))
  }
  // .then(getApi())
})
