import React, { useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardHeader, Col, Table } from 'reactstrap'
import Wrapper from '../../components/Content/Wrapper'
import { getBusShiftDashboard } from '../../redux/action/dashboardAction'

const BusShift = ({param}) => {
    const { dataBusShift, isLoadingBusShift } = useSelector((state)=> state.dashboard)
    const [provider, setProvider] = useState([])
    const [value, setValue] = useState([])
    const [date, setDate] = useState()
    const {t} = useTranslation()
    const dispatch = useDispatch()
    useEffect(()=>{
        if(date){
            param.date = date
            dispatch(getBusShiftDashboard(param))
        }
    },[date])
    useEffect(()=>{
        let now = new Date();
        let month = (now.getMonth() + 1);               
        let day = now.getDate();
        if (month < 10) 
            month = "0" + month;
        if (day < 10) 
            day = "0" + day;
        let today = now.getFullYear() + '-' + month + '-' + day;
        setDate(today)
    },[])

    const _generateData = (data) => {
        const dataProvider = data[0].providerList
        const tempData = []
        for (let x = 0; x < dataProvider.length; x++) {
            const dataValue = []
            for (let y = 0; y < data.length; y++) {
                const jumlahBus = data[y].providerList[x].jumlahBus
                dataValue.push(jumlahBus)
            }
            tempData.push({
                name: dataProvider[x].providerName,
                data: dataValue
            })
        }
        console.log(tempData)
        setValue(tempData)
    }

    useEffect(()=>{
        if(dataBusShift.length > 0){
            _generateData(dataBusShift)
        }
        // if(dataBusShift.length > 0){
        //     let arr = []
        //     dataBusShift[0].providerList.map((key, index)=>{
        //         arr.push({name: key.providerName})
        //     })
        //     let totalArr = dataBusShift.length
        //     for(let i=0 ; i < totalArr; i++){
        //         dataBusShift[0].map((key,index)=>{
        //             arr.map((name, index)=>{
        //                 if(key.providerName === name.name){

        //                 }
        //             })
        //         })
        //     }
        //     setProvider(arr)
        // }
    },[dataBusShift])

    return (
        <Card style={{margin: 0}}>
            <CardHeader style={{padding: '20px 17px'}}>
                <div className="row">
                <Col xs={6} md={6} lg={6} sm={6} style={{alignSelf: 'center'}}>
                    <span style={{fontWeight:700, fontSize: 15}}>{t('Bus Count per Shift')}</span>
                </Col>
                <Col xs={6} md={6} lg={6} sm={6}>
                    <input type="date" onChange={(e)=>setDate(e.target.value)} className="form-control" value={date} />
                </Col>
                </div>
            </CardHeader>
            <CardBody>
                <Wrapper style={{height: 200}}>
                    {
                        isLoadingBusShift ? (
                            <div>
                                <div className="spinner-grow text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                <div className="spinner-grow text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                <div className="spinner-grow text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : (

                            <Table striped className='table-responsive'>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    {dataBusShift.map((key, index)=>(
                                        <th>{key.shiftName}</th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {value.map((key, index)=>(
                                    <tr>
                                        <th>{key.name}</th>
                                        {key.data.map((object, indeks)=>(
                                            <td>{object}</td>
                                        ))}
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        )
                    }
                </Wrapper>
            </CardBody>
        </Card>
        
    )
}

export default BusShift