import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import { deleteCluster, findCluster, getClusterList, postCluster } from "../action/clusterAction";
// import axios from "axios";
// import cartItems from "../api";

const initialState = {
  message: '',
  type: '',
  isOpenMessage: false,
}
const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    openMessage: (state, action) => {
      state.isOpenMessage = action.payload.open
      state.message = action.payload.message
      state.type = action.payload.type
    },
  },
})

export const { openMessage } = messageSlice.actions
export default messageSlice.reducer
