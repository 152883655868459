import React from 'react'
import Breadcrumbs from "../components/Common/Breadcrumb";

const UnderConstruction = () => {
  return (
    <React.Fragment>
        <div className="page-content">
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Page" breadcrumbItem="Under Construction" />
        </div>
    </React.Fragment>
  )
}

export default UnderConstruction