import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ACCOUNT } from "../../constants";
import { openMessage } from "./messageSlice";

export const login = createAsyncThunk('auth/login', async (param, thunkAPI) => {
  // console.log(login)
  const response = await fetch(`${API_ACCOUNT}/auth/login`, {
    method: 'POST',
    headers: {
      Accept: '*/*',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Authorization': 'Basic Z2F0ZXdheS1zZXJ2aWNlOkxlb25pMjAyM0V0bXM='
    },
    body: new URLSearchParams({
      username: param.username,
      password: param.password,
      grant_type: 'password',
    }),
  })
  if (response.status === 200) {
    console.log(response.status)
    const responseJson = await response.json()
    // thunkAPI.dispatch(getCardStat());
    // console.log(responseJson)

    thunkAPI.dispatch(
      openMessage({
        message: 'Success Login!',
        type: 'success',
        open: true,
      }),
    )
    return responseJson
  } else {
    thunkAPI.dispatch(
      openMessage({
        message: 'Invalid Username Or Password',
        type: 'failed',
        open: true,
      }),
    )
    return null
  }
})
const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? {
      startApp: false,
      isLoggedIn: true,
      user: user,
    }
  : {
      startApp: true,
      isLoggedIn: false,
      user: null,
      // user: [],
    };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = []
      state.isLoggedIn = false
      state.startApp = true
      // const navigate = useNavigate()
      localStorage.clear()
      if (localStorage.getItem('user') === null) {
        // navigate('#/login')
        // console.log('test bung')
        window.location.href = '/login'
      }
    },
  }, 
  extraReducers: {
    // [register.fulfilled]: (state, action) => {
    //   state.isLoggedIn = false;
    // },
    // [register.rejected]: (state, action) => {
    //   state.isLoggedIn = false;
    // },
    [login.fulfilled]: (state, action) => {
      if (action.payload === null) {
        state.isLoggedIn = false
        state.startApp = false
      } else {
        state.isLoggedIn = true
        state.startApp = false
        state.user = action.payload
        localStorage.setItem('user', JSON.stringify(action.payload))
      }
      // let wrapping = [action.payload].flat();

      // console.log(action.payload);
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false
      state.user = null
    },
  }
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
