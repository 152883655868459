import { createSlice } from "@reduxjs/toolkit";
import { getPieChartDashboard, getMapStationDashboard, getBarChartDashboard, getBarDashboard, getBusShiftDashboard, getEmployeeShiftDashboard } from "../action/dashboardAction";

const initialState = {
    dataMap: [],
    dataPieChart: [],
    dataBarChart: [],
    dataBar: '',
    dataBusShift: [],
    dataEmployeeShift: [],
    isLoadingMap: false,
    isLoadingBusShift: false,
    isLoadingEmployeeShift: false
};
const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
    },
    extraReducers: {
        [getMapStationDashboard.pending]: (state) => {
            state.isLoadingMap   = true
        },
        [getMapStationDashboard.fulfilled]: (state, action) => {
        // console.log(action.payload);
            state.dataMap = action.payload.data
            state.isLoadingMap   = false
        },
        [getMapStationDashboard.rejected]: (state) => {
            state.isLoadingMap   = false
        },
        [getPieChartDashboard.pending]: (state) => {
            state.isLoadingMap   = true
        },
        [getPieChartDashboard.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataPieChart = action.payload.data
        state.isLoadingMap   = false
        },
        [getPieChartDashboard.rejected]: (state) => {
        state.isLoadingMap   = false
        },
        [getBarChartDashboard.pending]: (state) => {
            state.isLoadingMap   = true
        },
        [getBarChartDashboard.fulfilled]: (state, action) => {
        // console.log(action.payload);
            state.dataBarChart = action.payload.data
            state.isLoadingMap   = false
        },
        [getBarChartDashboard.rejected]: (state) => {
        state.isLoadingMap   = false
        },
        [getBarDashboard.pending]: (state) => {
            state.isLoadingMap   = true
        },
        [getBarDashboard.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataBar = action.payload.data
        state.isLoadingMap   = false
        },
        [getBarDashboard.rejected]: (state) => {
        state.isLoadingMap   = false
        },
        [getBusShiftDashboard.pending]: (state) => {
            state.isLoadingBusShift   = true
        },
        [getBusShiftDashboard.fulfilled]: (state, action) => {
        // console.log(action.payload);
            state.dataBusShift = action.payload.data
            state.isLoadingBusShift   = false
        },
        [getBusShiftDashboard.rejected]: (state) => {
            state.isLoadingBusShift   = false
        },
        [getEmployeeShiftDashboard.pending]: (state) => {
            state.isLoadingBusShift   = true
        },
        [getEmployeeShiftDashboard.fulfilled]: (state, action) => {
        // console.log(action.payload);
            state.dataEmployeeShift = action.payload.data
            state.isLoadingBusShift   = false
        },
        [getEmployeeShiftDashboard.rejected]: (state) => {
            state.isLoadingBusShift   = false
        },
    }
});

export const {  } = dashboardSlice.actions;

export default dashboardSlice.reducer;