import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import DatatableComponent from '../../components/Content/Datatable'
import Datatable from '../../components/Content/Datatable'
import ModalComponent from '../../components/Content/Modal'
import { getShiftPage } from '../../redux/action/master/shiftAction'
import { openFormShift } from '../../redux/slice/master/shiftSlice'
import FormShift from './util/Shift/FormShift'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useTranslation } from 'react-i18next'
import FilterSelect from '../../components/Content/FilterSelect'

const Shift = () => {
  const {t} = useTranslation()
  const { isOpenShift, dataShift, isLoadingShift } = useSelector((state)=>state.shift)
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState('')
  const [select, setSelect] = useState('')
  const [filter, setFilter] = useState([{name: '', category: ''}])
  const countPerPage = 10
  const onChange = (e, field) => {
    let arr = [...filter]
    if (field === 'name') {
      arr[0].name = e.target.value
    }
    if (field === 'category') {
      arr[0].category = e
    }
    setFilter(arr)
  }

  const columns = [
    {
      name: (
        <div className='row' style={{width: '100%', marginBottom: '13px'}}>
          <Col lg={12} md={12} sm={12} xs={12} className='mb-2'>
            {t("Name")}
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <input type='text' onChange={(e)=> onChange(e, 'name')} className='form-control' placeholder={'Search By '+ t("Name")} />
          </Col>
        </div>
      ),
      selector: (row) => row.name,
    },
    {
      name: (
        <div className='row' style={{width: '100%', marginBottom: '13px'}}>
          <Col lg={12} md={12} sm={12} xs={12} className='mb-2'>
            {t("Category")}
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <FilterSelect 
              options={[{value: 'Direct Employee', label: 'Direct Employee'}, {value: 'Undirect Employee', label: 'Undirect Employee'}]} 
              onChange={(e)=>onChange(e,'category')} 
            />
          </Col>
        </div>
      ),
      selector: (row) => row.category,
    },
    {
        name: <>{t("Description")}</>,
        selector: (row) => row.descr,
    },
    {
      name: <>{t("Action")}</>,
      cell: (row) => (
        <div style={{ padding: '10px 0' }}>
          <button 
            className='btn btn-outline-secondary btn-sm' 
            onClick={()=>{
              dispatch(openFormShift({open: !isOpenShift, type: 'edit',data: row, id: row.id}))
            }} 
            style={{marginRight: 5}}>
              {t("Edit")}
            </button> 
            <button 
            className='btn btn-danger btn-sm' 
            onClick={()=>{
              dispatch(openFormShift({open: !isOpenShift, type: 'delete',data: row, id: row.id}))
            }} 
            style={{marginRight: 5}}>
              {t("Delete")}
            </button>
        </div>
      ),
    },
  ]
  const { user } = useSelector((state) => state.auth)
  const param = {
    token: user.access_token,
    sort: sort,
    size: countPerPage,
    select: select,
    filter: filter,
    page: page,
  }

  useEffect(()=>{
    dispatch(getShiftPage(param))
  },[page, filter, select, sort])
  return (
    <React.Fragment>
        <div className="page-content">
            {/* Render Breadcrumb */}
            <Breadcrumbs title={t("Master Data")} breadcrumbItem={t("Shift")} />
            {/* Content */}
            <Row>
                <div className="col-6 mb-3">
                    <button className="btn btn-primary waves-effect waves-light" style={{marginRight: 5}}
                        onClick={()=>{dispatch(openFormShift({open: !isOpenShift, type: 'add'}))}}
                        data-toggle="modal"
                        data-target=".bs-example-modal-center">
                          + {t("Add Data")}
                    </button>
                </div>
                <div className="col-6 mb-3" style={{textAlign: 'right'}}>
                {isLoadingShift ?
                (
                  <div>
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <button className="btn btn-primary waves-effect waves-light"
                      onClick={()=>setFilter([{name: '', category: ''}])}>
                        <i className="mdi mdi-refresh"></i> Reset Filter
                  </button>
                )}
                </div>
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <DatatableComponent  
                                data={dataShift.content}
                                page={page}
                                countPerPage={countPerPage}
                                columns={columns}
                                setPage={setPage}
                                totalElements={dataShift.totalElements}
                                columnFilter
                                columnSorter
                                pagination
                                tableProps={{
                                    hover: true,
                                }} 
                            />
                        </div>
                    </div>
                </div>
            </Row>
        </div>
        <FormShift isOpen={isOpenShift} onClose={()=> dispatch(openFormShift({open: !isOpenShift, type: ''}))} param={param} />
    </React.Fragment>

  )
}

export default Shift