import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ModalComponent from '../../../../components/Content/Modal'
import { openFormShift } from '../../../../redux/slice/master/shiftSlice'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Col, FormFeedback, FormGroup, Input, Label } from 'reactstrap'
import { useDispatch } from 'react-redux'
import Alert from '../../../../components/Content/Alert'
import { deleteShift, editShift, postShift } from '../../../../redux/action/master/shiftAction'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'

const FormShift = ({param}) => {
    const { isOpenShift, idShift, dataShiftById, stateFormShift, isLoadingShift } = useSelector((state)=>state.shift)
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const [selected, setSelected] = useState()
    const handleFormSubmit = (values) =>{
        // console.log(values)
        param.data = values
        if(stateFormShift === 'add'){
            dispatch(postShift(param))
        }else if(stateFormShift === 'edit'){
            param.id = idShift
            dispatch(editShift(param))
        }else{
            param.id = idShift
            dispatch(deleteShift(param))
        }
    }
    const initialValues = {
        name: idShift ==='' ? '': dataShiftById.name,
        descr: idShift ==='' ? '': dataShiftById.descr,
        category: idShift ==='' ? '': dataShiftById.category
    }
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required!'),
        category: Yup.string().required('Category is required!'),
        descr: Yup.string().required('Description is required!'),
    })
    useEffect(() => {
        if(stateFormShift === 'edit'){
            setSelected(dataShiftById.category === 'Indirect Employee' ? 
            {label: 'Indirect Employee', value: 'Indirect Employee'} :
            {label: 'Direct Employee', value: 'Direct Employee'})
        }
    },[isOpenShift])
    return stateFormShift === 'delete' ? (
        <Alert 
            isOpen={isOpenShift} 
            text={t("Are You Sure for Delete This Data ?")}
            action={handleFormSubmit}
            toggle={()=>{dispatch(openFormShift({open: !isOpenShift, type: ''}))}} 
            close={()=>{dispatch(openFormShift({open: !isOpenShift, type: ''}))}} />
    ) : (
        <ModalComponent 
            isOpen={isOpenShift} 
            close={()=>{dispatch(openFormShift({open: !isOpenShift, type: ''}))}}
            title={"Form Data "+t("Shift")}
            idForm="shift-form"
            toggle={()=>{dispatch(openFormShift({open: true, type: 'add'}))}}
            isLoading={isLoadingShift}
        >
            <Formik 
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit} id="shift-form">
                        <div className='row'>
                            <Col xs={12} sm={12} lg={12} md={12}>
                                <FormGroup className='mb-3'>
                                    <Label>{t('Name')}</Label>
                                    <Input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.name && !!errors.name}
                                    />
                                    <FormFeedback>{errors.name}</FormFeedback>
                                </FormGroup>
                                <FormGroup className='mb-3'>
                                    <Label>{t('Description')}</Label>
                                    <Input
                                        type="text"
                                        id="descr"
                                        name="descr"
                                        value={values.descr}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.descr && !!errors.descr}
                                    />
                                    <FormFeedback>{errors.descr}</FormFeedback>
                                </FormGroup>
                                <FormGroup className='mb-3'>
                                    <Label>{t('Category')}</Label>
                                    <Select
                                        styles={{
                                            control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor:
                                                touched.category && errors.category
                                                ? 'red' : 'rgba(0,0,0,.24)',
                                            width: '100%',
                                            appearance: 'auto',
                                            }),
                                        }}
                                        onChange={(e)=>{
                                            handleChange({
                                                target: { name: 'category', value: e.value },
                                            })
                                            setSelected(e)
                                            // console.log(labelRuas)
                                            // console.log(e.value)
                                            // handleChange(e)
                                        }}
                                        name="category"
                                        id="category"
                                        value={selected}
                                        options={[{label: 'Direct Employee', value: 'Direct Employee'}, {label: 'Indirect Employee', value: 'Indirect Employee'}]}
                                    />
                                </FormGroup>   
                            </Col>
                        </div>
                    </form>
                )}
            </Formik>
        </ModalComponent>
    )
}

export default FormShift