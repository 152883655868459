import { createSlice } from "@reduxjs/toolkit";
import { deleteArea, editArea, getAreaById, getAreaByRegionalId, getAreaList, getAreaPage, postArea } from "../../action/master/areaAction";

const initialState = {
    dataArea: [],
    isOpenArea: false,
    stateFormArea: '',
    dataAreaById: '',
    idArea: '',
    isLoadingArea: false,
};
const areaSlice = createSlice({
    name: "area",
    initialState,
    reducers: {
        openFormArea: (state, action) => {
            if(action.payload.open){
                if(action.payload.type !== 'add'){
                    state.dataAreaById = action.payload.data
                    state.idArea = action.payload.id
                }
            }else{
                state.dataAreaById = ''
                state.idArea = ''
            }
            state.stateFormArea = action.payload.type
            state.isOpenArea = action.payload.open;
        },
    },
    extraReducers: {
      [getAreaPage.pending]: (state) => {
        state.isLoadingArea = true
      },
      [getAreaPage.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataArea = action.payload.data
        state.isLoadingArea = false
      },
      [getAreaPage.rejected]: (state) => {
        state.isLoadingArea = false
      },
      [getAreaList.pending]: (state) => {
        state.isLoadingArea = true
      },
      [getAreaList.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataArea = action.payload.data
        state.isLoadingArea = false
      },
      [getAreaList.rejected]: (state) => {
        state.isLoadingArea = false
      },
      [getAreaById.pending]: (state) => {
        state.isLoadingArea = true
      },
      [getAreaById.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataAreaById = action.payload.data
        state.isLoadingArea = false
      },
      [getAreaById.rejected]: (state) => {
        state.isLoadingArea = false
      },
      [getAreaByRegionalId.pending]: (state) => {
        state.isLoadingArea = true
      },
      [getAreaByRegionalId.fulfilled]: (state, action) => {
        // console.log(action.payload)
        state.dataAreaByRegionalId = action.payload.data
        state.isLoadingArea = false
      },
      [getAreaByRegionalId.rejected]: (state) => {
        state.isLoadingArea = false
      },
      [postArea.pending]: (state) => {
        state.isLoadingArea = true
      },
      [postArea.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.isOpenArea = false
        state.isLoadingArea = false
        state.stateFormArea = ''
      },
      [postArea.rejected]: (state) => {
        state.isLoadingArea = false
      },
      [editArea.pending]: (state) => {
        state.isLoadingArea = true
      },
      [editArea.fulfilled]: (state, action) => {
        state.isOpenArea = false
        state.isLoadingArea = false
        state.dataByIdArea = []
        state.idArea = 0
        state.stateFormArea = ''
      },
      [editArea.rejected]: (state) => {
        state.isLoadingArea = false
      },
      [deleteArea.pending]: (state) => {
        state.isLoadingArea = true
      },
      [deleteArea.fulfilled]: (state, action) => {
        state.isOpenArea = false
        state.isLoadingArea = false
        state.dataByIdArea = []
        state.idArea = 0
        state.stateFormArea = ''
      },
      [deleteArea.rejected]: (state) => {
        state.isLoadingArea = false
      },
    }
});

export const {  openFormArea } = areaSlice.actions;

export default areaSlice.reducer;