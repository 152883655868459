import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { CardHeader, Col, FormGroup, Label, Row } from 'reactstrap'
import DatatableComponent from '../components/Content/Datatable'
import Breadcrumbs from "../components/Common/Breadcrumb";
import { getBuslog, getListDetailEmployee } from '../redux/action/busLogAction';
import Select from "react-select";
import { getRouteListByIdBusProvider } from '../redux/action/master/routeAction'
import { getBusByProviderId } from '../redux/action/master/busAction'
import { getBusProviderList } from '../redux/action/master/busproviderAction'
import { useTranslation } from 'react-i18next'
import DetailBuslog from './DetailBuslog'
import { closeDetail } from '../redux/slice/busLogSlice'

const Buslog = () => {
  const {t} = useTranslation()
  const { dataBuslog, isLoadingBuslog, dataDetailBuslog, isLoadingDetailBusLog } = useSelector((state)=>state.busLog)
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState('')
  const [filter, setFilter] = useState('')
  const countPerPage = 10
  const { dataBusProvider } = useSelector((state)=>state.busProvider)

  const [dataSelect, setSelect] = useState([{busProvider: {label: '', value: ''}}])
  const [selected, setSelected] = useState([{busProvider: {label: '', value: ''}}])
  const [dataBuslogById, setBusLogById] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const openDetail = (data) => {
    if(!isOpen){
      dispatch(getListDetailEmployee({token: user.access_token, id: data}))
    }else{
      dispatch(closeDetail())
    }
    setIsOpen(!isOpen)
  }
  const columns = [
    {
        name: <>{t("Date")}</>,
        selector: (row) => row.arrivalTime.split('T')[0],
    },
    {
      name: <>{t("Time")}</>,
      selector: (row) => row.arrivalTime.split('T')[1],
    },
    {
        name: <>{t("Occupancy")}</>,
        selector: (row) => row.occupancy,
    },
    {
        name: <>{t("Distance")}</>,
        selector: (row) => row.distance+' km',
    },
    {
        name: <>{t("Provider")}</>,
        selector: (row) => row.nameProvider,
    }, 
    {
        name: <>{t("Action")}</>,
        cell: (row) => (
          <button 
            className='btn btn-primary btn-sm'  
            style={{marginRight: 5}}
            onClick={()=>openDetail(row.id, row.numPlat)}>
              {t("See Employee")}
            </button>
        ),
    }, 
  ]
  const { user } = useSelector((state) => state.auth)
  const param = {
    token: user.access_token,
    sort: sort,
    size: countPerPage,
    select: selected,
    filter: filter,
    page: page,
  }
  useEffect(()=>{
    dispatch(getBuslog(param))
  },[page, filter, selected, sort])

  useEffect(() => {
    let arr = [...dataSelect]
    let selectedArr = [...selected]
    let newArr = [{value: '', label: 'All'}]
    if (dataBusProvider.length > 0) {
      dataBusProvider.map(({ id, name }) => (
        newArr.push({ value: id, label: name })
      ))
      // console.log(newArr)
      arr[0].busProvider = newArr
    }

    selectedArr[0].busProvider = arr[0].busProvider[0]
    setSelect(arr)
    setSelected(selectedArr)
  },[dataBusProvider])
  useEffect(()=>{
    dispatch(getBusProviderList(param))
  },[])
  return (
    <React.Fragment>
        <div className="page-content">
            {/* Render Breadcrumb */}
            <Breadcrumbs title={t("Bus Log")} breadcrumbItem={t("Bus Log")} />
            {/* Content */}
            <Row>
                {isLoadingBuslog ?
                (
                <div className="col-6 mb-3" style={{textAlign: 'right'}}>
                  <div>
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
                ) : null}
                <div className="col-12">
                    <div className="card">

                        <CardHeader style={{padding: '20px 17px'}}>
                          <div className="row">
                            <Col lg={12} md={12} sm={12} xs={12}>
                              <FormGroup className='mb-3'>
                                  <Label>{t("Bus Provider")}</Label> 
                                  <Select
                                      styles={{
                                          control: (baseStyles, state) => ({
                                          ...baseStyles,
                                          width: '100%',
                                          appearance: 'auto',
                                          }),
                                      }}
                                      onChange={(e)=>{
                                          let arr = [...selected]
                                          arr[0].busProvider = e
                                          setSelected(arr)
                                          param.idBusProvider = e.value
                                          // console.log(param)
                                          dispatch(getBusByProviderId(param))
                                          // console.log(labelRuas)
                                          // console.log(e.value)
                                          // handleChange(e)
                                      }}
                                      name="busProviderId"
                                      id="busProviderId"
                                      value={selected[0].busProvider}
                                      options={dataSelect[0].busProvider}
                                  />
                              </FormGroup>
                            </Col>
                          </div>
                        </CardHeader>
                        <div className="card-body">

                          {/* {isLoadingBuslog ?
                           (
                            <>
                              <div style={{textAlign: 'center'}}>
                                <div class="spinner-grow text-primary" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-primary" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-primary" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>
                              <div style={{textAlign: 'center'}}>
                                <p style={{fontSize: '12px'}}>Loading...</p>
                              </div>
                            </>
                            ) : 
                            ( */}
                            <DatatableComponent  
                                data={dataBuslog.content}
                                page={page}
                                countPerPage={countPerPage}
                                columns={columns}
                                totalElements={dataBuslog.totalElements}
                                setPage={setPage}
                                columnFilter
                                columnSorter
                                pagination
                                tableProps={{
                                    hover: true,
                                }} 
                            />
                            {/* // )} */}
                        </div>
                    </div>
                </div>
            </Row>
        </div>
        <DetailBuslog isOpen={isOpen} data={dataDetailBuslog} close={()=>openDetail()} />
    </React.Fragment>

  )
}

export default Buslog