import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    error: "",
    success: "",
};
const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducer: {
        
    }
});

export default profileSlice.reducer;