import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Button, Col, Row } from 'reactstrap'
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DatatableComponent from '../../../components/Content/Datatable'
import { openFormEmployee } from '../../../redux/slice/master/employeeSlice'
import { getEmployeePage } from '../../../redux/action/master/employeeAction'
import FormEnrollment from './FormEnrollment';
import UploadData from './UploadData';
import { useTranslation } from 'react-i18next';
import FilterSelect from '../../../components/Content/FilterSelect';

const Employee = () => {
  const {t} = useTranslation()
  const { isOpenEmployee, dataEmployee, isLoadingEmployee } = useSelector((state)=>state.employee)
  const { dataRoute } = useSelector((state)=>state.route)
  const { dataArea } = useSelector((state)=>state.area)
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState('')
  const [dataSelect, setSelect] = useState([{area: [], route: []}])
  const countPerPage = 10
  const [isOpenUpload, setOpenUpload] = useState(false)
  const [filter, setFilter] = useState([{matricule: '', area: '', route: '', name: '', station: ''}])
  const openHandler = () => {
      setOpenUpload(!isOpenUpload)
  }
  const onChange = (e, field) => {
    let arr = [...filter]
    if (field === 'matricule') {
      arr[0].matricule = e.target.value
    }
    if (field === 'area') {
      arr[0].area = e
    }
    if (field === 'route') {
      arr[0].route = e
    }
    if (field === 'name') {
      arr[0].name = e.target.value
    }
    if (field === 'station') {
      arr[0].name = e.target.value
    }
    setFilter(arr)
  }

  const columns = [

    {
      name: (
        <div className='row' style={{width: '100%', marginBottom: '13px'}}>
          <Col lg={12} md={12} sm={12} xs={12} className='mb-2'>
            {t("Matricule")}
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <input type='text' onChange={(e)=> onChange(e, 'matricule')} className='form-control' />
          </Col>
        </div>
      ),
      selector: (row) => row.nik,
      width: '170px',
    },
    {
      name: (
        <div className='row' style={{width: '100%', marginBottom: '13px'}}>
          <Col lg={12} md={12} sm={12} xs={12} className='mb-2'>
            {t("Name")}
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <input type='text' onChange={(e)=> onChange(e, 'name')} className='form-control'/>
          </Col>
        </div>
      ),
      selector: (row) => row.firstname +" "+ row.lastname,
      width: '150px',
    },
    {
        name: 'RF ID CARD',
        selector: (row) => row.idRFCard,
        width: '170px',
    },
    {
      name: (
        <div className='row' style={{width: '100%', marginBottom: '13px'}}>
          <Col lg={12} md={12} sm={12} xs={12} className='mb-2'>
            {t("Area")}
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <FilterSelect 
              options={dataSelect[0].area.length > 0 ? dataSelect[0].area : []} 
              onChange={(e)=>onChange(e,'area')} 
            />
          </Col>
        </div>
      ),
      selector: (row) => row.areaName,
      width: '170px',
    },
    {
      name: (
        <div className='row' style={{width: '100%', marginBottom: '13px'}}>
          <Col lg={12} md={12} sm={12} xs={12} className='mb-2'>
            {t("Route")}
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <FilterSelect 
              options={dataSelect[0].route.length > 0 ? dataSelect[0].route : []} 
              onChange={(e)=>onChange(e,'route')} 
            />
          </Col>
        </div>
      ),
      selector: (row) => row.routeName,
      width: '290px',
    },
    {
      name: (
        <div className='row' style={{width: '100%', marginBottom: '13px'}}>
          <Col lg={12} md={12} sm={12} xs={12} className='mb-2'>
            {t("Station")}
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <input type='text' onChange={(e)=> onChange(e, 'station')} className='form-control'/>
          </Col>
        </div>
      ),
      selector: (row) => row.stationName,
      width: '250px',
    },
    {
        name: <>{t("Action")}</>,
        cell: (row) => (
            <div style={{ padding: '10px 0' }}>
            <button 
                className='btn btn-outline-secondary btn-sm' 
                onClick={()=>{
                dispatch(openFormEmployee({open: !isOpenEmployee, type: 'edit',data: row, id: row.id}))
                }} 
                style={{marginRight: 5}}>
                {t("Edit")}
                </button> 
                <button 
                className='btn btn-danger btn-sm' 
                onClick={()=>{
                dispatch(openFormEmployee({open: !isOpenEmployee, type: 'delete',data: row, id: row.id}))
                }} 
                style={{marginRight: 5}}>
                {t("Delete")}
                </button>
            </div>
        ),
    },
  ]
  const { user } = useSelector((state) => state.auth)
  const param = {
    token: user.access_token,
    sort: sort,
    size: countPerPage,
    filter: filter,
    page: page,
  }
  useEffect(() => {
    let arr = [...dataSelect]
    if (dataRoute.length > 0) {
        let newArr = dataRoute.map(({ id, name }) => ({ value: id, label: name }))
        // console.log(newArr)
        arr[0].route = newArr
    }
    if (dataArea.length > 0) {
      let newArr = dataArea.map(({ id, name }) => ({ value: id, label: name }))
      // console.log(newArr)
      arr[0].area = newArr
    }
      // let value = newArr.find(({ value }) => value === idBusProvider);
      // console.log(value)
      // selectedArr[0].busProvider = valuebusProvider
      setSelect(arr)
  },[dataArea, dataRoute])

  useEffect(()=>{
    dispatch(getEmployeePage(param))
  },[page, filter, sort])
  return (
    <React.Fragment>
        <div className="page-content">
            {/* Render Breadcrumb */}
            <Breadcrumbs title={t("Master Data")} breadcrumbItem={t("Enrollment")} />
            {/* Content */}
            <Row>
                <div className="col-6 mb-3">
                    <button className="btn btn-primary waves-effect waves-light"
                        onClick={()=>{dispatch(openFormEmployee({open: !isOpenEmployee, type: 'add'}))}}
                        data-toggle="modal"
                        data-target=".bs-example-modal-center">
                          + {t("Add Data")}
                    </button>
                    <button className="btn btn-primary waves-effect waves-light" onClick={openHandler} style={{ marginLeft: '5px' }}>Upload Data</button>
                </div>
                <div className="col-6 mb-3" style={{textAlign: 'right'}}>
                {isLoadingEmployee ?
                (
                  <div>
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <button className="btn btn-primary waves-effect waves-light"
                      onClick={()=>setFilter([{matricule: '', area: '', route: '', name: ''}])}>
                        <i className="mdi mdi-refresh"></i> Reset Filter
                  </button>
                  )} 
                </div>
                <div className="col-12">
                  <UploadData open={isOpenUpload} param={param} isLoading={isLoadingEmployee} />
                </div>
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <DatatableComponent  
                                data={dataEmployee.content}
                                page={page}
                                countPerPage={countPerPage}
                                columns={columns}
                                totalElements={dataEmployee.totalElements}
                                setPage={setPage}
                                columnFilter
                                columnSorter
                                pagination
                                tableProps={{
                                    hover: true,
                                }} 
                            />
                        </div>
                    </div>
                </div>
            </Row>
        </div>
        <FormEnrollment isOpen={isOpenEmployee} onClose={()=> dispatch(openFormEmployee({open: !isOpenEmployee, type: ''}))} param={param} />
    </React.Fragment>

  )
}

export default Employee