// export const API_WFM = 'http://202.180.21.198:28080/wfm'
export const API_MASTER = 'https://dev-leoni.rastek.id/restapi/master'
// export const API_INVENTORY = 'http://202.180.21.198:28080/inventory'
export const API_ACCOUNT = 'https://dev-leoni.rastek.id/restapi/account'
export const API_EMPLOYEES = 'https://dev-leoni.rastek.id/restapi/employee'
export const API_BUS = 'https://dev-leoni.rastek.id/restapi/bus'
// export const API_CORRECTIVE = 'http://202.180.21.198:28080/corrective'
// export const API_PREVENTIVE = 'http://202.180.21.198:28080/preventive'
// export const API_PROACTIVE = 'http://202.180.21.198:28080/proactive'
// export const API_UTILITY = 'http://202.180.21.198:28080/utility'
// export const API_POTENSI_PENGUKURAN = 'http://202.180.21.198:28080/potensi-pengukuran'
