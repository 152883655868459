import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ModalComponent from '../../../components/Content/Modal'
import { openModalUser, openModalRole } from '../../../redux/slice/master/userSlice'
import { getRoleList } from '../../../redux/action/master/roleAction'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Col, FormFeedback, FormGroup, Input, Label, Row, Table } from 'reactstrap'
import { useDispatch } from 'react-redux'
import Alert from '../../../components/Content/Alert'
import { deleteUser, editUser, postUser } from '../../../redux/action/master/userAction'
import FormRoleList from './FormRoleList'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { getEmployeeById, getEmployeeList, getEmployeeSearch } from '../../../redux/action/master/employeeAction'
import Wrapper from '../../../components/Content/Wrapper'
import styled from 'styled-components'

const ListEmployee = styled.div`
list-style-type: none;
padding: 0;
text-align: left;
`
const ItemEmployee = styled.div`
border-bottom: 1px solid #ced4da;
padding: 10px;
font-size: 14px;
width: 100%;
&:hover {
    background: #e2eeff !important;
    cursor: pointer;
}
`
const FormUser = ({ param }) => {

    const { isOpenUser, idUser, dataUserById, stateFormUser, isLoadingUser } = useSelector((state) => state.user)
    const dispatch = useDispatch()

    const {t} = useTranslation()
    const handleFormSubmit = (values) => {

        values.roleId = selectedList

        if (stateFormUser === 'add') {

            param.data = values
            dispatch(postUser(param))

        } else if (stateFormUser === 'edit') {

            param.data = values
            param.id = idUser

            dispatch(editUser(param))

        } else {
            param.id = idUser
            dispatch(deleteUser(param))
        }
    }

    const initialValues = {
        username: idUser === '' ? '' : dataUserById.username,
        email: idUser === '' ? '' : dataUserById.email,
        password: idUser === '' ? '' : '',
        employeeId: idUser ==='' ? '': dataUserById.employeeId,
        isActive: idUser === '' ? false : dataUserById.isActive
    }

    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Username is required!'),
        email: Yup.string().required('Email is required!'),
        employeeId: Yup.string().required('Employee is required!'),
    })

    const [dataSelect, setSelect] = useState([])
    const [selectedList, setSelectedList] = useState([]);
    const [dataSelectEmployee, setSelectEmployee] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState();
    const [searchValue, setSearchValue] = useState();
    const [openList, setOpenList] = useState(false);
    const { dataRole } = useSelector((state) => state.role)
    const { dataEmployee, dataEmployeeById, isLoadingEmployee } = useSelector((state) => state.employee)
    const [listRole, setListRole] = useState([])

    useEffect(() => {

        // load data role
        dispatch(getRoleList(param))
        // dispatch(getEmployeeList(param))
    }, [])

    useEffect(() => {
        if(dataEmployee.length > 0){
            setOpenList(true)
        }else{
            setOpenList(false)
        }
    },[dataEmployee])

    useEffect(() => {

        let arr = [...dataSelect]

        // get data role set to an array
        if (dataRole.length > 0) {

            let newArr = dataRole.map(({ id, name }) => ({ value: id, label: name }))
            arr = newArr
        }

        // get data user by id on click
        if (Object.keys(dataUserById).length > 0) {

            let newArr = []
            let arrRoleUser = []
            let i = 0

            for (var key in dataUserById.roleId) {

                newArr.push(dataUserById.roleId[key])

                const matchingElements = dataRole.filter(item => item.id === dataUserById.roleId[key]);
                if (matchingElements.length > 0) {

                    arrRoleUser.push({ id: matchingElements[0].id, name: matchingElements[0].name })

                } else {
                    // console.log("Data tidak ditemukan dalam array.");
                }
            }

            // get and set data only id role selected
            setSelectedList(newArr)

            // get and set data role selected
            setListRole(arrRoleUser)
        }

        // set array data all role to dataSelect
        setSelect(arr)

    }, [dataRole, dataUserById])

    //search employee
    const searchEmployee = (value) => {
        setSearchValue(value)
        if(value.length >= 3){
            param.name = value
            dispatch(getEmployeeSearch(param))
            setOpenList(true)
        }else{
            setOpenList(false)
        }
    }

    // do DualListBox on change selected
    const changeList = (selected) => {

        let newArr = []

        for (let i = 0; i < selected.length; i++) {

            if (stateFormUser === 'edit') {

                let matchingRoleUser = dataRole.filter(item => item.id === selected[i]);

                if (matchingRoleUser.length > 0) {
                    newArr.push({ id: matchingRoleUser[0].id, name: matchingRoleUser[0].name })
                } else if (matchingRoleUser.length === 0) {
                    newArr.push({ id: '', name: '' })
                }

            } else {

                let matchingRoleUser = dataRole.filter(item => item.id === selected[i]);
                if (matchingRoleUser.length > 0) {
                    newArr.push({ id: '', name: '' })
                }
            }
        }

        // get and set data role selected
        setListRole(newArr)
    }
    useEffect(() => {
        if(stateFormUser === 'edit'){
            param.id = dataUserById.employeeId
            dispatch(getEmployeeById(param))
        }else{
            setSearchValue('')
            setSelectedList([])
            // setListRole([])
        }
    },[isOpenUser])
    useEffect(()=>{
        let arr = ''
        if (dataEmployeeById.length !== '') {
            if(stateFormUser === 'edit'){
                setSearchValue(dataEmployeeById.firstname+' '+dataEmployeeById.lastname)
            }
        }
        setSelectedEmployee(arr)
    },[dataEmployeeById])

    return stateFormUser === 'delete' ? (
        <Alert
            isOpen={isOpenUser}
            text={t("Are You Sure for Delete This Data ?")} 
            action={handleFormSubmit}
            toggle={() => { dispatch(openModalUser({ open: true, type: 'add' })) }}
            close={() => { dispatch(openModalUser({ open: !isOpenUser, type: '' })) }} />
    ) : (
            <>
                <ModalComponent
                    isOpen={isOpenUser}
                    close={() => { dispatch(openModalUser({ open: !isOpenUser, type: '' })) }}
                    title={"Form Data "+t("User")}
                    idForm="user-form"
                    toggle={() => { dispatch(openModalUser({ open: true, type: 'add' })) }}
                    size="lg"
                    isLoading={isLoadingUser}
                >
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                            <form onSubmit={handleSubmit} id="user-form">
                                <Row>
                                    <Col md={6}>
                                        <FormGroup className="mb-3" check>
                                            <Label check>
                                                <Input
                                                    type="checkbox"
                                                    name="isActive"
                                                    value={values.isActive}
                                                    onChange={handleChange}
                                                    checked={values.isActive}
                                                />
                                                {t("Is Active")}
                                            </Label>
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <Label>{t("Username")}</Label>
                                            <Input
                                                type="text"
                                                name="username"
                                                value={values.username}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                invalid={touched.username && !!errors.username}
                                            />
                                            <FormFeedback>{errors.username}</FormFeedback>
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <Label>{t('Email')}</Label>
                                            <Input
                                                type="text"
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                invalid={touched.email && !!errors.email}
                                            />
                                            <FormFeedback>{errors.email}</FormFeedback>
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <Label>{t("Employee")} ({t("Minimum 3 Character for Search")})</Label>
                                            <Input
                                                type="text"
                                                name="employeeId"
                                                value={searchValue}
                                                onChange={(e)=>searchEmployee(e.target.value)}
                                                onBlur={handleBlur}
                                                invalid={touched.employeeId && !!errors.employeeId}
                                                autocomplete="off"
                                            />
                                                {/* <div style={{border: '1px solid #ced4da', height: '100px', borderRadius: '5px'}}> */}
                                                    {openList ?(
                                                        <Wrapper style={{
                                                            height: '100px', 
                                                            overflowX: 'hidden', 
                                                            marginTop: 5,
                                                            border: '1px solid #ced4da', 
                                                            borderRadius: '5px', 
                                                            padding: '10px'
                                                        }}>
                                                            <ListEmployee>
                                                                {dataEmployee.map((key,index)=>(
                                                                    <ItemEmployee 
                                                                        onClick={()=>{
                                                                            handleChange({
                                                                                target: { name: 'employeeId', value: key.id },
                                                                            })
                                                                            setSearchValue(key.firstname+' '+key.lastname)
                                                                            setOpenList(false)
                                                                        }
                                                                    }
                                                                    >
                                                                        {key.firstname} {key.lastname}
                                                                    </ItemEmployee>
                                                                ))}
                                                            </ListEmployee>
                                                        </Wrapper>
                                                    ): null}
                                                {/* </div> */}
                                            <FormFeedback>{errors.employeeId}</FormFeedback>
                                            {/* {isLoadingEmployee ? (
                                            <div>
                                                <div className="spinner-grow text-primary" role="status">
                                                <span className="sr-only">Loading...</span>
                                                </div>
                                                <div className="spinner-grow text-primary" role="status">
                                                <span className="sr-only">Loading...</span>
                                                </div>
                                                <div className="spinner-grow text-primary" role="status">
                                                <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>) : (
                                            <Select
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor:
                                                        touched.employeeId && errors.employeeId
                                                        ? 'red' : 'rgba(0,0,0,.24)',
                                                    width: '100%',
                                                    appearance: 'auto',
                                                    }),
                                                }}
                                                onChange={(e)=>{
                                                    let arr = ''
                                                    handleChange({
                                                        target: { name: 'employeeId', value: e.value },
                                                    })
                                                    arr = e
                                                    setSelectedEmployee(arr)
                                                    // console.log(labelRuas)
                                                    // console.log(e.value)
                                                    // handleChange(e)
                                                }}
                                                name="employeeId"
                                                id="employeeId"
                                                value={selectedEmployee}
                                                options={dataSelectEmployee}
                                            />)
                                            } */}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <Label>{t('Password')}</Label>
                                            <Input
                                                type="password"
                                                name="password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                invalid={touched.password && !!errors.password}
                                            />
                                            <FormFeedback>{errors.password}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <Row className='align-items-center'>
                                            <Col md={6}>
                                                {t('Role List')}
                                            </Col>
                                            <Col md={6}>
                                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                    <a
                                                        href="#"
                                                        onClick={() => dispatch(openModalRole({ open: true }))}
                                                        className="btn btn-primary"
                                                    >
                                                        {t('Edit')} {t('Role List')}
                                                    </a ></div>
                                            </Col>
                                            <Col xs={12}>
                                                <Table className='mt-3'>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                #
                                                            </th>
                                                            <th>
                                                                {t('Role')}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {listRole.map((val, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{val.name}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </form>
                        )}
                    </Formik>
                </ModalComponent>

                <FormRoleList
                    dataListRole={dataSelect}
                    selectedRole={selectedList}
                    onChange={newSelected => {
                        setSelectedList(newSelected)
                        changeList(newSelected)
                    }}
                />
            </>
    )
}

export default FormUser