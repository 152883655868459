import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ModalComponent from '../../../components/Content/Modal'
import { openModalRole, openModalUser } from '../../../redux/slice/master/roleSlice'
import { getUserList } from '../../../redux/action/master/userAction'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { FormFeedback, FormGroup, Input, Label, Row, Col, Table } from 'reactstrap'
import { useDispatch } from 'react-redux'
import Alert from '../../../components/Content/Alert'
import { deleteRole, editRole, postRole, getRoleUserByIdRole } from '../../../redux/action/master/roleAction'
import FormUserList from './FormUserList'
import { useTranslation } from 'react-i18next'

const FormRole = ({ param }) => {

    const { isOpenModal, idRole, dataRoleById, stateFormRole, isLoadingRole, dataRoleUser } = useSelector((state) => state.role)
    const dispatch = useDispatch()

    const handleFormSubmit = (values) => {

        values.userIds = selectedList

        if (stateFormRole === 'add') {

            param.data = values
            dispatch(postRole(param))

        } else if (stateFormRole === 'edit') {

            param.data = values
            param.id = idRole
            dispatch(editRole(param))

        } else {

            param.id = idRole
            dispatch(deleteRole(param))
        }
    }

    const initialValues = {
        name: idRole === '' ? '' : dataRoleById.name,
        descr: idRole === '' ? '' : dataRoleById.descr
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required!'),
        descr: Yup.string().required('Description is required!'),
    })

    const [dataSelect, setSelect] = useState([])
    const [selectedList, setSelectedList] = useState([]);
    const { dataUser } = useSelector((state) => state.user)
    const [listUser, setListUser] = useState([])
    const {t} = useTranslation()

    useEffect(() => {

        // load data user
        dispatch(getUserList(param))
    }, [])

    useEffect(() => {

        let arr = [...dataSelect]

        // get data user set to an array
        if (dataUser.length > 0) {

            let newArr = dataUser.map(({ id, username }) => ({ value: id, label: username }))
            arr = newArr
        }

        // get data role by id on click
        if (Object.keys(dataRoleUser).length > 0) {

            let newArr = []
            let arrRoleUser = []
            let i = 0

            for (var key in dataRoleUser.userIds) {

                newArr.push(dataRoleUser.userIds[key])

                const matchingElements = dataUser.filter(item => item.id === dataRoleUser.userIds[key]);
                if (matchingElements.length > 0) {

                    arrRoleUser.push({ id: matchingElements[0].id, username: matchingElements[0].username })

                } else {
                    console.log("Data tidak ditemukan dalam array.");
                }

                i++
            }

            // get and set data only id user selected
            setSelectedList(newArr)

            // get and set data user selected
            setListUser(arrRoleUser)
        }

        // set array data all user to dataSelect
        setSelect(arr)

    }, [dataUser, dataRoleUser])

    useEffect(() => {
        if (stateFormRole === 'edit') {
            param.roleId = dataRoleById.id
            dispatch(getRoleUserByIdRole(param))

        }
    }, [isOpenModal])

    // do DualListBox on change selected
    const changeList = (selected) => {

        let newArr = []

        for (let i = 0; i < selected.length; i++) {

            if (stateFormRole === 'edit') {

                let matchingRoleUser = dataUser.filter(item => item.id === selected[i]);

                if (matchingRoleUser.length > 0) {
                    newArr.push({ id: matchingRoleUser[0].id, username: matchingRoleUser[0].username })
                } else if (matchingRoleUser.length === 0) {
                    newArr.push({ id: '', username: '' })
                }

            } else {

                let matchingRoleUser = dataUser.filter(item => item.id === selected[i]);
                if (matchingRoleUser.length > 0) {
                    newArr.push({ id: '', username: '' })
                }
            }
        }

        // get and set data user selected
        setListUser(newArr)
    }

    return stateFormRole === 'delete' ? (
        <Alert
            isOpen={isOpenModal}
            text="Are You Sure for Delete This Data ?"
            toggle={() => { dispatch(openModalRole({ open: true, type: 'add' })) }}
            close={() => { dispatch(openModalRole({ open: !isOpenModal, type: '' })) }} />
    ) : (
            <>
                <ModalComponent
                    isOpen={isOpenModal}
                    close={() => { dispatch(openModalRole({ open: !isOpenModal, type: '' })) }}
                    title={"Form Data " + t("Role")}
                    idForm="role-form"
                    toggle={() => { dispatch(openModalRole({ open: true, type: 'add' })) }}
                    size="lg"
                    isLoading={isLoadingRole}
                >
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                            <form onSubmit={handleSubmit} id="role-form">
                                <Row>
                                    <Col md={6}>
                                        <FormGroup className='mb-3'>
                                            <Label>{t("Name")}</Label>
                                            <Input
                                                type="text"
                                                id="name"
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                invalid={touched.name && !!errors.name}
                                            />
                                            <FormFeedback>{errors.name}</FormFeedback>
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <Label>{t("Description")}</Label>
                                            <Input
                                                type="text"
                                                id="descr"
                                                name="descr"
                                                value={values.descr}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                invalid={touched.descr && !!errors.descr}
                                            />
                                            <FormFeedback>{errors.descr}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <Row className='align-items-center'>
                                            <Col md={6}>
                                                {t("User List")}
                                            </Col>
                                            <Col md={6}>
                                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                    <a
                                                        href="#"
                                                        onClick={() => dispatch(openModalUser({ open: true }))}
                                                        className="btn btn-primary"
                                                    >
                                                        {t("Edit")} {t("User List")}
                                                    </a ></div>
                                            </Col>
                                            <Col xs={12}>
                                                <Table className='mt-3'>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                #
                                                            </th>
                                                            <th>
                                                                {t('Username')}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {listUser.map((val, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{val.username}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </form>
                        )}
                    </Formik>
                </ModalComponent>
                <FormUserList
                    dataListUser={dataSelect}
                    selectedUser={selectedList}
                    onChange={newSelected => {
                        setSelectedList(newSelected)
                        changeList(newSelected)
                    }}
                />
            </>
    )
}

export default FormRole