import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ModalComponent from '../../../../components/Content/Modal'
import { openFormRoute, openRouteStation } from '../../../../redux/slice/master/routeSlice'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Col, FormFeedback, FormGroup, Input, Label } from 'reactstrap'
import { useDispatch } from 'react-redux'
import Alert from '../../../../components/Content/Alert'
import { deleteRoute, editRoute, postRoute } from '../../../../redux/action/master/routeAction'
import Select from 'react-select'
import { getBusProviderById, getBusProviderList } from '../../../../redux/action/master/busproviderAction'
import { getFactoryById, getFactoryList } from '../../../../redux/action/master/factoryAction'
import { DualListBox } from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { getStationList } from '../../../../redux/action/master/stationAction'
import Wrapper from '../../../../components/Content/Wrapper'
import { useTranslation } from 'react-i18next'

const FormStation = ({dataStation,selectedStation, onChange}) => {
    const {isOpenRouteStation} = useSelector((state)=>state.route)
    const dispatch = useDispatch()
    const {t} = useTranslation()
    return  (
        <ModalComponent 
            isOpen={isOpenRouteStation} 
            close={()=>{dispatch(openRouteStation({open: !isOpenRouteStation}))}}
            title={"Data "+t("Route")+" "+t("Station")}
            size="lg"
            toggle={()=>{dispatch(openRouteStation({open: true, type: 'add'}))}}
        >
            <div className="row">
                <Col xs={12} sm={12} lg={12} md={12}>
                    <DualListBox
                        options={dataStation}
                        selected={selectedStation}
                        onChange={onChange}
                        canFilter
                        showOrderButtons
                        filterPlaceholder="Filter..."
                        icons={{
                        moveLeft: <i className="fa fa-chevron-left" />,
                        moveAllLeft: <i className="fas fa-angle-double-left" />,
                        moveRight: <i className="fa fa-chevron-right" />,
                        moveAllRight: <i className="fas fa-angle-double-right" />,
                        moveDown: <i className="fa fa-chevron-down" />,
                        moveUp: <i className="fa fa-chevron-up" />,
                        }}
                    />
                </Col>
            </div>
        </ModalComponent> 
    )
}

export default FormStation