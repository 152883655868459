import { createSlice } from "@reduxjs/toolkit";
import { deleteRole, editRole, getRoleById, getRoleByRegionalId, getRoleList, getRolePage, postRole, dataRoleUser, getRoleUserByIdRole } from "../../action/master/roleAction";

const initialState = {
  dataRole: [],
  isOpenModal: false,
  isOpenModalUser: false,
  stateFormRole: '',
  dataRoleById: '',
  idRole: '',
  isLoadingRole: false,
  dataRoleUser: [],
};
const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    openModalRole: (state, action) => {

      if (action.payload.open) {
        if (action.payload.type !== 'add') {
          state.dataRoleById = action.payload.data
          state.idRole = action.payload.id
        }
      } else {
        state.dataRoleById = ''
        state.idRole = ''
      }
      state.stateFormRole = action.payload.type
      state.isOpenModal = action.payload.open;
    },
    openModalUser: (state, action) => {
      state.isOpenModalUser = action.payload.open;
    },
  },
  extraReducers: {
    [getRolePage.pending]: (state) => {
      state.isLoadingRole = true
    },
    [getRolePage.fulfilled]: (state, action) => {
      state.dataRole = action.payload.data
      state.isLoadingRole = false
    },
    [getRolePage.rejected]: (state) => {
      state.isLoadingRole = false
    },

    [getRoleList.pending]: (state) => {
      state.isLoadingRole = true
    },
    [getRoleList.fulfilled]: (state, action) => {
      state.dataRole = action.payload.data
      state.isLoadingRole = false
    },
    [getRoleList.rejected]: (state) => {
      state.isLoadingRole = false
    },

    // [getRoleById.pending]: (state) => {
    //   state.isLoadingRole = true
    // },
    // [getRoleById.fulfilled]: (state, action) => {
    //   state.dataRole = action.payload.data
    //   state.isLoadingRole = false
    // },
    // [getRoleById.rejected]: (state) => {
    //   state.isLoadingRole = false
    // },

    [getRoleUserByIdRole.pending]: (state) => {
      state.isLoadingRole = true
    },
    [getRoleUserByIdRole.fulfilled]: (state, action) => {
      state.dataRoleUser = action.payload.data
      state.isLoadingRole = false
    },
    [getRoleUserByIdRole.rejected]: (state) => {
      state.isLoadingRole = false
    },

    [postRole.pending]: (state) => {
      state.isLoadingRole = true
    },
    [postRole.fulfilled]: (state, action) => {
      state.isOpenModal = false
      state.isLoadingRole = false
      state.stateFormRole = ''
    },
    [postRole.rejected]: (state) => {
      state.isLoadingRole = false
    },
    [editRole.pending]: (state) => {
      state.isLoadingRole = true
    },
    [editRole.fulfilled]: (state, action) => {
      state.isOpenModal = false
      state.isLoadingRole = false
      state.dataByIdRole = []
      state.idRole = 0
      state.stateFormRole = ''
    },
    [editRole.rejected]: (state) => {
      state.isLoadingRole = false
    },

    [deleteRole.pending]: (state) => {
      state.isLoadingRole = true
    },
    [deleteRole.fulfilled]: (state, action) => {
      state.isOpenModal = false
      state.isLoadingRole = false
      state.dataByIdRole = []
      state.idRole = 0
      state.stateFormRole = ''
    },
    [deleteRole.rejected]: (state) => {
      state.isLoadingRole = false
    },
  }
});

export const { openModalRole, openModalUser } = roleSlice.actions;

export default roleSlice.reducer;