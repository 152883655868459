import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  layoutType: "vertical",
  layoutWidth: "fluid",
  leftSideBarTheme: "light",
  leftSideBarType: "default",
  topbarTheme: "colored",
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false,
  showSidebar: true,
  leftMenu: false,
};
const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    changeLayout: (state, action) => {
      state.layoutType = action.payload;
    },
    changeLayoutWidth: (state, action) => {
      state.layoutWidth = action.payload;
    },
    changeTopbarTheme: (state, action) => {
      state.topbarTheme = action.payload;
    },
    changeSidebarType: (state, action) => {
      state.leftSideBarType = action.payload.type;
      state.isMobile = action.payload.isMobile;
    },
    changeSidebarTheme: (state, action) => {
      state.leftSideBarTheme = action.payload;
    },
    showRightSidebarAction: (state, action) => {
      state.showRightSidebar = action.payload;
    },
    toggleLeftmenu: (state, action) => {
      state.leftMenu = action.payload;
    },
    changePreloader: (state, action)=>{
      state.isPreloader = action.payload;
    },
    // changeTopbarTheme: (state) => {
    //   state.topbarTheme = state;
    // },
  },
});

export const {
  changeLayout,
  changeLayoutWidth,
  changeTopbarTheme,
  changeSidebarType,
  changeSidebarTheme,
  showRightSidebarAction,
  toggleLeftmenu,
  changePreloader
} = layoutSlice.actions;
export default layoutSlice.reducer;
