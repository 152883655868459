import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Col, FormGroup, Label, Row } from 'reactstrap'
import DatatableComponent from '../../components/Content/Datatable'
import Datatable from '../../components/Content/Datatable'
import ModalComponent from '../../components/Content/Modal'
import { getStationPage } from '../../redux/action/master/stationAction'
import { openFormStation } from '../../redux/slice/master/stationSlice'
import FormStation from './util/Station/FormStation'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getRouteList, getRouteStationPage } from '../../redux/action/master/routeAction'
import Select from "react-select";
import { useTranslation } from 'react-i18next'
import FilterSelect from '../../components/Content/FilterSelect'


const Station = () => {
  const {t} = useTranslation()
  const { isOpenStation } = useSelector((state)=>state.station)
  const { dataRouteStation, dataRoute, isLoadingRouteStation } = useSelector((state)=>state.route)
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState('')
  const [filter, setFilter] = useState([{name: '', route: ''}])
  const countPerPage = 10

  const [dataSelect, setSelect] = useState([{route: []}])
  const [selected, setSelected] = useState([{route: ''}])
  const onChange = (e, field) => {
    let arr = [...filter]
    if (field === 'name') {
      arr[0].name = e.target.value
    }
    if (field === 'route') {
      arr[0].route = e
    }
    setFilter(arr)
  }
  
  const columns = [
    {
        name: '#',
        selector: (row) => row.orderNumber,
        width: "60px"
    },
    {
      name: (
        <div className='row' style={{width: '100%', marginBottom: '13px'}}>
          <Col lg={12} md={12} sm={12} xs={12} className='mb-2'>
            {t("Name")}
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <input type='text' onChange={(e)=> onChange(e, 'name')} className='form-control' placeholder={'Search By '+ t("Name")} />
          </Col>
        </div>
      ),
      selector: (row) => row.stationName,
      width: "300px"
    },
    {
      name: (
        <div className='row' style={{width: '100%', marginBottom: '13px'}}>
          <Col lg={12} md={12} sm={12} xs={12} className='mb-2'>
            {t("Route")}
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <FilterSelect options={dataSelect[0].route.length > 0 ? dataSelect[0].route : []} onChange={(e)=>onChange(e,'route')}  />
          </Col>
        </div>
      ),
      selector: (row) => row.routeName,
    },
    {
        name: <>{t("Status")}</>,
        selector: (row) => row.isActive ? 'Active' : 'Not Active',
    },
    {
      name: <>{t("Action")}</>,
      cell: (row) => (
        <div style={{ padding: '10px 0' }}>
          <button 
            className='btn btn-outline-secondary btn-sm' 
            onClick={()=>{
              dispatch(openFormStation({open: !isOpenStation, type: 'edit',data: row, id: row.idStation}))
            }} 
            style={{marginRight: 5}}>
              {t("Edit")}
            </button> 
            <button 
            className='btn btn-danger btn-sm' 
            onClick={()=>{
              dispatch(openFormStation({open: !isOpenStation, type: 'delete',data: row, id: row.idStation}))
            }} 
            style={{marginRight: 5}}>
              {t("Delete")}
            </button>
        </div>
      ),
    },
  ]
  const { user } = useSelector((state) => state.auth)
  const param = {
    token: user.access_token,
    sort: sort,
    size: countPerPage,
    select: selected,
    filter: filter,
    page: page,
  }

  useEffect(() => {
    let arr = [...dataSelect]
    let selectedArr = [...selected]
    let newArr = [{value: '', label: 'All'}]
    if (dataRoute.length > 0) {
        dataRoute.map(({ id, name }) => (
          newArr.push({ value: id, label: name })
        ))
        // console.log(newArr)
        arr[0].route = newArr
    }
    selectedArr[0].route = arr[0].route[0]
    setSelect(arr)
    setSelected(selectedArr)
  },[dataRoute])
  useEffect(()=>{
    dispatch(getRouteStationPage(param))
  },[page, filter, sort])
  useEffect(()=>{
      dispatch(getRouteList(param))
  },[])
  return (
    <React.Fragment>
        <div className="page-content">
            {/* Render Breadcrumb */}
            <Breadcrumbs title={t("Master Data")} breadcrumbItem={t("Station")} />
            {/* Content */}
            <Row>
                <div className="col-6 mb-3">
                    <button className="btn btn-primary waves-effect waves-light" style={{marginRight: 5}}
                        onClick={()=>{dispatch(openFormStation({open: !isOpenStation, type: 'add'}))}}
                        data-toggle="modal"
                        data-target=".bs-example-modal-center">
                          + {t("Add Data")}
                    </button>
                </div>
                <div className="col-6 mb-3" style={{textAlign: 'right'}}>
                {isLoadingRouteStation ?
                (
                  <div>
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <button className="btn btn-primary waves-effect waves-light"
                      onClick={()=>setFilter([{name: '', route: ''}])}>
                        <i className="mdi mdi-refresh"></i> Reset Filter
                  </button>
                )}
                </div>
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <DatatableComponent  
                                data={dataRouteStation.content}
                                page={page}
                                countPerPage={countPerPage}
                                columns={columns}
                                totalElements={dataRouteStation.totalElements}
                                setPage={setPage}
                                columnFilter
                                columnSorter
                                pagination
                                tableProps={{
                                    hover: true,
                                }} 
                            />
                        </div>
                    </div>
                </div>
            </Row>
        </div>
        <FormStation isOpen={isOpenStation} onClose={()=> dispatch(openFormStation({open: !isOpenStation, type: ''}))} param={param} routeSelected={selected[0].route} routeData={dataSelect[0].route}/>
    </React.Fragment>

  )
}

export default Station