import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Col } from 'reactstrap'
import DatatableComponent from '../../../../components/Content/Datatable'
import ModalComponent from '../../../../components/Content/Modal'
import { getEmployeeList } from '../../../../redux/action/master/employeeAction'
import { getShiftList } from '../../../../redux/action/master/shiftAction'
import { deleteEmployeeSchedule, editEmployeeSchedule, getEmployeeScheduleByIdWeekly, getEmployeeScheduleByIdWeeklyPage } from '../../../../redux/action/master/weeklyScheduleAction'
import { backAction, changeStateNewShift, changeViewModal, openFormWeeklySchedule } from '../../../../redux/slice/master/weeklyScheduleSlice'
import FormEmployeeSchedule from './FormEmployeeSchedule'

const FormNewSchedule = (props) => {
    const [page, setPage] = useState(0)
    const [sort, setSort] = useState('')
    const [select, setSelect] = useState('')
    const [filter, setFilter] = useState('')
    const [filterName, setFilterName] = useState('')
    const countPerPage = 10
    const {t} = useTranslation()
    //
    const [checkbox, setCheckbox] = useState([])
    const { isOpenWeeklySchedule,isLoadingEmployeeSchedule, idWeeklySchedule, dataWeeklyScheduleById, stateFormWeeklySchedule, dataEmployeeScheduleById, dataEmployeeScheduleByIdShift, stateModal, dataEmployeeScheduleByIdWeekly, dataEmployeeScheduleByTanggal,stateShift } = useSelector((state)=>state.weeklySchedule)
    const { dataEmployee, isLoadingEmployee } = useSelector((state)=>state.employee)
    const { dataShift, isLoadingShift } = useSelector((state)=>state.shift)
    const [selectedList, setSelectedList] = useState([]);
    const [selectedBefore, setSelectedBefore] = useState([])
    const [listRouteStation, setListRouteStation] = useState([])
    const [dataEmployeeExist, setEmployeeExist] = useState([])
    const [dataEmployeeNotExist, setEmployeeNotExist] = useState([])
    // const [stateModal, setStateModal] = useState('employee')
    const dispatch = useDispatch()
    const changeState = (data, state) => {
        // console.log(data)
        dispatch(changeViewModal({state: state, dataShiftById: data}))
        // setStateModal('shift')
    }
    const changeNewShift = (e) => {
        dispatch(changeStateNewShift({data: dataEmployeeNotExist[e.target.value]}))
    }
    const [newDataEmployee, setDataEmployee] = useState([])
    // console.log(newDataEmployee)
    const saveEmployeeSchedule = () =>{
        props.param.stationData = selectedList
        props.param.oldStationData = selectedBefore
        props.param.idShift = dataEmployeeScheduleByIdShift.idShift
        props.param.idWeekly = dataEmployeeScheduleByTanggal.id
        props.param.idWeeklySchedule = idWeeklySchedule
        props.param.dataByTanggal = dataEmployeeScheduleByTanggal
        props.param.param = param
        dispatch(editEmployeeSchedule(props.param))
    }
    const changeCheckbox = (e) =>{
        let arr = checkbox
        let check = checkbox.includes(e.target.value)
        if(!check){
            arr.push(e.target.value)
            setCheckbox(arr)
            // console.log(arr)
        }else{
            let newArr = arr.filter(x => x !== e.target.value)
            setCheckbox(newArr)
            // console.log(newArr)
        }
    } 
    const deleteES = () => {
        param.data = checkbox
        param.idWeekly = dataEmployeeScheduleByTanggal.id
        param.idShift = dataEmployeeScheduleByIdShift.idShift
        dispatch(deleteEmployeeSchedule(param))
    }
    const changeList = (selected) => {
        let newArr = []
        // for(let i = 0 ; i < selected.length ; i++){
        //     if(stateFormRoute === 'edit'){
        //         let matchingRouteStation = dataRouteStation.filter(item => item.idStation === selected[i]);
        //         let matchingStation = dataStation.filter(item => item.id === selected[i]);
        //         if(matchingRouteStation.length > 0 && matchingStation.length > 0 ){
        //             newArr.push({no:matchingRouteStation[0].orderNumber ,id:matchingRouteStation[0].id, name:matchingStation[0].name, geoFencingEnd: matchingRouteStation[0].geoFencingEnd, geoFencingStart: matchingRouteStation[0].geoFencingStart, idStation: matchingStation[0].id})
        //         }else if(matchingRouteStation.length === 0 && matchingStation.length > 0){
        //             newArr.push({no:'' ,id:'', name:matchingStation[0].name, geoFencingEnd: false, geoFencingStart:false, idStation: matchingStation[0].id})
        //         }
        //     }else{
        //         let matchingStation = dataStation.filter(item => item.id === selected[i]);
        //         if(matchingStation.length > 0){
        //             newArr.push({no:'' ,id:'', name:matchingStation[0].name, geoFencingEnd: false, geoFencingStart:false, idStation: matchingStation[0].id})
        //         }
        //     }
            
        // }
        // console.log(selected)
        setListRouteStation(selected)
    }
    useEffect(()=>{
        let arr = []
        if(stateModal === 'addShift'){
            if (dataEmployee.length > 0) {
                let newArr = dataEmployee.map(({ id, firstname, lastname }) => ({ value: id, label: firstname+' '+lastname }))
                // console.log(newArr)
                arr = newArr
            }
        }
        setDataEmployee(arr)
    },[dataEmployee])

    const columns = [
        {
            name: '',
            cell: (row) => {
                let check = checkbox.includes(row.id)
                return (
                    <input type="checkbox" onChange={(e)=> changeCheckbox(e)} value={row.id} />
                )
            }
        },
        {
            name: 'MATRICULE',
            selector: (row) => row.nik,
        },
        {
            name: 'NAME',
            selector: (row) => row.name,
        },
    ]
    useEffect(()=>{
        if(stateModal === 'addShift'){
            dispatch(getEmployeeList(props.param))
            props.param.idWeekly = dataEmployeeScheduleByTanggal.id
            props.param.idShift = dataEmployeeScheduleByIdShift.idShift
            dispatch(getEmployeeScheduleByIdWeekly(props.param))
        }else if(stateModal === 'shift'){
            dispatch(getShiftList(param))
        }
    },[stateModal])
    const setDataExist = () =>{
        let arr = []
        let selectShift = []
        dataEmployeeScheduleById.map((key, index)=>{
            let count = 0
            key.schedules.map((keys, indexs)=> {
                count = count + keys.employees
            })
            if(count > 0){
                arr.push(key)
            }else{
                selectShift.push(key)
            }
        })
        setEmployeeExist(arr)
        setEmployeeNotExist(selectShift)
    }
    useEffect(()=>{
        if(dataEmployeeScheduleById.length > 0){
            setDataExist()
        }
    },[dataEmployeeScheduleById])
    const param = {
        token: props.param.token,
        sort: sort,
        size: countPerPage,
        select: select,
        filter: filter,
        page: page,
        name: filterName
    }
    useEffect(()=>{
        if(stateModal === 'detailEmployee'){
            param.idWeekly = dataEmployeeScheduleByTanggal.id
            param.idShift = dataEmployeeScheduleByIdShift.idShift
            dispatch(getEmployeeScheduleByIdWeeklyPage(param))
        }
    },[page, filter, select, sort, stateModal, filterName])
    useEffect(()=>{
        if(dataEmployeeScheduleByIdWeekly.length > 0){
            let newArr = [];
            let oldArr = [];
            
            dataEmployeeScheduleByIdWeekly.map((key) => {
                newArr.push(key.idEmployee)
                oldArr.push({id:key.id, idEmployee: key.idEmployee}) 
            })
            setSelectedList(newArr)
            setSelectedBefore(oldArr)
        }
    },[dataEmployeeScheduleByIdWeekly])
    return (
        <ModalComponent 
            isOpen={isOpenWeeklySchedule} 
            close={()=>{dispatch(openFormWeeklySchedule({open: !isOpenWeeklySchedule, type: ''}))}}
            title={stateModal === 'employee' ? "Form Data "+ t('Weekly Schedule') 
            : stateModal === 'shift' ? t('Employee Schedule') + " Detail" : t('Add') +' '+ t('Employee')}
            // idForm="weeklySchedule-form"
            footer={false}
            size="xl"
            toggle={()=>{dispatch(openFormWeeklySchedule({open: true, type: 'add'}))}}
        >
            <div className='row'>
                <Col xs={6} sm={6} md={6} lg={6}>
                    <div class="form-group row mb-3">
                        <label class="col-sm-2 col-form-label">{t('Week')}</label>
                        <div class="col-sm-10">
                        <input type="text" class="form-control" readOnly value={dataWeeklyScheduleById !== '' ?  dataWeeklyScheduleById.week : ''}/>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">{t('Year')}</label>
                        <div class="col-sm-10">
                        <input type="text" class="form-control" readOnly value={dataWeeklyScheduleById !== '' ?  dataWeeklyScheduleById.year : ''} />
                        </div>
                    </div>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6}>
                    <div class="form-group row mb-3">
                        <label class="col-sm-2 col-form-label">{t('Start Date')}</label>
                        <div class="col-sm-10">
                        <input type="text" class="form-control" readOnly value={dataWeeklyScheduleById !== '' ?  dataWeeklyScheduleById.localDate : ''} />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">{t('End Date')}</label>
                        <div class="col-sm-10">
                        <input type="text" class="form-control" readOnly value={dataWeeklyScheduleById !== '' ?  dataWeeklyScheduleById.dayOfWeek === 1 ? t('MONDAY') : dataWeeklyScheduleById.dayOfWeek === 2 ? t('TUESDAY'): dataWeeklyScheduleById.dayOfWeek === 3 ? t('WEDNESDAY'): dataWeeklyScheduleById.dayOfWeek === 4 ? t('THURSDAY'): dataWeeklyScheduleById.dayOfWeek === 5 ? t('FRIDAY'): dataWeeklyScheduleById.dayOfWeek === 6 ? t('SATURDAY') : t('SUNDAY') : ''} />
                        </div>
                    </div>
                </Col>

                <Col xs={12} sm={12} md={12} lg={12}>
                    <hr></hr>
                </Col>
            </div>
            <div className='row'>
                {stateModal === 'employee' ? (
                    <>
                        <Col xs={6} sm={6} md={6} lg={6} style={{alignSelf: 'center', marginBottom: '15px'}}>
                            <span style={{ color: '#495057', fontSize: 14, fontWeight: 500}}>{t('Employee Schedule')}</span>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} style={{textAlign: 'right', marginBottom: '15px'}}>
                            <button className="btn btn-primary waves-effect waves-light"
                                onClick={()=>{changeState('', 'shift',)}} 
                                data-toggle="modal"
                                data-target=".bs-example-modal-center">
                                + {t('Add Data')}
                            </button>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <table className='table table-borderless'>
                                <thead>
                                    <tr style={{fontSize: '13px'}}>
                                        <th>{t('Shift')}</th>
                                        {dataEmployeeExist.length > 0 ? (
                                            dataEmployeeExist[0].schedules.map((key, index)=>(
                                                <th>{key.tanggal}</th>
                                            ))
                                        ):(
                                            <th></th>
                                        )}
                                        <th>{t('Action')}</th>
                                    </tr>
                                </thead>
                                {!isLoadingEmployeeSchedule ? (
                                    <tbody>
                                    {
                                        dataEmployeeExist.map((key, index)=>(
                                            <tr style={{fontSize: '13px'}}>
                                                <td>{key.shift}</td>
                                                {key.schedules.map((keys, indexs)=>(

                                                    <td>{keys.employees}</td>
                                                ))}
                                                <td>
                                                    <button className='btn btn-outline-secondary btn-sm' 
                                                    onClick={()=>{changeState(key, 'shift')}} 
                                                    style={{marginRight: 5}}>
                                                    {t('Edit')}
                                                    </button>           
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                ) : (
                                    <div>
                                        <div className="spinner-grow text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                        </div>
                                        <div className="spinner-grow text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                        </div>
                                        <div className="spinner-grow text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                )}
                            </table>
                        </Col>
                    </>
                    ) : stateModal === 'shift' ?  (
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='row'>
                                <Col xs={6} sm={6} md={6} lg={6}>
                                    <div class="form-group row mb-3">
                                        <label class="col-sm-2 col-form-label">{t('Shift')}</label>
                                        <div class="col-sm-10">
                                        {stateShift === 'exist' ? (
                                            <input type="text" class="form-control" readOnly value={dataEmployeeScheduleByIdShift !== '' ?  dataEmployeeScheduleByIdShift.shift : ''}/>
                                        ) : (
                                            <select className='form-control' onChange={(e)=>changeNewShift(e)}>
                                                <option disabled selected>--{t('Select')} {t('Shift')}--</option>
                                                {dataEmployeeNotExist.map((key, index)=>(
                                                    <option value={index} key={index}>{key.shift}</option>
                                                ))}
                                            </select>
                                        )}
                                        </div>
                                    </div>
                                    {
                                        dataEmployeeScheduleByIdShift !== '' ? dataEmployeeScheduleByIdShift.schedules.map((key, index)=>(
                                            index < 3 ? (
                                                <div class="form-group row mb-3">
                                                    <label class="col-sm-3 col-form-label">{key.tanggal} : </label>
                                                    <label class="col-sm-7 col-form-label">{key.employees} {t('Employee')}</label>
                                                    <div class="col-sm-2" style={{alignSelf: 'center', textAlign: 'right'}}>
                                                        <button className='btn btn-outline-secondary btn-sm' onClick={()=>{changeState(key, 'detailEmployee')}} >{t('Edit')}</button>
                                                    </div>
                                                    {/* <div class="col-sm-10">
                                                        {dataEmployeeScheduleByIdShift !== '' ?  dataEmployeeScheduleByIdShift.shift : ''}
                                                    </div> */}
                                                </div>
                                            ) : null
                                        )) : null
                                    }
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={6}>

                                    {dataEmployeeScheduleByIdShift !== '' ? dataEmployeeScheduleByIdShift.schedules.map((key, index)=>(
                                            index > 2 ? (
                                                <>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-sm-3 col-form-label">{key.tanggal} : </label>
                                                        <label class="col-sm-7 col-form-label">{key.employees} {t('Employee')}</label>
                                                        <div class="col-sm-2" style={{alignSelf: 'center', textAlign: 'right'}}>
                                                            <button className='btn btn-outline-secondary btn-sm' onClick={()=>{changeState(key, 'detailEmployee')}}>{t('Edit')}</button>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : null
                                    )) : null}
                                </Col>
                            </div>
                        </Col>
                    ) : stateModal === 'addShift' ? (
                        <>
                            <Col xs={6} sm={6} md={6} lg={6}>
                                <h6>{t('Add')} {t('Employee Schedule')}</h6>
                            </Col>
                            {isLoadingEmployee ? (
                                <Col xs={6} sm={6} md={6} lg={6} style={{textAlign: 'right'}}>
                                    <div>
                                        <div className="spinner-grow text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                            </div>
                                            <div className="spinner-grow text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                            </div>
                                            <div className="spinner-grow text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </Col>
                            ) : null}
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <FormEmployeeSchedule 
                                    dataEmployee={newDataEmployee}
                                    selectedStation={selectedList}
                                    onChange={newSelected =>{
                                        setSelectedList(newSelected)
                                        changeList(newSelected)
                                    }} 
                                />
                            </Col>
                        </>
                    ) : (
                        <>
                            <Col xs={6} sm={6} md={6} lg={6} style={{marginBottom: 13}}>
                                <h5>Data {t('Employee Schedule')}</h5>
                            </Col>
                            {isLoadingEmployeeSchedule ? (
                                <Col xs={6} sm={6} md={6} lg={6} style={{textAlign: 'right', alignSelf: 'center'}}>
                                    <div>
                                        <div className="spinner-grow text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                            </div>
                                            <div className="spinner-grow text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                            </div>
                                            <div className="spinner-grow text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </Col>
                            ) : null}
                            <div className='row'>
                                <Col xs={6} sm={6} md={6} lg={6}>
                                    <div class="form-group row mb-3">
                                        <label class="col-sm-2 col-form-label">{t('Shift')}</label>
                                        <div class="col-sm-10">
                                        <input type="text" class="form-control" readOnly value={dataEmployeeScheduleByIdShift !== '' ?  dataEmployeeScheduleByIdShift.shift : ''} />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-2 col-form-label">{t('Schedule Date')}</label>
                                        <div class="col-sm-10">
                                        <input type="text" class="form-control" readOnly value={dataEmployeeScheduleByTanggal !== '' ?  dataEmployeeScheduleByTanggal.tanggal : ''} />
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={6}>
                                    <div className='row'>
                                        <Col xs={5} sm={5} md={5} lg={5} style={{marginBottom: 13, alignSelf: 'center'}}>
                                        <button className="btn btn-primary waves-effect waves-light" style={{marginRight: 5}}
                                            onClick={()=>{changeState('','addShift')}}>
                                            + {t('Add Data')}
                                        </button>
                                        <button className="btn btn-danger waves-effect waves-light"
                                            onClick={()=>{deleteES()}}>
                                            {t('Delete')}
                                        </button>
                                        </Col>
                                        <Col xs={7} sm={7} md={7} lg={7} style={{marginBottom: 13, textAlign: 'right'}}>
                                            <input type="text" class="form-control" onChange={(e)=>setFilterName(e.target.value)} placeholder='Search By Employee' value={filterName} />
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <DatatableComponent 
                                                data={dataEmployeeScheduleByIdWeekly.content}
                                                page={page}
                                                countPerPage={countPerPage}
                                                totalElements={dataEmployeeScheduleByIdWeekly.totalElements}
                                                columns={columns}
                                                setPage={setPage}
                                                columnFilter
                                                columnSorter
                                                pagination
                                                tableProps={{
                                                    hover: true,
                                                }} 
                                            />
                                        </Col>
                                    </div>
                                </Col>
                            </div>
                        </>
                    )
                }
                <Col xs={12} sm={12} md={12} lg={12} style={{textAlign: 'right', marginTop: 10}}>
                    {stateModal === 'addShift' ? (<button className='btn btn-outline-secondary' onClick={()=>saveEmployeeSchedule()} style={{marginRight: 5}}>{t('Save')}</button>) : null}
                    <button className='btn btn-primary' onClick={()=>{dispatch(backAction({state: stateModal === 'addShift' ? 'detailEmployee' : stateModal === 'detailEmployee' ? 'shift': 'employee'}))}}>{t('Back')}</button>
                </Col>
                
            </div>
        </ModalComponent>
    )
}

export default FormNewSchedule