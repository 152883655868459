import { createSlice } from "@reduxjs/toolkit";
import { deleteBus, editBus, getBusById, getBusByProviderId, getBusByRegionalId, getBusList, getBusPage, postBus } from "../../action/master/busAction";

const initialState = {
    dataBus: [],
    isOpenBus: false,
    stateFormBus: '',
    dataBusById: '',
    idBus: '',
    isLoadingBus: false,
};
const busSlice = createSlice({
    name: "bus",
    initialState,
    reducers: {
        openFormBus: (state, action) => {
          console.log(action.payload.type)
            if(action.payload.open){
                if(action.payload.type !== 'add'){
                    state.dataBusById = action.payload.data
                    state.idBus = action.payload.id
                }
            }else{
                state.dataBusById = ''
                state.idBus = ''
            }
            state.stateFormBus = action.payload.type
            state.isOpenBus = action.payload.open;
        },
    },
    extraReducers: {
      [getBusPage.pending]: (state) => {
        state.isLoadingBus = true
      },
      [getBusPage.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataBus = action.payload.data
        state.isLoadingBus = false
      },
      [getBusPage.rejected]: (state) => {
        state.isLoadingBus = false
      },
      [getBusList.pending]: (state) => {
        state.isLoadingBus = true
      },
      [getBusList.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataBus = action.payload.data
        state.isLoadingBus = false
      },
      [getBusList.rejected]: (state) => {
        state.isLoadingBus = false
      },
      [getBusById.pending]: (state) => {
        state.isLoadingBus = true
      },
      [getBusById.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataBusById = action.payload.data
        state.isLoadingBus = false
      },
      [getBusById.rejected]: (state) => {
        state.isLoadingBus = false
      },
      [getBusByRegionalId.pending]: (state) => {
        state.isLoadingBus = true
      },
      [getBusByRegionalId.fulfilled]: (state, action) => {
        // console.log(action.payload)
        state.dataBusByRegionalId = action.payload.data
        state.isLoadingBus = false
      },
      [getBusByRegionalId.rejected]: (state) => {
        state.isLoadingBus = false
      },
      [getBusByProviderId.pending]: (state) => {
        state.isLoadingBus = true
      },
      [getBusByProviderId.fulfilled]: (state, action) => {
        // console.log(action.payload)
        state.dataBus = action.payload.data
        state.isLoadingBus = false
      },
      [getBusByProviderId.rejected]: (state) => {
        state.isLoadingBus = false
      },
      [postBus.pending]: (state) => {
        state.isLoadingBus = true
      },
      [postBus.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.isOpenBus = false
        state.isLoadingBus = false
        state.stateFormBus = ''
      },
      [postBus.rejected]: (state) => {
        state.isLoadingBus = false
      },
      [editBus.pending]: (state) => {
        state.isLoadingBus = true
      },
      [editBus.fulfilled]: (state, action) => {
        state.isOpenBus = false
        state.isLoadingBus = false
        state.dataByIdBus = []
        state.idBus = 0
        state.stateFormBus = ''
      },
      [editBus.rejected]: (state) => {
        state.isLoadingBus = false
      },
      [deleteBus.pending]: (state) => {
        state.isLoadingBus = true
      },
      [deleteBus.fulfilled]: (state, action) => {
        state.isOpenBus = false
        state.isLoadingBus = false
        state.dataByIdBus = []
        state.idBus = 0
        state.stateFormBus = ''
      },
      [deleteBus.rejected]: (state) => {
        state.isLoadingBus = false
      },
    }
});

export const {  openFormBus } = busSlice.actions;

export default busSlice.reducer;