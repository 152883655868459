import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ModalComponent from '../../../../components/Content/Modal'
import { openFormStation } from '../../../../redux/slice/master/stationSlice'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Col, FormFeedback, FormGroup, Input, Label } from 'reactstrap'
import { useDispatch } from 'react-redux'
import Alert from '../../../../components/Content/Alert'
import { deleteStation, editStation, postStation } from '../../../../redux/action/master/stationAction'
import Select from 'react-select'
import { getRouteById } from '../../../../redux/action/master/routeAction'
import { useTranslation } from 'react-i18next'

const FormStation = ({param, routeSelected, routeData}) => {
    const { isOpenStation, idStation, dataStationById, stateFormStation, isLoadingStation } = useSelector((state)=>state.station)
    const { dataRoute, dataRouteStationById } = useSelector((state)=>state.route)
    const {t} = useTranslation()
    // const { dataRoute, dataRouteById } = useSelector((state)=>state.factory)
    // console.log(routeSelected)
    const dispatch = useDispatch()
    const handleFormSubmit = (values) =>{
        // console.log(values)
        param.data = values
        param.idRoute = idRoutes
        if(stateFormStation === 'add'){
            param.totalData = routeData.length
            dispatch(postStation(param))
        }else if(stateFormStation === 'edit'){
            param.id = idStation
            dispatch(editStation(param))
        }else{
            param.id = idStation
            dispatch(deleteStation(param))
        }
    }
    const [idRoutes, setRoute] = useState()
    const initialValues = {
        name: idStation ==='' ? '': dataStationById.stationName,
        longitude: idStation ==='' ? '': dataStationById.longitude,
        latitude: idStation ==='' ? '': dataStationById.latitude,
    }
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required!'),
        descr: Yup.string().required('Description is required!'),
        longitude: Yup.string().required('Longitude is required!'),
        latitude: Yup.string().required('Latitude is required!'),
    })
    useEffect(()=>{
        if(stateFormStation==='edit'){
            setRoute({value: dataStationById.idRoute, label: dataStationById.routeName})
        }
    },[stateFormStation])
    return stateFormStation === 'delete' ? (
        <Alert 
            isOpen={isOpenStation} 
            action={handleFormSubmit}
            text={t("Are You Sure for Delete This Data ?")}
            toggle={()=>{dispatch(openFormStation({open: !isOpenStation, type: ''}))}} 
            close={()=>{dispatch(openFormStation({open: !isOpenStation, type: ''}))}} />
    ) : (
        <ModalComponent 
            isOpen={isOpenStation} 
            close={()=>{dispatch(openFormStation({open: !isOpenStation, type: ''}))}}
            title={"Form Data "+t("Station")}
            idForm="station-form"
            toggle={()=>{dispatch(openFormStation({open: true, type: 'add'}))}}
            isLoading={isLoadingStation}
        >
            <Formik 
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit} id="station-form">
                        <div className='row'>
                            <Col xs={12} sm={12} lg={12} md={12}>
                                <FormGroup className='mb-3'>
                                    <Label>{t('Name')}</Label>
                                    <Input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.name && !!errors.name}
                                    />
                                    <FormFeedback>{errors.name}</FormFeedback>
                                </FormGroup>
                                <FormGroup className='mb-3'>
                                    <Label>{t('Longitude')}</Label>
                                    <Input
                                        type="text"
                                        id="longitude"
                                        name="longitude"
                                        value={values.longitude}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.longitude && !!errors.longitude}
                                    />
                                    <FormFeedback>{errors.longitude}</FormFeedback>
                                </FormGroup>
                                <FormGroup className='mb-3'>
                                    <Label>{t('Latitude')}</Label>
                                    <Input
                                        type="text"
                                        id="latitude"
                                        name="latitude"
                                        value={values.latitude}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.latitude && !!errors.latitude}
                                    />
                                    <FormFeedback>{errors.latitude}</FormFeedback>
                                </FormGroup>
                                <FormGroup className='mb-3'>
                                    <Label>{t('Description')}</Label>
                                    <Input
                                        type="text"
                                        id="descr"
                                        name="descr"
                                        value={values.descr}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.descr && !!errors.descr}
                                    />
                                    <FormFeedback>{errors.descr}</FormFeedback>
                                </FormGroup>
                                {stateFormStation !== 'edit' ? (
                                    <FormGroup className='mb-3'>
                                        <Label>{t('Route')}</Label>
                                        <Select
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderColor:
                                                    touched.idRoute && errors.idRoute
                                                    ? 'red' : 'rgba(0,0,0,.24)',
                                                width: '100%',
                                                appearance: 'auto',
                                                }),
                                            }}
                                            onChange={(e)=>{
                                                setRoute(e)
                                                // console.log(labelRuas)
                                                // console.log(e.value)
                                                // handleChange(e)
                                            }}
                                            name="idRoute"
                                            id="idRoute"
                                            value={idRoutes}
                                            options={routeData}
                                        />
                                    </FormGroup>
                                ) : null}
                            </Col>
                        </div>
                    </form>
                )}
            </Formik>
        </ModalComponent>
    )
}

export default FormStation