import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_MASTER } from '../../../constants'
import { openMessage } from '../../slice/messageSlice'
import { getRouteStationPage } from './routeAction'
// import { openMessage } from '../slice/messageSlice'
// import { openForm } from '../slice/api/stationSlice'

export const getStationPage = createAsyncThunk('station/page', async (param, thunkAPI) => {
  const respone = await fetch(
    `${API_MASTER}/api/station/page?page=${param.page}&size=${param.size}&sort=${param.select},${param.sort}&${param.select}=${param.filter}`,
    {
      method: 'get',
      headers: new Headers({
        Authorization: 'Bearer ' + param.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      }),
    },
  )
  const responeResult = await respone.json()
  // console.log("as")
  return responeResult
})
export const getStationById = createAsyncThunk('station/byId', async (param, thunkAPI) => {
  // console.log(param);
  const response = await fetch(`${API_MASTER}/api/station/${param.id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + param.token,
    },
  })
  if (response.status === 200) {
    const responseJson = await response.json()
    return responseJson
  }
})
export const getStationByRegionalId = createAsyncThunk('station/regionalId', async (param, thunkAPI) => {
  // console.log(param);
  const response = await fetch(`${API_MASTER}/api/station?regionalId=${param.regional}`, {
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
  })
  if (response.status === 200) {
    const responseJson = await response.json()
    return responseJson
  }
})
export const getStationList = createAsyncThunk('station/list', async (param, thunkAPI) => {
  const respone = await fetch(`${API_MASTER}/api/station/`, {
    method: 'get',
    headers: new Headers({
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    }),
  })
  const responeResult = await respone.json()
  // console.log("as")
  return responeResult
})
export const postStation = createAsyncThunk('station/post', async (param, thunkAPI) => {
  console.log(param);
  const bodyStation = {
    name : param.data.name,
    descr :  param.data.descr,
    longitude : param.data.longitude,
    latitude : param.data.latitude 
  }
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
    body: JSON.stringify(bodyStation),
  }
  let response = await fetch(`${API_MASTER}/api/station`, requestOptions)
  if (response.status === 200) {
    const responseJson = await response.json()
    const bodyRouteStation = [
      {
      idRoute : param.idRoute.value,
      idStation : responseJson.data.id,
      orderNumber : param.totalData + 1,
      geoFencingStart : false,
      geoFencingEnd : false
      }
    ]
    console.log(response.status)

    const requestOptionsStation = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + param.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      },
      body: JSON.stringify(bodyRouteStation),
    }
    let responseStation = await fetch(`${API_MASTER}/api/route/station`, requestOptionsStation)
    if (responseStation.status === 200) {
      thunkAPI.dispatch(
        openMessage({
          message: 'Success Insert Station!',
          type: 'success',
          open: true,
        }),
      )
      param.selected = []
      param.selected[0] = {route: {value: param.idRoute.value }}
      thunkAPI.dispatch(getRouteStationPage(param))
      return responseJson
    }
    if (responseStation.status === 400) {
      thunkAPI.dispatch(openMessage({ message: 'Insert Station Failed', type: 'danger', open: true }))
    }
    return responseJson
  }
  if (response.status === 400) {
    thunkAPI.dispatch(openMessage({ message: 'Insert Station Failed', type: 'danger', open: true }))
  }
})
export const editStation = createAsyncThunk('station/update', async (param, thunkAPI) => {
  console.log(param);
  const bodyStation = {
    name : param.data.name,
    descr :  param.data.descr,
    longitude : param.data.longitude,
    latitude : param.data.latitude 
  }
  const requestOptions = {
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
    body: JSON.stringify(bodyStation),
  }
  let response = await fetch(`${API_MASTER}/api/station/${param.id}`, requestOptions)
  if (response.status === 200) {
    console.log(response.status)
    const responseJson = await response.json()

    thunkAPI.dispatch(
      openMessage({
        message: 'Success Update Station!',
        type: 'success',
        open: true,
      }),
    )
    param.selected = []
    param.selected[0] = {route: {value: param.idRoute.value }}
    thunkAPI.dispatch(getRouteStationPage(param))
    return responseJson
  }
  if (response.status === 400) {
    thunkAPI.dispatch(openMessage({ message: 'Update Station Failed', type: 'danger', open: true }))
  }
  // .then(getApi())
})
export const deleteStation = createAsyncThunk('station/delete', async (param, thunkAPI) => {
  // console.log(param);
  const requestOptions = {
    method: 'Delete',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
  }
  let response = await fetch(`${API_MASTER}/api/station/${param.id}`, requestOptions)
  if (response.status === 200) {
    console.log(response.status)
    const responseJson = await response.json()

    thunkAPI.dispatch(
      openMessage({
        message: 'Success Delete Station!',
        type: 'success',
        open: true,
      }),
    )
    thunkAPI.dispatch(getStationPage(param))
    return responseJson
  }
  if (response.status === 400) {
    thunkAPI.dispatch(openMessage({ message: 'Delete Station Failed', type: 'danger', open: true }))
  }
  // .then(getApi())
})
