import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getNotificationBus, getNotificationBusList, updateStatusRead } from '../action/notificationAction'
// import { deleteCluster, findCluster, getClusterList, postCluster } from "../action/clusterAction";
// import axios from "axios";
// import cartItems from "../api";

const initialState = {
  dataNotif: '',
  dataNotifList: '',
  dataNotifById: '',
  dataNotifId: '',
  countNotif: 0,
  isOpenNotif: false,
  isLoadingNotif: false,
  isLoadingNotifList: false,
  startApp: true
}
const messageSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    changeStartApp: (state, action) => {
      state.startApp = false
    },
  },
  extraReducers: {
    [getNotificationBus.pending]: (state) => {
        state.isLoadingNotif   = true
    },
    [getNotificationBus.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataNotif = action.payload.data
        if(action.payload.code === 200){
          state.countNotif = action.payload.data.totalElements
        }
        state.isLoadingNotif = false
    },
    [getNotificationBus.rejected]: (state) => {
        state.isLoadingNotif   = false
    },
    [getNotificationBusList.pending]: (state) => {
        state.isLoadingNotifList   = true
    },
    [getNotificationBusList.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataNotifList = action.payload.data
        state.isLoadingNotifList   = false
    },
    [getNotificationBusList.rejected]: (state) => {
        state.isLoadingNotifList   = false
    },
    [updateStatusRead.pending]: (state) => {
        state.isLoadingNotifList   = true
    },
    [updateStatusRead.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.isLoadingNotifList   = false
    },
    [updateStatusRead.rejected]: (state) => {
        state.isLoadingNotifList   = false
    },
  }
})

export const { changeStartApp } = messageSlice.actions
export default messageSlice.reducer
