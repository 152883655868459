import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'reactstrap'
import styled from 'styled-components'

const Footer = styled.div`
    display: flex;
    align-items: center;
    border-top: 1px solid #eff2f7;
    border-bottom-right-radius: calc(0.4rem - 1px);
    border-bottom-left-radius: calc(0.4rem - 1px);
    flex-wrap: wrap;
    flex-shrink: 0;
    padding: 10px;
    justify-content: center;
`
const Alert = ({isOpen, close, children,idForm, toggle, text, action}) => {
    useEffect(()=>{
        if(isOpen){
            document.body.style.overflow = 'hidden';
        }else{
            document.body.style.overflow = null;
        }
        document.body.classList.add("no_padding")
    },[isOpen])
    const {t} = useTranslation()
    return (
        <Modal
            isOpen={isOpen}
            centered={true}
            toggle={()=>toggle()}
        >
            <div className="modal-body" style={{textAlign: 'center'}}>
               {text}
            </div>
            <Footer>
                <button className="btn btn-danger" onClick={()=>action()} style={{marginRight: '5px'}}>{t("Yes")}</button>
                <button className="btn btn-primary" onClick={()=>close()}>{t("No")}</button>
            </Footer>
        </Modal>
    )
}

export default Alert