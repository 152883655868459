import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_MASTER, API_ACCOUNT } from '../../../constants'
import { openMessage } from '../../slice/messageSlice'

export const getRolePage = createAsyncThunk('role/page', async (param, thunkAPI) => {
  const respone = await fetch(
    `${API_ACCOUNT}/admin/roles`,
    {
      method: 'get',
      headers: new Headers({
        Authorization: 'Bearer ' + param.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      }),
    },
  )
  const responeResult = await respone.json()
  // console.log("as")
  return responeResult
})

// export const getRoleById = createAsyncThunk('role/byId', async (param, thunkAPI) => {
//   // console.log('xxxxxxxx-----------',param);
//   const response = await fetch(`${API_MASTER}/api/role/${param.id}`, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: 'Bearer ' + param.token,
//     },
//   })
//   if (response.status === 200) {
//     const responseJson = await response.json()
//     return responseJson
//   }
// })

export const getRoleUserByIdRole = createAsyncThunk('role/user/roleId', async (param, thunkAPI) => {
  // console.log('---getRoleUserByIdRole:',param);
  const response = await fetch(`${API_ACCOUNT}/api/roles/${param.roleId}`, {
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
  })
  if (response.status === 200) {
    const responseJson = await response.json()
    return responseJson
  }
})

export const getRoleByRegionalId = createAsyncThunk('role/regionalId', async (param, thunkAPI) => {
  // console.log(param);
  const response = await fetch(`${API_MASTER}/api/role?regionalId=${param.regional}`, {
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
  })
  if (response.status === 200) {
    const responseJson = await response.json()
    return responseJson
  }
})

export const getRoleList = createAsyncThunk('role/list', async (param, thunkAPI) => {
  const respone = await fetch(`${API_ACCOUNT}/api/roles/`, {
    method: 'get',
    headers: new Headers({
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    }),
  })
  const responeResult = await respone.json()
  // console.log("as")
  return responeResult
})

export const postRole = createAsyncThunk('role/post', async (param, thunkAPI) => {
  // console.log(param);
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
    body: JSON.stringify(param.data),
  }
  let response = await fetch(`http://202.180.16.237:18080/account/admin/roles`, requestOptions)
  if (response.status === 200) {
    // console.log(response.status)
    const responseJson = await response.json()
    thunkAPI.dispatch(
      openMessage({
        message: 'Success Insert Role!',
        type: 'success',
        open: true,
      }),
    )
    thunkAPI.dispatch(getRolePage(param))
    return responseJson
  }
  if (response.status === 400) {
    thunkAPI.dispatch(openMessage({ message: 'Insert Role Failed', type: 'danger', open: true }))
  }
})

export const editRole = createAsyncThunk('role/update', async (param, thunkAPI) => {
  // console.log(param);
  const requestOptions = {
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
    body: JSON.stringify(param.data),
  }
  let response = await fetch(`http://202.180.16.237:18080/account/admin/roles/${param.id}`, requestOptions)
  if (response.status === 200) {
    // thunkAPI.dispatch(
    //   openMessage({ message: 'Edit Site Successfull', type: 'success', open: true })
    // );
    thunkAPI.dispatch(getRolePage(param))
  }
  // .then(getApi())
})

export const deleteRole = createAsyncThunk('role/delete', async (param, thunkAPI) => {
  // console.log(param);
  const requestOptions = {
    method: 'Delete',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
  }
  let response = await fetch(`http://202.180.16.237:18080/account/admin/roles/${param.id}`, requestOptions)
  if (response.status === 200) {
    // console.log(response.status)
    const responseJson = await response.json()

    thunkAPI.dispatch(
      openMessage({
        message: 'Success Delete Role!',
        type: 'success',
        open: true,
      }),
    )
    thunkAPI.dispatch(getRolePage(param))
    return responseJson
  }
  if (response.status === 400) {
    thunkAPI.dispatch(openMessage({ message: 'Delete Role Failed', type: 'danger', open: true }))
  }
  // .then(getApi())
})

export const getRouteListByIdBusProvider = createAsyncThunk('route/list/byId/busProvider', async (param, thunkAPI) => {
  const respone = await fetch(`${API_MASTER}/api/route/providerId/${param.idBusProvider}`, {
    // const respone = await fetch(`${API_MASTER}/api/route/`, {
    method: 'get',
    headers: new Headers({
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    }),
  })
  const responeResult = await respone.json()
  // console.log("as")
  return responeResult
})
