import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { Bar } from "react-chartjs-2"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { getBarChartDashboard, getPieChartDashboard } from "../../redux/action/dashboardAction"

const BarChart = () => {
  const { dataPieChart } = useSelector((state)=>state.dashboard)
  const [datas, setDatas] = useState([])
  const [labels, setLabels] = useState([])
  const [colors, setColors] = useState([])
  const dispatch = useDispatch()
  useEffect(()=>{
    if(dataPieChart.length > 0){
      let dataBar = []
      let titleBar = []
      let colorBar = []
      dataPieChart.map((key, index)=>{
          let num = Math.round(0xffffff * Math.random());
          let r = num >> 16;
          let g = num >> 8 & 255;
          let b = num & 255;
          dataBar.push(key.busCount)
          titleBar.push(key.alias)
          colorBar.push('rgba(' + r + ', ' + g + ', ' + b + ', 0.75)')
        }
      )
      // console.log(colorBar)
      setDatas(dataBar)
      setLabels(titleBar)
      setColors(colorBar)
    }
  },[dataPieChart])
  // const data = {
  //   type: 'bar',
  //   labels: labels,
  //   datasets: [
  //     {
  //       label: "Number of Buses",
  //       backgroundColor: colors,
  //       hoverBackgroundColor: colors,
  //       borderWidth: 1,
  //       data: datas,
  //     },
  //   ],
  // }

  // const Options = {
  //   scales: {
  //     xAxes: [
  //       {
  //         barPercentage: 0.6,
  //       },
  //     ],
  //   },
  //   legend: { display: false },
  // }
  const series = [
  {
    name: "Bus Provider",
    type: 'line',
    data: datas
  }]

const options = {
    chart: {
        toolbar: {
            show: false
        },
        zoom: {
            enabled: false
        }
    },
    colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
    dataLabels: {
        enabled: false,
    },
    stroke: {
        curve: 'smooth',
        width: '3',
        dashArray: [0],
    },

    markers: {
        size: 5,
        colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800']
    },
    xaxis: {
        categories: labels,
        title: {
            text: 'Bus'
        }
    },

    fill: {
        type: 'gradient',
        opacity: [1],
    },

    legend: {
        position: 'top',
        horizontalAlign: 'right',
    }
}
  const { user } = useSelector((state) => state.auth)
  useEffect(()=>{
    dispatch(getPieChartDashboard({token: user.access_token}))
  },[])

  return (
    // <div style={{ height: "100%" }}>
    <ReactApexChart
        options={options}
        series={series}
        height="260"
        type="line"
        className="apex-charts"
    />
    // </div>
  )
}

export default BarChart
