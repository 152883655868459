import React, { useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardHeader, Col, Table } from 'reactstrap'
import Wrapper from '../../components/Content/Wrapper'
import { getEmployeeShiftDashboard } from '../../redux/action/dashboardAction'

const EmployeeShift = ({param}) => {
    const { dataEmployeeShift, isLoadingEmployeeShift } = useSelector((state)=> state.dashboard)
    const [date, setDate] = useState()
    const {t} = useTranslation()
    const dispatch = useDispatch()
    useEffect(()=>{ 
        if(date){
            param.date = date
            dispatch(getEmployeeShiftDashboard(param))
        }
    },[date])
    useEffect(()=>{
        let now = new Date();
        let month = (now.getMonth() + 1);               
        let day = now.getDate();
        if (month < 10) 
            month = "0" + month;
        if (day < 10) 
            day = "0" + day;
        let today = now.getFullYear() + '-' + month + '-' + day;
        setDate(today)
    },[])


    return (
        <Card style={{margin: 0}}>
            <CardHeader style={{padding: '20px 17px'}}>
                <div className="row">
                <Col xs={6} md={6} lg={6} sm={6} style={{alignSelf: 'center'}}>
                    <span style={{fontWeight:700, fontSize: 15}}>{t('Employee Count per Shift')}</span>
                </Col>
                <Col xs={6} md={6} lg={6} sm={6}>
                    <input type="date" onChange={(e)=>setDate(e.target.value)} className="form-control" value={date} />
                </Col>
                </div>
            </CardHeader>
            <CardBody>
                    {
                        isLoadingEmployeeShift ? (
                            <div>
                                <div className="spinner-grow text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                <div className="spinner-grow text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                <div className="spinner-grow text   -primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : (

                            <div className='row' style={{width: '100%'}}>
                                {
                                    dataEmployeeShift.map((key, index)=>(
                                        <Col sm={3} xs={3} md={3} lg={3}>
                                            <Card style={{borderLeft: '7px rgba(40,61,146,255) solid', background: '#f6f6f6', padding: 13}}>
                                                <div className='row'>
                                                    <Col sm={12} xs={12} md={12} lg={12}>
                                                        <span style={{fontWeight: 600, fontSize: 16, color: 'rgba(40,61,146,255)'}}>{key.shiftName}</span>
                                                    </Col>
                                                    <Col sm={12} xs={12} md={12} lg={12}>
                                                        <span style={{fontWeight: 300, fontSize: 12, color: 'rgba(40,61,146,255)'}}>{key.employee === null ? '0' : key.employee}</span>
                                                    </Col>
                                                </div>
                                            </Card>
                                        </Col>
                                    ))
                                }
                            </div>
                        )
                    }
            </CardBody>
        </Card>
        
    )
}

export default EmployeeShift