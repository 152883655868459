import { GoogleApiWrapper, InfoWindow, Map } from "google-maps-react";
import React from "react";
import { connect } from "react-redux";
import { Card, CardBody } from "reactstrap";

const LoadingContainer = () => <div>Loading...</div>

const MapsGoogle = props => {
    const selectedPlace = {}

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    {/* <h4 className="card-title mb-4">Simple Map</h4> */}
                    <div id="simple-gmaps" className="gmaps" style={{ position: "relative" }}>
                        <Map
                            google={props.google}
                            initialCenter={{ lat: 35.8039419, lng: 10.5639663 }}
                            zoom={9.25}
                            style={{ width: "100%", height: "100%" }}
                            >
                            <InfoWindow>
                                <div>
                                <h1>{selectedPlace.name}</h1>
                                </div>
                            </InfoWindow>
                        </Map>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default connect(
    null,
    {}
  )(
    GoogleApiWrapper({
      apiKey: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    //   apiKey: "AIzaSyD8_-qAgozijkASl1NGqvA9acVTxUE23S8",
      LoadingContainer: LoadingContainer,
      v: "3",
    })(MapsGoogle)
  )