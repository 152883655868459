import PropTypes from 'prop-types'
import React, { Component, useEffect, useRef, useState } from "react"

import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
// import {
//   changeLayout,
//   changeSidebarTheme,
//   changeSidebarType,
//   changeTopbarTheme,
//   changeLayoutWidth,
// } from "../../store/actions"

import Header from "./Header"
import Sidebar from "./Sidebar"
import Footer from "./Footer"
import Rightbar from "../CommonForBoth/Rightbar"
import { useDispatch } from 'react-redux'
import { changeLayout, changeLayoutWidth, changeSidebarTheme, changeSidebarType, changeTopbarTheme } from '../../redux/slice/layoutSlice'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { openMessage } from '../../redux/slice/messageSlice'
import { Col } from 'reactstrap'
import { getNotificationBus } from '../../redux/action/notificationAction'
import { changeStartApp } from '../../redux/slice/notificationSlice'

const Toast = styled.div`
  visibility: hidden;
  position: absolute;
  right: 10px;
  top: 15px;
  z-index: 1100;
  background: ${(props) => props.color};
  padding: 15px;
  border-radius: 7px;
  border-left: 7px solid white;
  width: 250px;
  transition: visibility 0s, opacity 0.5s linear;
  &.active {
    visibility: visible;
  }
`
const ToastBody = styled.div`
  color: white;
`
const Layout = props => {
  // console.log(props);
  const { message, type, isOpenMessage } = useSelector((state) => state.message)
  const { dataNotif, countNotif, startApp } = useSelector((state) => state.notification)
  const [countLastNotif, setCountLast] = useState(countNotif)
  const dispatch = useDispatch()
  // const {
  //   layoutType,
  //   layoutWidth,
  //   leftSideBarTheme,
  //   leftSideBarType,
  //   topbarTheme,
  //   showRightSidebar,
  //   showSidebar,
  //   leftMenu,
  // } = useSelector((state) => state.layout);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2)
  }
  useEffect(() => {
    if (props.isPreloader === true) {
      document.getElementById("preloader").style.display = "block"
      document.getElementById("status").style.display = "block"

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none"
        document.getElementById("status").style.display = "none"
      }, 2500)
    } else {
      document.getElementById("preloader").style.display = "none"
      document.getElementById("status").style.display = "none"
    }

    // Scroll Top to 0
    window.scrollTo(0, 0)
    let currentage = capitalizeFirstLetter(props.location.pathname)

    document.title =
      currentage + " | ETMS Leoni"
    if (props.leftSideBarTheme) {
      dispatch(changeSidebarTheme(props.leftSideBarTheme))
    }

    dispatch(changeLayout("detached"))

    if (props.layoutWidth) {
      dispatch(changeLayoutWidth(props.layoutWidth))
    }

    if (props.leftSideBarType) {
      dispatch(changeSidebarType({type: props.leftSideBarType, isMobile: false}))
    }
    if (props.topbarTheme) {
      dispatch(changeTopbarTheme(props.topbarTheme))
    }
  },[])

    // Scroll Top to 0
   
  const toggleMenuCallback = () => {
    if (props.leftSideBarType === "default") {
      props.changeSidebarType({type: "condensed", isMobile: isMobile})
    } else if (props.leftSideBarType === "condensed") {
      props.changeSidebarType({type: "default", isMobile: isMobile})
    }
  }
  const colorToast = type === 'success' ? 'rgba(89, 214, 103, 0.9)' : type === 'info' ?  'rgba(2, 189, 176, 0.9)' : 'rgba(229, 33, 33, 0.9)'
  useEffect(() => {
    if (isOpenMessage) {
      setTimeout(function () {
        dispatch(openMessage({ message: '', type: '', open: false }))
      }, 5000)
    }
  }, [isOpenMessage])
  const { user } = useSelector((state) => state.auth)
  const param = {
    token: user.access_token,
    page: 0,
    size: 10,
  }
  const myInterval = useRef()
  const _getIntervalData = () => {
    myInterval.current = setInterval(()=>{
      dispatch(getNotificationBus(param))
    },10000)
  }
  useEffect(() => {
    if(dataNotif === ''){
      console.log('ada')
      dispatch(getNotificationBus(param))
      _getIntervalData()
    }else{
      console.log('ada juga')
      dispatch(getNotificationBus(param))
      _getIntervalData()
      dispatch(changeStartApp())
      setCountLast(countNotif)
    }

    return () => {
        clearInterval(myInterval.current);
        myInterval.current = null;
    }
  }, [])
  // console.log(countLastNotif)
  useEffect(() => {
      // dispatch(getNotificationBus(param))
      if(!startApp){
        if(countNotif !== countLastNotif){
          dispatch(openMessage({
            message: 'You Have New Notification',
            type: 'info',
            open: true,
          }))
        }
      }
  }, [countNotif])

  return (
    <React.Fragment>
        <Toast className={isOpenMessage ? 'active' : ''} color={colorToast}>
          <ToastBody id="toast-notif">
            <div className='row'>
              <Col 
                xs="3"
                md="3"
                sm="3"
                lg="3"
                style={{ textAlign: 'center', alignSelf: 'center' }}
                >

              {type === 'success' 
                ? (<i className="mdi mdi-checkbox-marked-circle" style={{fontSize:'30px'}}></i>) 
                : type === 'info' 
                ? (<i className="mdi mdi-alert-rhombus" style={{fontSize:'30px'}}></i>)
                : (<i className="mdi mdi-alert-circle" style={{fontSize:'30px'}}></i>)
              }
              </Col>
              <Col 
                xs="9"
                md="9"
                sm="9"
                lg="9"
                style={{ textAlign: 'center', alignSelf: 'center' }}
                >
                  <div className='row'>
                    <Col xs="12" md="12" sm="12" lg="12">
                      <span style={{ fontWeight: 600, fontSize: 14 }}>{type.toUpperCase()}</span>
                    </Col>
                    <Col xs="12" md="12" sm="12" lg="12">
                      <span style={{ fontWeight: 400, fontSize: 12 }}>{message}</span>
                    </Col>
                  </div>
              </Col>
            </div>
          </ToastBody>
        </Toast>
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div id="layout-wrapper">
            <Header toggleMenuCallback={toggleMenuCallback} />
            <Sidebar
              theme={props.leftSideBarTheme}
              type={props.leftSideBarType}
              isMobile={isMobile}
            />
            <div className="main-content">
              {props.children}
              <Footer />
            </div>
          </div>
        </div>
        {props.showRightSidebar ? <Rightbar /> : null}
      </React.Fragment>
  )
}

Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any
}

const mapStatetoProps = state => {
  return {
    ...state.layout,
  }
}
export default connect(mapStatetoProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
})(withRouter(Layout))