import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Col } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import MapTracking from './MapTracking';

const BusTracking = () => {
    const { user } = useSelector((state) => state.auth)
    const param = {
        token: user.access_token,
        roles: user.roles[0],
        idRoute: user.route_id
    }
    const {t} = useTranslation()
    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title={t("Bus Tracking")} breadcrumbItem={t("Bus Tracking")} />
                <div className='row'>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <MapTracking param={param} />
                    </Col>
                </div>
            </div>
        </React.Fragment>
    )
}

export default BusTracking