import { createSlice } from "@reduxjs/toolkit";
import { deleteDevice, editDevice, getDeviceById, getDeviceByRegionalId, getDeviceList, getDevicePage, postDevice } from "../../action/master/deviceAction";

const initialState = {
    dataDevice: [],
    isOpenDevice: false,
    stateFormDevice: '',
    dataDeviceById: '',
    idDevice: '',
    isLoadingDevice: false,
};
const deviceSlice = createSlice({
    name: "device",
    initialState,
    reducers: {
        openFormDevice: (state, action) => {
            if(action.payload.open){
                if(action.payload.type !== 'add'){
                    state.dataDeviceById = action.payload.data
                    state.idDevice = action.payload.id
                }
            }else{
                state.dataDeviceById = ''
                state.idDevice = ''
            }
            state.stateFormDevice = action.payload.type
            state.isOpenDevice = action.payload.open;
        },
    },
    extraReducers: {
      [getDevicePage.pending]: (state) => {
        state.isLoadingDevice = true
      },
      [getDevicePage.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataDevice = action.payload.data
        state.isLoadingDevice = false
      },
      [getDevicePage.rejected]: (state) => {
        state.isLoadingDevice = false
      },
      [getDeviceList.pending]: (state) => {
        state.isLoadingDevice = true
      },
      [getDeviceList.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataDevice = action.payload.data
        state.isLoadingDevice = false
      },
      [getDeviceList.rejected]: (state) => {
        state.isLoadingDevice = false
      },
      [getDeviceById.pending]: (state) => {
        state.isLoadingDevice = true
      },
      [getDeviceById.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataDevice = action.payload.data
        state.isLoadingDevice = false
      },
      [getDeviceById.rejected]: (state) => {
        state.isLoadingDevice = false
      },
      [getDeviceByRegionalId.pending]: (state) => {
        state.isLoadingDevice = true
      },
      [getDeviceByRegionalId.fulfilled]: (state, action) => {
        // console.log(action.payload)
        state.dataDeviceByRegionalId = action.payload.data
        state.isLoadingDevice = false
      },
      [getDeviceByRegionalId.rejected]: (state) => {
        state.isLoadingDevice = false
      },
      [postDevice.pending]: (state) => {
        state.isLoadingDevice = true
      },
      [postDevice.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.isOpenDevice = false
        state.isLoadingDevice = false
        state.stateFormDevice = ''
      },
      [postDevice.rejected]: (state) => {
        state.isLoadingDevice = false
      },
      [editDevice.pending]: (state) => {
        state.isLoadingDevice = true
      },
      [editDevice.fulfilled]: (state, action) => {
        state.isOpenDevice = false
        state.isLoadingDevice = false
        state.dataByIdDevice = []
        state.idDevice = 0
        state.stateFormDevice = ''
      },
      [editDevice.rejected]: (state) => {
        state.isLoadingDevice = false
      },
      [deleteDevice.pending]: (state) => {
        state.isLoadingDevice = true
      },
      [deleteDevice.fulfilled]: (state, action) => {
        state.isOpenDevice = false
        state.isLoadingDevice = false
        state.dataByIdDevice = []
        state.idDevice = 0
        state.stateFormDevice = ''
      },
      [deleteDevice.rejected]: (state) => {
        state.isLoadingDevice = false
      },
    }
});

export const {  openFormDevice } = deviceSlice.actions;

export default deviceSlice.reducer;