import { createSlice } from "@reduxjs/toolkit";
import { deleteUser, editUser, getUserById, getUserList, getUserPage, postUser } from "../../action/master/userAction";

const initialState = {
  dataUserPage: [],
  dataUser: [],
  isOpenModalRole: false,
  isOpenUser: false,
  stateFormUser: '',
  dataUserById: '',
  idUser: '',
  isLoadingUser: false,
  dataUserRole: [],
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    openModalUser: (state, action) => {
      if (action.payload.open) {
        if (action.payload.type !== 'add') {
          state.dataUserById = action.payload.data
          state.idUser = action.payload.id
        }
      } else {
        state.dataUserById = ''
        state.idUser = ''
      }
      state.stateFormUser = action.payload.type
      state.isOpenUser = action.payload.open;
    },
    openModalRole: (state, action) => {
      state.isOpenModalRole = action.payload.open;
    },
  },
  extraReducers: {

    // get page
    [getUserPage.pending]: (state) => {
      state.isLoadingUser = true
    },
    [getUserPage.fulfilled]: (state, action) => {
      state.dataUserPage = action.payload.data
      state.isLoadingUser = false
    },
    [getUserPage.rejected]: (state) => {
      state.isLoadingUser = false
    },

    // get list
    [getUserList.pending]: (state) => {
      state.isLoadingUser = true
    },
    [getUserList.fulfilled]: (state, action) => {
      // console.log(action.payload);
      state.dataUser = action.payload.data
      state.isLoadingUser = false
    },
    [getUserList.rejected]: (state) => {
      state.isLoadingUser = false
    },

    // get by id
    [getUserById.pending]: (state) => {
      state.isLoadingUser = true
    },
    [getUserById.fulfilled]: (state, action) => {
      // console.log(action.payload);
      state.dataUserById = action.payload.data
      state.isLoadingUser = false
    },
    [getUserById.rejected]: (state) => {
      state.isLoadingUser = false
    },

    // crud create
    [postUser.pending]: (state) => {
      state.isLoadingUser = true
    },
    [postUser.fulfilled]: (state, action) => {
      // console.log(action.payload);
      if(action.payload.message === 'Success'){
        state.isOpenUser = false
        state.isLoadingUser = false
        state.stateFormUser = ''
      }
    },
    [postUser.rejected]: (state) => {
      state.isLoadingUser = false
    },

    // crud update
    [editUser.pending]: (state) => {
      state.isLoadingUser = true
    },
    [editUser.fulfilled]: (state, action) => {
      state.isOpenUser = false
      state.isLoadingUser = false
      state.dataByIdUser = []
      state.idUser = 0
      state.stateFormUser = ''
    },
    [editUser.rejected]: (state) => {
      state.isLoadingUser = false
    },

    // creud delete
    [deleteUser.pending]: (state) => {
      state.isLoadingUser = true
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.isOpenUser = false
      state.isLoadingUser = false
      state.dataByIdUser = []
      state.idUser = 0
      state.stateFormUser = ''
    },
    [deleteUser.rejected]: (state) => {
      state.isLoadingUser = false
    },
  }
});

export const { openModalUser, openModalRole } = userSlice.actions;

export default userSlice.reducer;