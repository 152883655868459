import { createSlice } from "@reduxjs/toolkit";
import { deleteRoute, editRoute, getRouteById, getRouteByRegionalId, getRouteList, getRouteListByIdBusProvider, getRoutePage, getRouteStationById, getRouteStationByIdRoute, getRouteStationPage, postRoute } from "../../action/master/routeAction";

const initialState = {
    dataRoute: [],
    isOpenRoute: false,
    isOpenRouteStation: false,
    stateFormRoute: '',
    dataRouteById: '',
    idRoute: '',
    isLoadingRoute: false,
    dataRouteStation: [],
    dataRouteStationById: '',
};
const routeSlice = createSlice({
    name: "route",
    initialState,
    reducers: {
        openFormRoute: (state, action) => {
            if(action.payload.open){
                if(action.payload.type !== 'add'){
                    state.dataRouteById = action.payload.data
                    state.idRoute = action.payload.id
                }
            }else{
                state.dataRouteById = ''
                state.idRoute = ''
            }
            state.stateFormRoute = action.payload.type
            state.isOpenRoute = action.payload.open;
        },
        openRouteStation: (state, action) => {
          // if(state.isOpenRouteStation){
          //   state.isOpenRoute = true;
          // }else{
          //   state.isOpenRoute = false;
          // }
          state.isOpenRouteStation = action.payload.open;
        },
    },
    extraReducers: {
      [getRoutePage.pending]: (state) => {
        state.isLoadingRoute = true
      },
      [getRoutePage.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataRoute = action.payload.data
        state.isLoadingRoute = false
      },
      [getRoutePage.rejected]: (state) => {
        state.isLoadingRoute = false
      },
      [getRouteStationPage.pending]: (state) => {
        state.isLoadingRouteStation = true
      },
      [getRouteStationPage.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataRouteStation = action.payload.data
        state.isLoadingRouteStation = false
      },
      [getRouteStationPage.rejected]: (state) => {
        state.isLoadingRouteStation = false
      },
      [getRouteList.pending]: (state) => {
        state.isLoadingRoute = true
      },
      [getRouteList.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataRoute = action.payload.data
        state.isLoadingRoute = false
      },
      [getRouteList.rejected]: (state) => {
        state.isLoadingRoute = false
      },
      [getRouteListByIdBusProvider.pending]: (state) => {
        state.isLoadingRoute = true
      },
      [getRouteListByIdBusProvider.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataRoute = action.payload.data
        state.isLoadingRoute = false
      },
      [getRouteListByIdBusProvider.rejected]: (state) => {
        state.isLoadingRoute = false
      },
      [getRouteById.pending]: (state) => {
        state.isLoadingRoute = true
      },
      [getRouteById.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataRouteById = action.payload.data
        state.isLoadingRoute = false
      },
      [getRouteById.rejected]: (state) => {
        state.isLoadingRoute = false
      },
      [getRouteStationById.pending]: (state) => {
        state.isLoadingRoute = true
      },
      [getRouteStationById.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataRouteStationById = action.payload.data
        state.isLoadingRoute = false
      },
      [getRouteStationById.rejected]: (state) => {
        state.isLoadingRoute = false
      },
      [getRouteStationByIdRoute.pending]: (state) => {
        state.isLoadingRoute = true
      },
      [getRouteStationByIdRoute.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataRouteStation = action.payload.data
        state.isLoadingRoute = false
      },
      [getRouteStationByIdRoute.rejected]: (state) => {
        state.isLoadingRoute = false
      },
      [getRouteByRegionalId.pending]: (state) => {
        state.isLoadingRoute = true
      },
      [getRouteByRegionalId.fulfilled]: (state, action) => {
        // console.log(action.payload)
        state.dataRouteByRegionalId = action.payload.data
        state.isLoadingRoute = false
      },
      [getRouteByRegionalId.rejected]: (state) => {
        state.isLoadingRoute = false
      },
      [postRoute.pending]: (state) => {
        state.isLoadingRoute = true
      },
      [postRoute.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.isOpenRoute = false
        state.isLoadingRoute = false
        state.stateFormRoute = ''
      },
      [postRoute.rejected]: (state) => {
        state.isLoadingRoute = false
      },
      [editRoute.pending]: (state) => {
        state.isLoadingRoute = true
      },
      [editRoute.fulfilled]: (state, action) => {
        state.isOpenRoute = false
        state.isLoadingRoute = false
        state.dataByIdRoute = []
        state.idRoute = 0
        state.stateFormRoute = ''
      },
      [editRoute.rejected]: (state) => {
        state.isLoadingRoute = false
      },
      [deleteRoute.pending]: (state) => {
        state.isLoadingRoute = true
      },
      [deleteRoute.fulfilled]: (state, action) => {
        state.isOpenRoute = false
        state.isLoadingRoute = false
        state.dataByIdRoute = []
        state.idRoute = 0
        state.stateFormRoute = ''
      },
      [deleteRoute.rejected]: (state) => {
        state.isLoadingRoute = false
      },
    }
});

export const {  openFormRoute,openRouteStation } = routeSlice.actions;

export default routeSlice.reducer;