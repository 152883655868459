import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, Media } from "reactstrap"
import SimpleBar from "simplebar-react"
import moment from 'moment';

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../../assets/images/users/avatar-4.jpg"

//i18n
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { useDispatch } from "react-redux"
import { updateStatusRead } from "../../../redux/action/notificationAction"

const LinkAku = styled.div` 
  background: ${(props) => props.isRead ? `#f6f6f6` : `white`} !important;
  &:hover {
    background: #f6f6f6 !important;
  }
`
function TimeAgo({ datetime, props }) {
  const [timeAgo, setTimeAgo] = useState('');
  const [infoTime, setInfoTime] = useState('');
  const convertLocal = datetime.split(' ')[0]+'T'+datetime.split(' ')[1]+'Z';
  const localDateTime = moment.utc(convertLocal).local();
  const newDateTime = localDateTime.format('YYYY-MM-DD HH:mm:ss')
  useEffect(() => {
    const timeDiff = new Date() - new Date(newDateTime);
    const minutesAgo = Math.floor(timeDiff / (1000 * 60));
    if (minutesAgo >= 24 * 60) {
      const daysAgo = Math.floor(minutesAgo / (24 * 60));
      setTimeAgo(`${daysAgo}`);
      setInfoTime('days a go');
    }
    else if (minutesAgo >= 60) {
      const hoursAgo = Math.floor(minutesAgo / 60);
      setTimeAgo(`${hoursAgo}`);
      setInfoTime('hours a go');
    }
    else {
      setTimeAgo(`${minutesAgo}`);
      setInfoTime('minutes a go')
    }
  }, [datetime]);

  return <span>{timeAgo} {props.t(infoTime)}</span>;
}
const NotificationDropdown = props => {
  const [counting, setCounting] = useState(0)
  const { dataNotif } = useSelector((state) => state.notification)
  const dispatch = useDispatch()
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  useEffect(()=>{
    if(dataNotif === '' ){
      setCounting(0)
    }else{
      let count = 0
      dataNotif.content.map((key, value)=>{
        if(!key.isRead){
          count = count+1
        }
      })
      setCounting(count)
    }
  },[dataNotif])
  const { user } = useSelector((state) => state.auth)
  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="mdi mdi-bell-outline"></i>
          <span className="badge rounded-pill bg-danger ">{counting}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0">{" "}{props.t("Notifications")} </h6>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {dataNotif === '' ? null : dataNotif.content.map((key, index)=>(
              <Link to="#" className="text-reset notification-item" onClick={()=>key.isRead ? '' : dispatch(updateStatusRead({id: key.oneSignalId, token: user.access_token}))}>
                <LinkAku className="d-flex align-items-start" isRead={key.isRead}>
                  <div className="flex-1">
                    <h6 className="mt-0 mb-1" style={{color:!key.isRead ? '' : 'rgb(139 139 139)' }}>
                      {key.title}
                    </h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1" style={{color:!key.isRead ? '' : 'rgb(139 139 139)' }}>
                        {key.body}
                      </p>
                      <p className="mb-0" style={{color:!key.isRead ? '' : 'rgb(139 139 139)' }}>
                        <i className="mdi mdi-clock-outline"></i>{" "}
                        <TimeAgo datetime={key.createdAt} props={props} />
                      </p>
                    </div>
                  </div>
                  {!key.isRead ? (
                    <div className="avatar-xs me-3" style={{height: '10px' , width: '10px'}}>
                      <span className="avatar-title bg-success rounded-circle font-size-5">
                      </span>
                    </div>
                  ): null}
                </LinkAku>
              </Link>
            ))}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 text-center"
              to="/event-log"
            ><i className="mdi mdi-arrow-right-circle me-1"></i>
              {" "}
              {props.t("View all")}{" "}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any
}