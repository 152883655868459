import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_BUS, API_MASTER } from '../../constants'
// import { openMessage } from '../slice/messageSlice'
// import { openForm } from '../slice/api/areaSlice'

export const getBusTracking = createAsyncThunk('bus-tracking', async (param, thunkAPI) => {
  // console.log(param)
  const respone = await fetch(
    `${API_BUS}/api/bus/position/log/route/web?idRoute=${param.idRoute}&idProvider=${param.idBusProvider}`,
    // `${API_MASTER}/api/map?routeId=13&providerId=8`,
    {
      method: 'get',
      headers: new Headers({
        Authorization: 'Bearer ' + param.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      }),
    },
  )
  const responeResult = await respone.json()
  // console.log("as")
  return responeResult
})