import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"

import { Row, Col, Alert, Container, Label, Input, FormFeedback, Form } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, Redirect } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser, apiError, socialLogin } from "../../store/actions"

// import images
import logo from "../../assets/images/logo-sm-dark.png"
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'

import * as Yup from "yup";
import { login } from '../../redux/slice/authSlice'
import styled from 'styled-components'
import { openMessage } from '../../redux/slice/messageSlice'
import logoSm from "../../assets/leoni/navicon.png";

const Toast = styled.div`
  visibility: hidden;
  position: absolute;
  right: 10px;
  top: 15px;
  z-index: 1100;
  background: ${(props) => props.color};
  padding: 15px;
  border-radius: 7px;
  border-left: 7px solid white;
  width: 250px;
  transition: visibility 0s, opacity 0.5s linear;
  &.active {
    visibility: visible;
  }
`
const ToastBody = styled.div`
  color: white;
`
const ToastIcon = styled.i`
font-size: 30px;
`
const Login = (props) => {
  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  // handleValidSubmit
  const dispatch = useDispatch();

  const [userLogin, setUserLogin] = useState([]);

  const { user } = useSelector((state) => state.auth);

  // useEffect(() => {
  //   if (user && user) {
  //     setUserLogin({
  //       username: user.username,
  //       password: user.password,
  //     });
  //   }
  // }, [user]);

      // console.log(values)
      // dispatch(login({username: 'admin', password: 'pass'}));

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: userLogin.username ,
      password: userLogin.password ,
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your User Name"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      // console.log(values)
      dispatch(login({username: values.username, password: values.password}));
    },
  });

  const { message, type, isOpenMessage } = useSelector((state) => state.message)
  const colorToast = type === 'success' ? 'rgba(89, 214, 103, 0.9)' : 'rgba(229, 33, 33, 0.9)'
  useEffect(() => {
    if (isOpenMessage) {
      setTimeout(function () {
        dispatch(openMessage({ message: '', type: '', open: false }))
      }, 5000)
    }
  }, [isOpenMessage])
  if (localStorage.getItem("user")) {
    return <Redirect exact from="/login" to="/" />;
  } 
  return (
    <React.Fragment>

      <Toast className={isOpenMessage ? 'active' : ''} color={colorToast}>
        <ToastBody id="toast-notif">
          <div className='row'>
            <Col 
              xs="3"
              md="3"
              sm="3"
              lg="3"
              style={{ textAlign: 'center', alignSelf: 'center' }}
              >

            {type === 'success' 
              ? (<ToastIcon className="mdi mdi-account-check" style={{fontSize: '30px'}}></ToastIcon>) 
              : (<ToastIcon className="mdi mdi-account-alert"></ToastIcon>)
            }
            </Col>
            <Col 
              xs="9"
              md="9"
              sm="9"
              lg="9"
              style={{ textAlign: 'center', alignSelf: 'center' }}
              >
                <div className='row'>
                  <Col xs="12" md="12" sm="12" lg="12">
                    <span style={{ fontWeight: 600, fontSize: 14 }}>{type.toUpperCase()}</span>
                  </Col>
                  <Col xs="12" md="12" sm="12" lg="12">
                    <span style={{ fontWeight: 400, fontSize: 12 }}>{message}</span>
                  </Col>
                </div>
            </Col>
          </div>
        </ToastBody>
      </Toast>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="card overflow-hidden">
                <div className="bg-login text-center">
                  <div className="bg-login-overlay"></div>
                  <div className="position-relative">
                    <h5 className="text-white font-size-20">Welcome Back !</h5>
                    <p className="text-white-50 mb-0">Sign in to continue.</p>
                    <Link to="/" className="logo logo-admin mt-4">
                      <img src={logoSm} alt="" height="30" />
                    </Link>
                  </div>
                </div>
                <div className="card-body pt-5">
                  <div className="p-2">
                  <Form
                      className="mt-4"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                      action="#"
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="username">
                          Username
                        </Label>
                        <Input
                          name="username"
                          className="form-control"
                          placeholder="Enter Username"
                          type="username"
                          id="username"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username && validation.errors.username
                              ? true
                              : false
                          }
                        />
                        {validation.touched.username && validation.errors.username ? (
                          <FormFeedback type="invalid">
                            {validation.errors.username}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="userpassword">
                          Password
                        </Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          className="form-control"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                      <div className="mt-4 text-center d-none">
                        <Link to="/forgot-password" className="text-muted"><i
                          className="mdi mdi-lock me-1"></i> Forgot your password?</Link>
                      </div>
                    </Form>

                  </div>
                </div>
              </div>
              <div className="mt-5 text-center">
                <p className='d-none'>Don't have an account ? <Link to="/register"
                  className="fw-medium text-primary"> Signup now </Link> </p>
                <p className='d-none'>© {new Date().getFullYear()} Qovex. Crafted with <i
                  className="mdi mdi-heart text-danger"></i> by Themesbrand
                        </p>
                <p>© {new Date().getFullYear()} Panon.</p>
              </div>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { user, isLoggedIn } = state.auth
  return { user, isLoggedIn }
}

export default withRouter(
  connect(mapStateToProps, { })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}