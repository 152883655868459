import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Row } from 'reactstrap'
import DatatableComponent from '../../components/Content/Datatable'
import Datatable from '../../components/Content/Datatable'
import ModalComponent from '../../components/Content/Modal'
import { getEmployeeSchedule, getWeeklySchedulePage } from '../../redux/action/master/weeklyScheduleAction'
import { openExpand, openFormWeeklySchedule } from '../../redux/slice/master/weeklyScheduleSlice'
import FormWeeklySchedule from './util/WeeklySchedule/FormWeeklySchedule'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import styled, { css, keyframes } from 'styled-components'
import EmployeeSchedule from './util/WeeklySchedule/EmployeeSchedule'
import FormNewSchedule from './util/WeeklySchedule/FormNewSchedule'
import UploadFile from './util/WeeklySchedule/UploadFile'
import { useTranslation } from 'react-i18next'

const rotateArrow = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
`;
const ArrowButton = styled.button`
background: transparent;
border: 0;
width: 100%;
height: 30px;
transition: transform 0.3s ease;

&:hover {
    background: #e6e6e6;
    transform: scale(1.1);
}
&.active{
    animation: ${rotateArrow} 0.3s forwards;
    transform: rotate(90deg);
}
`
const NewWeeklySchedule = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { isOpenWeeklySchedule, dataWeeklySchedule ,dataWeeklyScheduleById, dataEmployeeScheduleById, isLoadingWeeklySchedule, idWeeklySchedule } = useSelector((state)=>state.weeklySchedule)
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState('')
  const [select, setSelect] = useState('')
  const [filter, setFilter] = useState('')
  const [activeArrow, setActiveArrow] = useState(false)
  const countPerPage = 10
  const {t} = useTranslation()
  const [isOpenUpload, setOpenUpload] = useState(false)
  const openHandler = () => {
      setOpenUpload(!isOpenUpload)
  }

  const handleOpenModal = async (isopen, type, data, id) =>{
    dispatch(openFormWeeklySchedule({open: isopen, type: type, data: data, id: id}))
    await dispatch(getEmployeeSchedule({ id: id, token: user.access_token }))
  }

  const beforeExpand = async (data, id, dataRow) => {

    let buttonArrow = document.getElementById('button-'+id)
    if (!isExpanded) {
      dispatch(openExpand({ open: true, id: id, data: data, dataRow: dataRow }))
      await dispatch(getEmployeeSchedule({ id: id, token: user.access_token }))
      buttonArrow.classList.add('active');
      // await dispatch(getCorrectiveByIdTicket({ id: id, token: user.access_token }))
      // await dispatch(getTimeToResponseByTicketId({ id: id, token: user.access_token }))
      setIsExpanded(!isExpanded)
    } else {
      document.getElementById('beforeExpand').appendChild(document.getElementById('coba'))
      const elements = document.getElementsByClassName('expanded')
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0])
      }
      setIsExpanded(false)

      let buttonArrowBefore = document.getElementById('button-'+idWeeklySchedule)   
      buttonArrowBefore.classList.remove('active');
      if (idWeeklySchedule === id) {
        dispatch(openExpand({ open: false }))
      } else {
        await dispatch(getEmployeeSchedule({ id: id, token: user.access_token }))
        dispatch(openExpand({ open: true, id: id, data: data, dataRow: dataRow }))
        setIsExpanded(true)
        buttonArrow.classList.add('active');
      }
      // dispatch(openExpand({ state: 'edit', open: false, id: id, data: data }))
    }
    // setExpandedRows(!expandedRows)
  }
  const expandRows = (id) => {
    let rowSelected = document.getElementById('row-' + id)
    let node = document.createElement('div')
    node.className = 'expanded'

    // console.log(node)
    const row = node.appendChild(document.getElementById('coba'))
    // console.log(row)
    insertAfter(node, rowSelected)
  }
  const insertAfter = (newNode, existingNode) => {
    existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling)
  }
  useEffect(() => {
    // console.log(expandedRows)
    if (isExpanded) {
      expandRows(idWeeklySchedule)
    }
  }, [isExpanded])
  const columns = [
    {
      name: '', 
      cell: (row)=>(
        <ArrowButton
          // onClick={()=>{
          //   // dispatch(openFormWeeklySchedule({open: !isOpenWeeklySchedule, type: 'edit',data: row, id: row.id}))
          //   handleClick()
          // }}
          id={'button-'+row.id}
          onClick={() => beforeExpand(row.schedule, row.id, row)}
          key={row.id}
          >
            <i className="fas fa-angle-right"></i>
        </ArrowButton> 
      ),
      width: '60px'
    },
    {
      name: <>{t("Week")}/{t("Year")}</>,
      selector: (row) => row.week+'/'+row.year,
    },
    {
        name: <>{t("Start Date")}</>,
        selector: (row) => row.localDate,
    },
    {
        name: <>{t("Start Day")}</>,
        selector: (row) => row.dayOfWeek === 1 ? t('MONDAY') : row.dayOfWeek === 2 ? t('TUESDAY'): row.dayOfWeek === 3 ? t('WEDNESDAY'): row.dayOfWeek === 4 ? t('THURSDAY'): row.dayOfWeek === 5 ? t('FRIDAY'): row.dayOfWeek === 6 ? t('SATURDAY') : t('SUNDAY'),
    },
    {
        name: <>{t("Employee")}</>,
        selector: (row) => row.ofEmployees,
    },
    {
      name: <>{t("Action")}</>,
      cell: (row) => (
        <div style={{ padding: '10px 0' }}>
          <button 
            className='btn btn-outline-secondary btn-sm' 
            onClick={()=>{ handleOpenModal(!isOpenWeeklySchedule, 'edit', row, row.id)
            }} 
            style={{marginRight: 5}}>
              {t("Edit")}
          </button>
        </div>
      ),
    },
  ]
  const { user } = useSelector((state) => state.auth)
  const param = {
    token: user.access_token,
    sort: sort,
    size: countPerPage,
    select: select,
    filter: filter,
    page: page,
  }

  useEffect(()=>{
    dispatch(getWeeklySchedulePage(param))
  },[page, filter, select, sort])
  return (
    <React.Fragment>
        <div className="page-content">

            <Breadcrumbs title={t("Master Data")} breadcrumbItem={t("Weekly Schedule")} />
            <Row>
                {/* <div className="col-6 mb-3">
                    <button className="btn btn-primary waves-effect waves-light"
                        onClick={()=>{dispatch(openFormWeeklySchedule({open: !isOpenWeeklySchedule, type: 'add'}))}}
                        data-toggle="modal"
                        data-target=".bs-example-modal-center">
                          + Add Data
                    </button>
                </div> */}
                <div className="col-6 mb-3">
                    <button className="btn btn-primary waves-effect waves-light" onClick={openHandler} style={{ marginLeft: '5px' }}>Upload Data</button>
                </div>

                {isLoadingWeeklySchedule ?
                (
                <div className="col-6 mb-3" style={{textAlign: 'right'}}>
                  <div>
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
                ) : null}
                <div className="col-12">
                  <UploadFile open={isOpenUpload} param={param} loading={isLoadingWeeklySchedule} />
                </div>
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            {/* ( */}
                            <DatatableComponent  
                                data={dataWeeklySchedule.content}
                                page={page}
                                countPerPage={countPerPage}
                                totalElements={dataWeeklySchedule.totalElements}
                                columns={columns}
                                setPage={setPage}
                                columnFilter
                                columnSorter
                                pagination
                                tableProps={{
                                    hover: true,
                                }} 
                            />
                        </div>
                    </div>
                </div>
            </Row>
        </div>
        <FormNewSchedule isOpen={isOpenWeeklySchedule} onClose={()=> dispatch(openFormWeeklySchedule({open: !isOpenWeeklySchedule, type: ''}))} param={param} />
        <div id="beforeExpand" style={{ display: 'none' }}>
          <EmployeeSchedule
            data={dataEmployeeScheduleById}
            expanded={isExpanded}
          />
        </div>
    </React.Fragment>

  )
}

export default NewWeeklySchedule