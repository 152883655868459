import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ModalComponent from '../../../../components/Content/Modal'
import { openFormArea } from '../../../../redux/slice/master/areaSlice'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap'
import { useDispatch } from 'react-redux'
import Alert from '../../../../components/Content/Alert'
import { useEffect } from 'react'
import { getFactoryById, getFactoryList } from '../../../../redux/action/master/factoryAction'
import { deleteArea, editArea, postArea } from '../../../../redux/action/master/areaAction'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

const FormArea = ({param}) => {
    const { isOpenArea, idArea, dataAreaById, stateFormArea } = useSelector((state)=>state.area)
    const {t} = useTranslation()
    const { dataFactory, dataFactoryById } = useSelector((state)=>state.factory)
    const dispatch = useDispatch()
    const handleFormSubmit = (values) =>{
        // console.log(values)
        if(stateFormArea === 'add'){
            param.data = values
            dispatch(postArea(param))
        }else if(stateFormArea === 'edit'){
            param.data = values
            param.id = idArea
            dispatch(editArea(param))
        }else{
            param.id = idArea
            dispatch(deleteArea(param))
        }
    }
    const [idFactory, setFactory] = useState()
    const [datasFactory, setFactorys] = useState([])
    const initialValues = {
        name: idArea ==='' ? '': dataAreaById.name,
        descr: idArea ==='' ? '': dataAreaById.descr,
        idFactory: idArea ==='' ? '': dataAreaById.idFactory,
    }
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required!'),
        descr: Yup.string().required('Description is required!'),
        idFactory: Yup.string().required('Factory is required!'),
    })
    useEffect(()=>{
        dispatch(getFactoryList(param))
    },[])

    useEffect(() => {
        if (dataFactory.length > 0) {
            let newArr = dataFactory.map(({ id, name }) => ({ value: id, label: name }))
            // console.log(newArr)
            setFactorys(newArr)
        }
    },[dataFactory])
    useEffect(() => {
        if(stateFormArea === 'edit'){
            param.id = dataAreaById.idFactory
            dispatch(getFactoryById(param))
        }
    },[isOpenArea])
    useEffect(() => {
        if (dataFactoryById !== '') {
            if(stateFormArea === 'edit'){
                setFactory({label:dataFactoryById.name, value: dataFactoryById.id})
            }
        }
    },[dataFactoryById])
    return stateFormArea === 'delete' ? (
        <Alert 
            isOpen={isOpenArea} 
            action={handleFormSubmit}
            text={t("Are You Sure for Delete This Data ?")} 
            toggle={()=>{dispatch(openFormArea({open: !isOpenArea, type: ''}))}} 
            close={()=>{dispatch(openFormArea({open: !isOpenArea, type: ''}))}} />
    ) : (
        <ModalComponent 
            isOpen={isOpenArea} 
            close={()=>{dispatch(openFormArea({open: !isOpenArea, type: ''}))}}
            title={"Form Data "+t("Area")}
            idForm="area-form"
            toggle={()=>{dispatch(openFormArea({open: true, type: 'add'}))}}
        >
            <Formik 
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit} id="area-form">
                        <FormGroup className='mb-3'>
                            <Label>{t("Name")}</Label>
                            <Input
                                type="text"
                                id="name"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={touched.name && !!errors.name}
                            />
                            <FormFeedback>{errors.name}</FormFeedback>
                        </FormGroup>
                        <FormGroup className='mb-3'>
                            <Label>{t("Description")}</Label>
                            <Input
                                type="text"
                                id="descr"
                                name="descr"
                                value={values.descr}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={touched.descr && !!errors.descr}
                            />
                            <FormFeedback>{errors.descr}</FormFeedback>
                        </FormGroup>

                        <FormGroup className='mb-3'>
                            <Label>{t("Factory")}</Label>
                            <Select
                                styles={{
                                    control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor:
                                        touched.factoryId && errors.factoryId
                                        ? 'red' : 'rgba(0,0,0,.24)',
                                    width: '100%',
                                    appearance: 'auto',
                                    }),
                                }}
                                onChange={(e)=>{
                                    handleChange({
                                        target: { name: 'idFactory', value: e.value },
                                    })
                                    setFactory(e)
                                    // console.log(labelRuas)
                                    // console.log(e.value)
                                    // handleChange(e)
                                }}
                                name="idFactory"
                                id="idFactory"
                                value={idFactory}
                                options={datasFactory}
                            />
                        </FormGroup>   
                        
                    </form>
                )}
            </Formik>
        </ModalComponent>
    )
}

export default FormArea