import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Row } from 'reactstrap'
import DatatableComponent from '../../../components/Content/Datatable'
import { openModalRole } from '../../../redux/slice/master/roleSlice'
import FormRole from './FormRole'
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getRolePage } from '../../../redux/action/master/roleAction'
import { useTranslation } from 'react-i18next'

const Role = () => {
  const { isOpenRole, dataRole, isLoadingRole } = useSelector((state) => state.role)
  const dispatch = useDispatch()

  const {t} = useTranslation()
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState('')
  const [select, setSelect] = useState('')
  const [filter, setFilter] = useState('')
  const countPerPage = 10
  const columns = [
    {
      name: (<>{t("Name")}</>),
      selector: (row) => row.name,
    },
    {
      name: (<>{t("User Count")}</>),
      selector: (row) => row.userIds.length,
    },
    {
      name: (<>{t("Action")}</>),
      cell: (row) => (
        <div style={{ padding: '10px 0' }}>
          <button
            className='btn btn-outline-secondary btn-sm'
            onClick={() => {
              dispatch(openModalRole({ open: !isOpenRole, type: 'edit', data: row, id: row.id }))
            }}
            style={{ marginRight: 5 }}>
            {t("Edit")}
          </button>
          {/* <button
            className='btn btn-danger btn-sm'
            onClick={()=>{
              dispatch(openModalRole({open: !isOpenRole, type: 'delete',data: row, id: row.id}))
            }}
            style={{marginRight: 5}}>
              Delete
            </button> */}
        </div>
      ),
    },
  ]
  const { user } = useSelector((state) => state.auth)
  const param = {
    token: user.access_token,
    sort: sort,
    size: countPerPage,
    select: select,
    filter: filter,
    page: page,
  }

  useEffect(() => {
    dispatch(getRolePage(param))
  }, [page, filter, select, sort])

  return (
    <React.Fragment>
      <div className="page-content">
        {/* Render Breadcrumb */}
        <Breadcrumbs title={t("Master Data")} breadcrumbItem={t("Role")} />
        {/* Content */}
        <Row>
          {/* <div className="col-6 mb-3">
            <button className="btn btn-primary waves-effect waves-light"
              onClick={() => { dispatch(openModalRole({ open: !isOpenRole, type: 'add' })) }}
              data-toggle="modal"
              data-target=".bs-example-modal-center">
              + {t("Add Data")}
            </button>
          </div> */}
          {isLoadingRole ?
            (
              <div className="col-12 mb-3" style={{ textAlign: 'right' }}>
                <div>
                  <div className="spinner-grow text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div className="spinner-grow text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div className="spinner-grow text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : null}
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <DatatableComponent
                  data={dataRole}
                  page={page}
                  countPerPage={countPerPage}
                  totalElements={dataRole.length}
                  columns={columns}
                  setPage={setPage}
                  columnFilter
                  columnSorter
                  pagination
                  tableProps={{
                    hover: true,
                  }}
                />
              </div>
            </div>
          </div>
        </Row>
      </div>
      <FormRole isOpen={isOpenRole} param={param} onClose={() => dispatch(openModalRole({ open: !isOpenRole, type: '' }))} />
    </React.Fragment>

  )
}

export default Role