import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_MASTER } from '../../../constants'
import { openMessage } from '../../slice/messageSlice'
// import { openMessage } from '../slice/messageSlice'
// import { openForm } from '../slice/api/factorySlice'

export const getFactoryPage = createAsyncThunk('factory/page', async (param, thunkAPI) => {
  const respone = await fetch(
    `${API_MASTER}/api/factory/page?page=${param.page}&size=${param.size}&name=${param.filter[0].name}&location=${param.filter[0].location}&phone=${param.filter[0].phone}`,
    {
      method: 'get',
      headers: new Headers({
        Authorization: 'Bearer ' + param.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
      }),
    },
  )
  const responeResult = await respone.json()
  // console.log("as")
  return responeResult
})
export const getFactoryById = createAsyncThunk('factory/byId', async (param, thunkAPI) => {
  // console.log(param);
  const response = await fetch(`${API_MASTER}/api/factory/${param.id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + param.token,
    },
  })
  if (response.status === 200) {
    const responseJson = await response.json()
    return responseJson
  }
})
export const getFactoryByRegionalId = createAsyncThunk('factory/regionalId', async (param, thunkAPI) => {
  // console.log(param);
  const response = await fetch(`${API_MASTER}/api/factory?regionalId=${param.regional}`, {
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
  })
  if (response.status === 200) {
    const responseJson = await response.json()
    return responseJson
  }
})
export const getFactoryList = createAsyncThunk('factory/list', async (param, thunkAPI) => {
  const respone = await fetch(`${API_MASTER}/api/factory/`, {
    method: 'get',
    headers: new Headers({
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    }),
  })
  const responeResult = await respone.json()
  // console.log("as")
  return responeResult
})
export const postFactory = createAsyncThunk('factory/post', async (param, thunkAPI) => {
  // console.log(param);
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
    body: JSON.stringify(param.data),
  }
  let response = await fetch(`${API_MASTER}/api/factory`, requestOptions)
  if (response.status === 200) {
    // console.log(response.status)
    const responseJson = await response.json()

    thunkAPI.dispatch(
      openMessage({
        message: 'Success Insert Factory!',
        type: 'success',
        open: true,
      }),
    )
    thunkAPI.dispatch(getFactoryPage(param))
    return responseJson
  }
  if (response.status === 400) {
    thunkAPI.dispatch(openMessage({ message: 'Insert Factory Failed', type: 'danger', open: true }))
  }
})
export const editFactory = createAsyncThunk('factory/update', async (param, thunkAPI) => {
  // console.log(param);
  const requestOptions = {
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
    body: JSON.stringify(param.data),
  }
  let response = await fetch(`${API_MASTER}/api/factory/${param.id}`, requestOptions)
  if (response.status === 200) {
    // thunkAPI.dispatch(
    //   openMessage({ message: 'Edit Site Successfull', type: 'success', open: true })
    // );
    thunkAPI.dispatch(getFactoryPage(param))
  }
  // .then(getApi())
})
export const deleteFactory = createAsyncThunk('factory/delete', async (param, thunkAPI) => {
  // console.log(param);
  const requestOptions = {
    method: 'Delete',
    headers: {
      Authorization: 'Bearer ' + param.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
    body: JSON.stringify(param.data),
  }
  let response = await fetch(`${API_MASTER}/api/factory/${param.id}`, requestOptions)
  if (response.status === 200) {
    // thunkAPI.dispatch(toggleModal({ state: 'delete', open: false, id: '', data: [] }));
    // thunkAPI.dispatch(
    //   openMessage({ message: 'Delete Site Successfull', type: 'success', open: true })
    // );
    thunkAPI.dispatch(getFactoryPage(param))
  }
  // .then(getApi())
})
