import React from "react"
import { Redirect } from "react-router-dom"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Master Data pages
import MasterBusProvider from "../pages/Master/BusProvider"
import MasterBus from "../pages/Master/Bus"
import MasterDevice from "../pages/Master/Device"
import MasterArea from "../pages/Master/Area"
import MasterFactory from "../pages/Master/Factory"
import MasterRoute from "../pages/Master/Route"
import MasterShift from "../pages/Master/Shift"
import MasterStation from "../pages/Master/Station"
import MasterWeeklySchedule from "../pages/Master/WeeklySchedule"
import NewWeeklySchedule from "../pages/Master/NewWeeklySchedule"

// User Management pages
import User from "../pages/UserManagement/User/User"
import Role from "../pages/UserManagement/Role/Role"
import Enrollment from "../pages/UserManagement/Enrollment/Enrollment";
import Buslog from "../pages/Buslog";
import EventLog from "../pages/EventLog";
import BusTracking from "../pages/BusTracking/BusTracking";
import UnderConstruction from "../pages/UnderConstruction"

const userRoutes = [
  { path: "/bus-tracking", component: BusTracking },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/bus-tracking" /> },
]
const adminRoutes = [
  { path: "/dashboard", component: Dashboard },

  // Master Data
  { path: "/master/bus-provider", component: MasterBusProvider },
  { path: "/master/bus", component: MasterBus },
  { path: "/master/device", component: MasterDevice },
  { path: "/master/area", component: MasterArea },
  { path: "/master/factory", component: MasterFactory },
  { path: "/master/route", component: MasterRoute },
  { path: "/master/shift", component: MasterShift },
  { path: "/master/station", component: MasterStation },
  // { path: "/master/weekly-schedule", component: MasterWeeklySchedule },
  { path: "/master/weekly-schedule", component: NewWeeklySchedule },

  // User Management
  { path: "/user-mgt/user", component: User },
  { path: "/user-mgt/role", component: Role },
  { path: "/user-mgt/enrollment", component: Enrollment },

  //busLog
  { path: "/bus-log", component: Buslog },
  { path: "/bus-tracking", component: BusTracking },
  { path: "/event-log", component: EventLog },
  { path: "/under-construct", component: UnderConstruction },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]
const staffRoutes = [
  { path: "/dashboard", component: Dashboard },
  // User Management
  { path: "/user-mgt/user", component: User },
  { path: "/user-mgt/role", component: Role },
  { path: "/user-mgt/enrollment", component: Enrollment },
  //busLog
  { path: "/bus-log", component: Buslog },
  { path: "/bus-tracking", component: BusTracking },
  { path: "/event-log", component: EventLog },
  { path: "/under-construct", component: UnderConstruction },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]


const authRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

]

export { userRoutes, adminRoutes, staffRoutes, authRoutes }