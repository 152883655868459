import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button, Card, CardBody, CardHeader, Collapse } from 'reactstrap'
import { downloadTemplateEmployee, uploadEmployee } from '../../../redux/action/master/employeeAction'

const UploadData = ({open, param, loading}) => {
    const dispatch = useDispatch()
    const [file, setFile] = useState()
    const {t} = useTranslation()
    const changeFile = (e) => {
        setFile(e.target.files[0])
    }
    const uploadAction= ()=> {
        param.data = {file: file}
        dispatch(uploadEmployee(param))
    }
    return (
        <div>
            <Collapse isOpen={open}>
            <Card>
                <CardHeader>
                    <div className="row">
                        <div className="col-md-6 col-xs-6 col-lg-6 col sm-6" style={{alignSelf:'center'}}>
                            <h6>{t('Upload')} {t('RFID')}</h6>
                        </div>
                        <div className="col-md-6 col-xs-6 col-lg-6 col sm-6" style={{textAlign: 'right'}}>
                            <button className="btn btn-success" onClick={()=>dispatch(downloadTemplateEmployee(param))}>
                            {t('Download')} Template
                            </button>
                        </div>
                    </div>
                </CardHeader>
                <CardBody>
                    <div className="row">
                        <div className="col-md-10 col-xs-10 col-lg-10 col sm-10">
                            <input type="file" className="form-control" onChange={(e)=> changeFile(e)} />
                        </div>
                        <div className="col-md-2 col-xs-2 col-lg-2 col sm-2">
                        {loading ? (
                            <div>
                                <div className="spinner-grow text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                <div className="spinner-grow text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                <div className="spinner-grow text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            <button className="btn btn-danger" style={{width: '100%'}} onClick={()=> uploadAction()} >
                            {t('Upload')}
                            </button>
                        )}
                        </div>
                    </div>
                </CardBody>
            </Card>
            </Collapse>
        </div>
    )
}

export default UploadData