import { createSlice } from "@reduxjs/toolkit";
import { deleteFactory, editFactory, getFactoryById, getFactoryByRegionalId, getFactoryList, getFactoryPage, postFactory } from "../../action/master/factoryAction";

const initialState = {
    dataFactory: [],
    isOpenFactory: false,
    stateFormFactory: '',
    dataFactoryById: '',
    idFactory: '',
    isLoadingFactory: false,
};
const factorySlice = createSlice({
    name: "factory",
    initialState,
    reducers: {
        openFormFactory: (state, action) => {
            if(action.payload.open){
                if(action.payload.type !== 'add'){
                    state.dataFactoryById = action.payload.data
                    state.idFactory = action.payload.id
                }
            }else{
                state.dataFactoryById = ''
                state.idFactory = ''
            }
            state.stateFormFactory = action.payload.type
            state.isOpenFactory = action.payload.open;
        },
    },
    extraReducers: {
      [getFactoryPage.pending]: (state) => {
        state.isLoadingFactory = true
      },
      [getFactoryPage.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataFactory = action.payload.data
        state.isLoadingFactory = false
      },
      [getFactoryPage.rejected]: (state) => {
        state.isLoadingFactory = false
      },
      [getFactoryList.pending]: (state) => {
        state.isLoadingFactory = true
      },
      [getFactoryList.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataFactory = action.payload.data
        state.isLoadingFactory = false
      },
      [getFactoryList.rejected]: (state) => {
        state.isLoadingFactory = false
      },
      [getFactoryById.pending]: (state) => {
        state.isLoadingFactory = true
      },
      [getFactoryById.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.dataFactoryById = action.payload.data
        state.isLoadingFactory = false
      },
      [getFactoryById.rejected]: (state) => {
        state.isLoadingFactory = false
      },
      [getFactoryByRegionalId.pending]: (state) => {
        state.isLoadingFactory = true
      },
      [getFactoryByRegionalId.fulfilled]: (state, action) => {
        // console.log(action.payload)
        state.dataFactoryByRegionalId = action.payload.data
        state.isLoadingFactory = false
      },
      [getFactoryByRegionalId.rejected]: (state) => {
        state.isLoadingFactory = false
      },
      [postFactory.pending]: (state) => {
        state.isLoadingFactory = true
      },
      [postFactory.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.isOpenFactory = false
        state.isLoadingFactory = false
        state.stateFormFactory = ''
      },
      [postFactory.rejected]: (state) => {
        state.isLoadingFactory = false
      },
      [editFactory.pending]: (state) => {
        state.isLoadingFactory = true
      },
      [editFactory.fulfilled]: (state, action) => {
        state.isOpenFactory = false
        state.isLoadingFactory = false
        state.dataByIdFactory = []
        state.idFactory = 0
        state.stateFormFactory = ''
      },
      [editFactory.rejected]: (state) => {
        state.isLoadingFactory = false
      },
      [deleteFactory.pending]: (state) => {
        state.isLoadingFactory = true
      },
      [deleteFactory.fulfilled]: (state, action) => {
        state.isOpenFactory = false
        state.isLoadingFactory = false
        state.dataByIdFactory = []
        state.idFactory = 0
        state.stateFormFactory = ''
      },
      [deleteFactory.rejected]: (state) => {
        state.isLoadingFactory = false
      },
    }
});

export const {  openFormFactory } = factorySlice.actions;

export default factorySlice.reducer;