import {createAsyncThunk} from "@reduxjs/toolkit";
import {API_MASTER} from "../../../constants";
import {openMessage} from "../../slice/messageSlice";

export const getEnrollmentPage = createAsyncThunk('enrollment/page', async (param) => {
    const response = await fetch(`${API_MASTER}/api/enrollment/page?page=${param.page}&size=${param.size}&sort=${param.select},${param.sort}&${param.select}=${param.filter}`,
        {
            method: 'GET',
            headers: new Headers({
                Authorization: 'Bearer ' + param.token,
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': 'true',
            })
        }
    )
    const result = await response.json();
    return result;
});
export const getEnrollmentById = createAsyncThunk('enrollment/byId', async (param) => {
    const response = await fetch(`${API_MASTER}/api/enrollment/${param.id}`,
        {
            method: 'GET',
            headers: new Headers({
                Authorization: 'Bearer ' + param.token,
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': 'true',
            })
        }
    )
    if (response.status === 200) {
        const result = await response.json();
        return result;
    }
});
export const getEnrollmentList = createAsyncThunk('enrollment/list', async (param) => {
    const response = await fetch(`${API_MASTER}/api/enrollment/`,
        {
            method: 'GET',
            headers: new Headers({
                Authorization: 'Bearer ' + param.token,
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': 'true',
            })
        }
    )
    if (response.status === 200) {
        const result = await response.json();
        return result;
    }
});
export const createEnrollment = createAsyncThunk('enrollment/create', async (param, thunkAPI) => {
    const options = {
        method: 'POST',
        headers: new Headers({
            Authorization: 'Bearer ' + param.token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
        }),
        body: JSON.stringify(param.data),
    }
    let response = await fetch(`${API_MASTER}/api/enrollment/`, options)
    if (response.status === 200) {
        const result = await response.json();
        thunkAPI.dispatch(
            openMessage({
                message: 'Success Insert Enrollment!',
                type: 'success',
                open: true
            })
        );
        thunkAPI.dispatch(getEnrollmentPage(param));
        return result;
    }
    if (response.status === 400) {
        thunkAPI.dispatch(
            openMessage({
                message: 'Insert Enrollment Failed',
                type: 'danger',
                open: true
            })
        );
    }
});
export const editEnrollment = createAsyncThunk('enrollment/update', async (param, thunkAPI) => {
    const options = {
        method: 'PUT',
        headers: new Headers({
            Authorization: 'Bearer ' + param.token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
        }),
        body: JSON.stringify(param.data),
    }
    let response = await fetch(`${API_MASTER}/api/enrollment/${param.id}`, options)
    if (response.status === 200) {
        const result = await response.json();
        thunkAPI.dispatch(
            openMessage({
                message: 'Success Edit Enrollment!',
                type: 'success',
                open: true
            })
        );
        thunkAPI.dispatch(getEnrollmentPage(param));
        return result;
    }
    if (response.status === 400) {
        thunkAPI.dispatch(
            openMessage({
                message: 'Edit Enrollment Failed',
                type: 'danger',
                open: true
            })
        );
    }
});
export const deleteEnrollment = createAsyncThunk('enrollment/delete', async (param, thunkAPI) => {
    const options = {
        method: 'DELETE',
        headers: new Headers({
            Authorization: 'Bearer ' + param.token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
        }),
        body: JSON.stringify(param.data),
    }
    let response = await fetch(`${API_MASTER}/api/enrollment/${param.id}`, options)
    if (response.status === 200) {
        const result = await response.json();
        thunkAPI.dispatch(
            openMessage({
                message: 'Success Delete Enrollment!',
                type: 'success',
                open: true
            })
        );
        thunkAPI.dispatch(getEnrollmentPage(param));
        return result;
    }
    if (response.status === 400) {
        thunkAPI.dispatch(
            openMessage({
                message: 'Delete Enrollment Failed',
                type: 'danger',
                open: true
            })
        );
    }
});