import { createSlice } from "@reduxjs/toolkit";
import { getBuslog, getListDetailEmployee } from "../action/busLogAction";

const initialState = {
    dataBuslog: [],
    dataDetailBuslog: [],
    isLoadingBuslog : false,
    isLoadingDetailBusLog: false
};
const busLogSlice = createSlice({
    name: "busLog",
    initialState,
    reducers: {
        closeDetail: (state, action) => {
            state.dataDetailBuslog = []
        },
    },
    extraReducers: {
        [getBuslog.pending]: (state) => {
            state.isLoadingBuslog   = true
        },
        [getBuslog.fulfilled]: (state, action) => {
            // console.log(action.payload);
            state.dataBuslog = action.payload.data
            state.isLoadingBuslog   = false
        },
        [getBuslog.rejected]: (state) => {
            state.isLoadingBuslog   = false
        },
        [getListDetailEmployee.pending]: (state) => {
            state.isLoadingDetailBuslog = true
        },
        [getListDetailEmployee.fulfilled]: (state, action) => {
            // console.log(action.payload);
            state.dataDetailBuslog = action.payload.data
            state.isLoadingDetailBuslog = false
        },
        [getListDetailEmployee.rejected]: (state) => {
            state.isLoadingDetailBuslog = false
        },
    }
});

export const { closeDetail } = busLogSlice.actions;

export default busLogSlice.reducer;