import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import Select from 'react-select'
import { deleteEmployee, editEmployee, postEmployee } from '../../../redux/action/master/employeeAction'
import { getFactoryById, getFactoryList } from '../../../redux/action/master/factoryAction'
import { openFormEmployee } from '../../../redux/slice/master/employeeSlice'
import Alert from '../../../components/Content/Alert'
import ModalComponent from '../../../components/Content/Modal'
import Wrapper from '../../../components/Content/Wrapper'
import { getRouteById, getRouteList, getRouteStationByIdRoute } from '../../../redux/action/master/routeAction'
import { getAreaById, getAreaList } from '../../../redux/action/master/areaAction'
import { getStationById, getStationList } from '../../../redux/action/master/stationAction'
import { set } from 'lodash'
import { useTranslation } from 'react-i18next'

const FormEnrollment = ({param}) => {
    const { isOpenEmployee, idEmployee, dataEmployeeById, stateFormEmployee } = useSelector((state)=>state.employee)
    const { dataRoute, dataRouteById, dataRouteStation } = useSelector((state)=>state.route)
    const { dataArea, dataAreaById } = useSelector((state)=>state.area)
    const { dataStation, dataStationById } = useSelector((state)=>state.station)
    const {t} = useTranslation()
    const [selected, setSelected] = useState([
        {
            gender: '', 
            bloodType: '', 
            maritalStatus: '', 
            position: '', 
            areaId: '',
            routeId: '',
            stationId: ''
        }
    ])
    const [dataSelect, setDataSelect] = useState([{dataRoute: [], dataArea: [], dataStation: []}])
    const dispatch = useDispatch()
    const handleFormSubmit = (values) =>{
        // console.log(values)
        if(stateFormEmployee === 'add'){
            param.data = values
            dispatch(postEmployee(param))
        }else if(stateFormEmployee === 'edit'){
            param.data = values
            param.id = idEmployee
            dispatch(editEmployee(param))
        }else{
            param.id = idEmployee
            dispatch(deleteEmployee(param))
        }
    }
    const initialValues = {
        firstname: idEmployee ==='' ? '': dataEmployeeById.firstname,
        lastname: idEmployee ==='' ? '': dataEmployeeById.lastname,
        maritalStatus: idEmployee ==='' ? '': dataEmployeeById.maritalStatus,
        position: idEmployee ==='' ? '': dataEmployeeById.position,
        email: idEmployee ==='' ? '': dataEmployeeById.email,
        phone: idEmployee ==='' ? '': dataEmployeeById.phone,
        routeId: idEmployee ==='' ? '': dataEmployeeById.routeId,
        gender: idEmployee ==='' ? '': dataEmployeeById.gender,
        stationId: idEmployee ==='' ? '': dataEmployeeById.stationId,
        areaId: idEmployee ==='' ? '': dataEmployeeById.areaId,
        birthDate: idEmployee ==='' ? '': dataEmployeeById.birthDate,
        bloodType: idEmployee ==='' ? '': dataEmployeeById.bloodType,
        nik: idEmployee ==='' ? '': dataEmployeeById.nik,
        idRFCard: idEmployee ==='' ? '': dataEmployeeById.idRFCard,
    }
    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required('Name is required!'),
        maritalStatus: Yup.string().required('Marital Status is required!'),
        email: Yup.string().required('Email is required!'),
        phone: Yup.string().required('Phone is required!'),
        birthDate: Yup.string().required('Birth Date is required!'),
        bloodType: Yup.string().required('Blood Type is required!'),
        nik: Yup.string().required('NIK is required!'),
        gender: Yup.string().required('Gender is required!'),
        routeId: Yup.string().required('Route is required!'),
        stationId: Yup.string().required('Station is required!'),
        areaId: Yup.string().required('Area is required!'),
    })
    useEffect(()=>{
        dispatch(getRouteList(param))
        dispatch(getAreaList(param))
        if(idEmployee !== ''){
            param.routeId = dataEmployeeById.routeId
            dispatch(getRouteStationByIdRoute(param))
        }
    },[])
    // console.log(selected)
    useEffect(() => {
        let arr = [...dataSelect]
        if (dataRoute.length > 0) {
            let newArr = dataRoute.map(({ id, name }) => ({ value: id, label: name }))
            // console.log(newArr)
            arr[0].dataRoute = newArr
        }
        if (dataArea.length > 0) {
            let newArr = dataArea.map(({ id, name }) => ({ value: id, label: name }))
            // console.log(newArr)
            arr[0].dataArea = newArr
        }
        if (dataRouteStation.length > 0) {
            let newArr = dataRouteStation.map(({ idStation, stationName }) => ({ value: idStation, label: stationName }))
            // console.log(newArr)
            arr[0].dataStation = newArr
            if(stateFormEmployee === 'edit'){
                let selectedArr = [...selected]
                let result = newArr.find(item => item.value === dataEmployeeById.stationId);
                selectedArr[0].stationId = result
                setSelected(selectedArr)
                // console.log(selectedArr)
            }
        }
        setDataSelect(arr)
    },[dataRoute,dataArea,dataRouteStation])
    useEffect(() => {
        if(stateFormEmployee === 'edit'){
            let arr = [...selected]
            param.id = dataEmployeeById.routeId
            dispatch(getRouteById(param))
            param.id = dataEmployeeById.areaId
            dispatch(getAreaById(param))
            param.routeId = dataEmployeeById.routeId
            dispatch(getRouteStationByIdRoute(param))
            arr[0].gender = dataEmployeeById.gender === 'M' ? {value: 'M', label: t("Male")} : {value: 'F', label: t("Female")}
            arr[0].maritalStatus = dataEmployeeById.maritalStatus === 'Single' ? {value: 'Single', label: t("Single")} : {value: 'Married', label: t("Married")}
            arr[0].bloodType = 
                dataEmployeeById.bloodType === 'AB' ? 
                {value: 'AB', label: 'AB'} 
                : dataEmployeeById.bloodType === 'A' ? 
                {value: 'A', label: 'A'} 
                : dataEmployeeById.bloodType === 'B' ? 
                {value: 'B', label: 'B'} 
                : {value: 'O', label: 'O'}
        }
    },[isOpenEmployee])

    useEffect(() => {
        let arr = [...selected]
        if( dataRouteById !== null){
            if (dataRouteById !== '') {
                if(stateFormEmployee === 'edit'){
                    // console.log('mantap')
                    arr[0].routeId = {label: dataRouteById.name, value: dataRouteById.id}
                }
            }
        }
        if( dataAreaById !== null){
            if (dataAreaById !== '') {
                if(stateFormEmployee === 'edit'){
                    arr[0].areaId = {label: dataAreaById.name, value: dataAreaById.id}
                }
            }
        }
        setSelected(arr)
        // console.log(arr)
    },[dataRouteById,dataAreaById])
    return stateFormEmployee === 'delete' ? (
        <Alert 
            isOpen={isOpenEmployee} 
            action={handleFormSubmit}
            text={t("Are You Sure for Delete This Data ?")}
            toggle={()=>{dispatch(openFormEmployee({open: !isOpenEmployee, type: ''}))}} 
            close={()=>{dispatch(openFormEmployee({open: !isOpenEmployee, type: ''}))}} />
    ) : (
        <ModalComponent
            size='xl'
            isOpen={isOpenEmployee} 
            close={()=>{dispatch(openFormEmployee({open: !isOpenEmployee, type: ''}))}}
            title={"Form Data "+t("Employee")}
            idForm="employee-form"
            toggle={()=>{dispatch(openFormEmployee({open: true, type: 'add'}))}}
        >
            <Wrapper style={{height: 380}}>
                <Formik 
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit} id="employee-form">
                            <div className="row" style={{width: '100%'}}>
                                <div className="col-6">
                                    <FormGroup className='mb-3'>
                                        <Label>{t("Matricule")}</Label>
                                        <Input
                                            type="text"
                                            id="nik"
                                            name="nik"
                                            value={values.nik}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={touched.nik && !!errors.nik}
                                        />
                                        <FormFeedback>{errors.nik}</FormFeedback>
                                    </FormGroup>
                                    <FormGroup className='mb-3'>
                                        <Label>{t("First Name")}</Label>
                                        <Input
                                            type="text"
                                            id="firstname"
                                            name="firstname"
                                            value={values.firstname}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={touched.firstname && !!errors.firstname}
                                        />
                                        <FormFeedback>{errors.firstname}</FormFeedback>
                                    </FormGroup>
                                    <FormGroup className='mb-3'>
                                        <Label>{t("Email")}</Label>
                                        <Input
                                            type="text"
                                            id="email"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={touched.email && !!errors.email}
                                        />
                                        <FormFeedback>{errors.email}</FormFeedback>
                                    </FormGroup>
                                    <FormGroup className='mb-3'>
                                        <Label>{t("Gender")}</Label>
                                        <Select
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderColor:
                                                    touched.gender && errors.gender
                                                    ? 'red' : 'rgba(0,0,0,.24)',
                                                width: '100%',
                                                appearance: 'auto',
                                                }),
                                            }}
                                            onChange={(e)=>{
                                                let arr = [...selected]
                                                handleChange({
                                                    target: { name: 'gender', value: e.value },
                                                })
                                                arr[0].gender = e
                                                setSelected(arr)
                                                // console.log(labelRuas)
                                                // console.log(e.value)
                                                // handleChange(e)
                                            }}
                                            name="gender"
                                            id="gender"
                                            value={selected[0].gender}
                                            options={[{label: t("Male"), value: 'M'}, {label: t("Female"), value: 'F'}]}
                                        />
                                        <FormFeedback>{errors.gender}</FormFeedback>
                                    </FormGroup>
                                </div>
                                <div className="col-6">
                                    <FormGroup className='mb-3'>
                                        <Label>ID RF Card</Label>
                                        <Input
                                            type="text"
                                            id="idRFCard"
                                            name="idRFCard"
                                            value={values.idRFCard}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={touched.idRFCard && !!errors.idRFCard}
                                        />
                                        <FormFeedback>{errors.idRFCard}</FormFeedback>
                                    </FormGroup>
                                    <FormGroup className='mb-3'>
                                        <Label>{t("Last Name")}</Label>
                                        <Input
                                            type="text"
                                            id="lastname"
                                            name="lastname"
                                            value={values.lastname}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={touched.lastname && !!errors.lastname}
                                        />
                                        <FormFeedback>{errors.lastname}</FormFeedback>
                                    </FormGroup>
                                    <FormGroup className='mb-3'>
                                        <Label>{t("Phone")}</Label>
                                        <Input
                                            type="text"
                                            id="phone"
                                            name="phone"
                                            value={values.phone}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={touched.phone && !!errors.phone}
                                        />
                                        <FormFeedback>{errors.phone}</FormFeedback>
                                    </FormGroup>
                                    <FormGroup className='mb-3'>
                                        <Label>{t("Blood Type")}</Label>
                                        <Select
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderColor:
                                                    touched.bloodType && errors.bloodType
                                                    ? 'red' : 'rgba(0,0,0,.24)',
                                                width: '100%',
                                                appearance: 'auto',
                                                }),
                                            }}
                                            onChange={(e)=>{
                                                let arr = [...selected]
                                                handleChange({
                                                    target: { name: 'bloodType', value: e.value },
                                                })
                                                arr[0].bloodType = e
                                                setSelected(arr)
                                                // console.log(labelRuas)
                                                // console.log(e.value)
                                                // handleChange(e)
                                            }}
                                            name="bloodType"
                                            id="bloodType"
                                            value={selected[0].bloodType}
                                            options={[
                                                {label: 'A', value: 'A'}, 
                                                {label: 'B', value: 'B'},
                                                {label: 'AB', value: 'AB'},
                                                {label: 'O', value: 'O'},
                                            ]}
                                        />
                                        <FormFeedback>{errors.bloodType}</FormFeedback>
                                    </FormGroup>
                                </div>
                                <div className="col-12">
                                    <FormGroup className='mb-3'>
                                        <Label>{t("Birth Date")}</Label>
                                        <Input
                                            type="date"
                                            id="birthDate"
                                            name="birthDate"
                                            value={values.birthDate}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={touched.birthDate && !!errors.birthDate}
                                        />
                                        <FormFeedback>{errors.birthDate}</FormFeedback>
                                    </FormGroup>
                                </div>
                                <div className="col-12">
                                    <FormGroup className='mb-3'>
                                        <Label>{t("Marital Status")}</Label>
                                        <Select
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderColor:
                                                    touched.maritalStatus && errors.maritalStatus
                                                    ? 'red' : 'rgba(0,0,0,.24)',
                                                width: '100%',
                                                appearance: 'auto',
                                                }),
                                            }}
                                            onChange={(e)=>{
                                                let arr = [...selected]
                                                handleChange({
                                                    target: { name: 'maritalStatus', value: e.value },
                                                })
                                                arr[0].maritalStatus = e
                                                setSelected(arr)
                                                // console.log(labelRuas)
                                                // console.log(e.value)
                                                // handleChange(e)
                                            }}
                                            name="maritalStatus"
                                            id="maritalStatus"
                                            value={selected[0].maritalStatus}
                                            options={[
                                                {label: 'Single', value: t("Single")}, 
                                                {label: 'Married', value: t("Married")},
                                            ]}
                                        />
                                        <FormFeedback>{errors.maritalStatus}</FormFeedback>
                                    </FormGroup>
                                </div>

                                <div className="col-12">
                                    <FormGroup className='mb-3'>
                                        <Label>{t("Position")}</Label>
                                        <Input
                                            type="text"
                                            id="position"
                                            name="position"
                                            value={values.position}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={touched.position && !!errors.position}
                                        />
                                        <FormFeedback>{errors.position}</FormFeedback>
                                    </FormGroup>
                                </div>
                                <div className="col-12">
                                    <FormGroup className='mb-3'>
                                        <Label>{t("Area")}</Label>
                                        <Select
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderColor:
                                                    touched.areaId && errors.areaId
                                                    ? 'red' : 'rgba(0,0,0,.24)',
                                                width: '100%',
                                                appearance: 'auto',
                                                }),
                                            }}
                                            onChange={(e)=>{
                                                let arr = [...selected]
                                                handleChange({
                                                    target: { name: 'areaId', value: e.value },
                                                })
                                                arr[0].areaId = e
                                                setSelected(arr)
                                                // console.log(labelRuas)
                                                // console.log(e.value)
                                                // handleChange(e)
                                            }}
                                            name="areaId"
                                            id="areaId"
                                            value={selected[0].areaId}
                                            options={dataSelect[0].dataArea}
                                        />
                                        <FormFeedback>{errors.areaId}</FormFeedback>
                                    </FormGroup>
                                </div>
                                <div className="col-6">
                                    <FormGroup className='mb-3'>
                                        <Label>{t("Route")}</Label>
                                        <Select
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderColor:
                                                    touched.routeId && errors.routeId
                                                    ? 'red' : 'rgba(0,0,0,.24)',
                                                width: '100%',
                                                appearance: 'auto',
                                                }),
                                            }}
                                            onChange={(e)=>{
                                                let arr = [...selected]
                                                handleChange({
                                                    target: { name: 'routeId', value: e.value },
                                                })
                                                arr[0].routeId = e
                                                setSelected(arr)
                                                param.routeId = e.value
                                                dispatch(getRouteStationByIdRoute(param))
                                                // console.log(labelRuas)
                                                // console.log(e.value)
                                                // handleChange(e)
                                            }}
                                            name="routeId"
                                            id="routeId"
                                            value={selected[0].routeId}
                                            options={dataSelect[0].dataRoute}
                                        />
                                        <FormFeedback>{errors.routeId}</FormFeedback>
                                    </FormGroup>
                                </div>

                                <div className="col-6">
                                    <FormGroup className='mb-3'>
                                        <Label>{t("Station")}</Label>
                                        <Select
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderColor:
                                                    touched.stationId && errors.stationId
                                                    ? 'red' : 'rgba(0,0,0,.24)',
                                                width: '100%',
                                                appearance: 'auto',
                                                }),
                                            }}
                                            onChange={(e)=>{
                                                let arr = [...selected]
                                                handleChange({
                                                    target: { name: 'stationId', value: e.value },
                                                })
                                                arr[0].stationId = e
                                                setSelected(arr)
                                                // console.log(labelRuas)
                                                // console.log(e.value)
                                                // handleChange(e)
                                            }}
                                            name="stationId"
                                            id="stationId"
                                            value={selected[0].stationId}
                                            options={dataSelect[0].dataStation}
                                        />
                                        <FormFeedback>{errors.stationId}</FormFeedback>
                                    </FormGroup>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </Wrapper>
        </ModalComponent>
    )
}

export default FormEnrollment