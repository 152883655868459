import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { CardHeader, Col, FormGroup, Label, Row } from 'reactstrap'
import DatatableComponent from '../components/Content/Datatable'
import Breadcrumbs from "../components/Common/Breadcrumb";
import Select from "react-select";
import { useTranslation } from 'react-i18next'
import { getNotificationBus, getNotificationBusList, updateStatusRead } from '../redux/action/notificationAction'
import moment from 'moment'

function TimeAgo({ datetime, props }) {
const [timeAgo, setTimeAgo] = useState('');
const [infoTime, setInfoTime] = useState('');
const convertLocal = datetime.split(' ')[0]+'T'+datetime.split(' ')[1]+'Z';
const localDateTime = moment.utc(convertLocal).local();
const newDateTime = localDateTime.format('YYYY-MM-DD HH:mm:ss')
useEffect(() => {
    const timeDiff = new Date() - new Date(newDateTime);
    const minutesAgo = Math.floor(timeDiff / (1000 * 60));
    if (minutesAgo >= 24 * 60) {
    const daysAgo = Math.floor(minutesAgo / (24 * 60));
    setTimeAgo(`${daysAgo}`);
    setInfoTime('days a go');
    }
    else if (minutesAgo >= 60) {
    const hoursAgo = Math.floor(minutesAgo / 60);
    setTimeAgo(`${hoursAgo}`);
    setInfoTime('hours a go');
    }
    else {
    setTimeAgo(`${minutesAgo}`);
    setInfoTime('minutes a go')
    }
}, [datetime]);

return <span>{timeAgo} {props(infoTime)}</span>;
}
const EventLog = () => {
  const {t} = useTranslation()
  const { dataNotifList, isLoadingNotifList } = useSelector((state)=>state.notification)
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState('')
  const [filter, setFilter] = useState('')
  const countPerPage = 10
  const columns = [
    {
        name: <>{t("Title")}</>,
        selector: (row) => row.title,
    },
    {
        name: <>{t("Message")}</>,
        selector: (row) => row.body,
    },
    {
        name: <>{t("Read?")}</>,
        style: {
            padding: "10px",
        },
        cell: (row) => row.isRead ? (<span className="avatar-title bg-success rounded-circle font-size-5" style={{height: '10px', width: '10px'}}>
        </span>) : (<><span className="avatar-title bg-danger rounded-circle font-size-5" style={{height: '10px', width: '10px'}}> </span><button style={{marginLeft: 10}} className='btn btn-sm btn-outline-secondary' onClick={()=>dispatch(updateStatusRead({id: row.oneSignalId, token: user.access_token}))}>Read</button></>),
    },
    {
        name: <>{t("Created At")}</>,
        cell: (row) => <TimeAgo datetime={row.createdAt} props={t} />,
    }, 
  ]
  const { user } = useSelector((state) => state.auth)
  const param = {
    token: user.access_token,
    sort: sort,
    size: countPerPage,
    filter: filter,
    page: page,
  }

  useEffect(()=>{
    dispatch(getNotificationBusList(param))
  },[page, filter, sort])
  return (
    <React.Fragment>
        <div className="page-content">
            {/* Render Breadcrumb */}
            <Breadcrumbs title={t("Event Log")} breadcrumbItem={t("Event Log")} />
            {/* Content */}
            <Row>
                {isLoadingNotifList ?
                (
                <div className="col-6 mb-3" style={{textAlign: 'right'}}>
                  <div>
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
                ) : null}
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">

                          {/* {isLoadingNotif ?
                           (
                            <>
                              <div style={{textAlign: 'center'}}>
                                <div class="spinner-grow text-primary" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-primary" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-primary" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>
                              <div style={{textAlign: 'center'}}>
                                <p style={{fontSize: '12px'}}>Loading...</p>
                              </div>
                            </>
                            ) : 
                            ( */}
                            <DatatableComponent  
                                data={dataNotifList.content}
                                page={page}
                                countPerPage={countPerPage}
                                columns={columns}
                                totalElements={dataNotifList.totalElements}
                                setPage={setPage}
                                columnFilter
                                columnSorter
                                pagination
                                tableProps={{
                                    hover: true,
                                }} 
                            />
                            {/* // )} */}
                        </div>
                    </div>
                </div>
            </Row>
        </div>
    </React.Fragment>

  )
}

export default EventLog