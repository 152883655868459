import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import DatatableComponent from '../../components/Content/Datatable'
import Datatable from '../../components/Content/Datatable'
import ModalComponent from '../../components/Content/Modal'
import { getDevicePage } from '../../redux/action/master/deviceAction'
import { openFormDevice } from '../../redux/slice/master/deviceSlice'
import FormDevice from './util/Device/FormDevice'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useTranslation } from 'react-i18next'
import FilterSelect from '../../components/Content/FilterSelect'

const Device = () => {
  const {t} = useTranslation()
  const { isOpenDevice, dataDevice, isLoadingDevice } = useSelector((state)=>state.device)
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState('')
  const [select, setSelect] = useState('')
  const [filter, setFilter] = useState([{name: '', serialNumber: '', category: '', noSim: '', masaBerlaku: ''}])
  const countPerPage = 10
  const onChange = (e, field) => {
    let arr = [...filter]
    if (field === 'name') {
      arr[0].name = e.target.value
    }
    if (field === 'serialNumber') {
      arr[0].serialNumber = e.target.value
    }
    if (field === 'category') {
      arr[0].category = e
    }
    if (field === 'noSim') {
      arr[0].noSim = e.target.value
    }
    if (field === 'masaBerlaku') {
      arr[0].masaBerlaku = e.target.value
    }
    setFilter(arr)
  }

  const columns = [
    {
        name: (
          <div className='row' style={{width: '100%', marginBottom: '13px'}}>
            <Col lg={12} md={12} sm={12} xs={12} className='mb-2'>
              {t("Name")}
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <input type='text' onChange={(e)=> onChange(e, 'name')} className='form-control' placeholder={'Search By '+ t("Name")} />
            </Col>
          </div>
        ),
        selector: (row) => row.name,
    },
    {
      name: (
        <div className='row' style={{width: '100%', marginBottom: '13px'}}>
          <Col lg={12} md={12} sm={12} xs={12} className='mb-2'>
            {t("Serial Number")}
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <input type='text' onChange={(e)=> onChange(e, 'serialNumber')} className='form-control' placeholder={'Search By '+ t("Serial Number")} />
          </Col>
        </div>
      ),
      selector: (row) => row.serialNumber,
    },
    {
      name: (
        <div className='row' style={{width: '100%', marginBottom: '13px'}}>
          <Col lg={12} md={12} sm={12} xs={12} className='mb-2'>
            {t("Category")}
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <FilterSelect 
              options={[{value: 'ALPR', label: 'ALPR'}, {value: 'Bus Device', label: 'Bus Device'}]} 
              onChange={(e)=>onChange(e,'category')} 
            />
          </Col>
        </div>
      ),
      selector: (row) => row.category,
    },
    {
      name: (
        <div className='row' style={{width: '100%', marginBottom: '13px'}}>
          <Col lg={12} md={12} sm={12} xs={12} className='mb-2'>
            {t("No. SIM")}
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <input type='text' onChange={(e)=> onChange(e, 'noSim')} className='form-control' placeholder={'Search By '+ t("No. SIM")} />
          </Col>
        </div>
      ),
      selector: (row) => row.noSim !=='' ? row.noSim : '-',
    },
    {
      name: (
        <div className='row' style={{width: '100%', marginBottom: '13px'}}>
          <Col lg={12} md={12} sm={12} xs={12} className='mb-2'>
            {t("Expired Date")}
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <input type='date' onChange={(e)=> onChange(e, 'masaBerlaku')} className='form-control' placeholder={'Search By '+ t("Expired Date")} />
          </Col>
        </div>
      ),
      selector: (row) => row.masaBerlaku !== '' ? row.masaBerlaku : '',
    },
    {
        name: <>{t("Quota")}</>,
        selector: (row) => row.jumlahQuota !== '' ? row.jumlahQuota : '',
    },
    {
      name: <>{t("Action")}</>,
      cell: (row) => (
        <div style={{ padding: '10px 0' }}>
          <button 
            className='btn btn-outline-secondary btn-sm' 
            onClick={()=>{
              dispatch(openFormDevice({open: !isOpenDevice, type: 'edit',data: row, id: row.id}))
            }}
            style={{marginRight: 5}}>
              <>{t("Edit")}</>
            </button> 
            <button 
            className='btn btn-danger btn-sm' 
            onClick={()=>{
              dispatch(openFormDevice({open: !isOpenDevice, type: 'delete',data: row, id: row.id}))
            }} 
            style={{marginRight: 5}}>
              <>{t("Delete")}</>
            </button>
        </div>
      ),
    },
  ]
  const { user } = useSelector((state) => state.auth)
  const param = {
    token: user.access_token,
    sort: sort,
    size: countPerPage,
    filter: filter,
    page: page,
  }

  useEffect(()=>{
    dispatch(getDevicePage(param))
  },[page, filter, select, sort])
  return (
    <React.Fragment>
        <div className="page-content">
            {/* Render Breadcrumb */}
            <Breadcrumbs title={t("Master Data")} breadcrumbItem={t("Device")} />
            {/* Content */}
            <Row>
                <div className="col-6 mb-3">
                    <button className="btn btn-primary waves-effect waves-light" style={{marginRight: 5}}
                        onClick={()=>{dispatch(openFormDevice({open: !isOpenDevice, type: 'add'}))}}
                        data-toggle="modal"
                        data-target=".bs-example-modal-center">
                          + {t("Add Data")}
                    </button>
                </div>
                <div className="col-6 mb-3" style={{textAlign: 'right'}}>
                {isLoadingDevice ?
                (
                  <div>
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <button className="btn btn-primary waves-effect waves-light"
                      onClick={()=>setFilter([{name: '', serialNumber: '', category: '', noSim: '', masaBerlaku: ''}])}>
                        <i className="mdi mdi-refresh"></i> Reset Filter
                  </button>
                )}
                </div>
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">

                          {/* {isLoadingDevice ?
                           (
                            <>
                              <div style={{textAlign: 'center'}}>
                                <div class="spinner-grow text-primary" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-primary" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-primary" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>
                              <div style={{textAlign: 'center'}}>
                                <p style={{fontSize: '12px'}}>Loading...</p>
                              </div>
                            </>
                            ) : 
                            ( */}
                            <DatatableComponent  
                                data={dataDevice.content}
                                page={page}
                                countPerPage={countPerPage}
                                columns={columns}
                                totalElements={dataDevice.totalElements}
                                setPage={setPage}
                                columnFilter
                                columnSorter
                                pagination
                                tableProps={{
                                    hover: true,
                                }} 
                            />
                            {/* )} */}
                        </div>
                    </div>
                </div>
            </Row>
        </div>
        <FormDevice isOpen={isOpenDevice} onClose={()=> dispatch(openFormDevice({open: !isOpenDevice, type: ''}))} param={param} />
    </React.Fragment>

  )
}

export default Device