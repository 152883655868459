import { applyMiddleware, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducer from "./slice/authSlice";
import layoutReducer from "./slice/layoutSlice";
import profileReducer from "./slice/profileSlice";
import busproviderReducer from "./slice/master/busproviderSlice";
import busReducer from "./slice/master/busSlice";
import deviceReducer from "./slice/master/deviceSlice";
import areaReducer from "./slice/master/areaSlice";
import factoryReducer from "./slice/master/factorySlice";
import roleReducer from "./slice/master/roleSlice";
import routeReducer from "./slice/master/routeSlice";
import shiftReducer from "./slice/master/shiftSlice";
import stationReducer from "./slice/master/stationSlice";
import userReducer from "./slice/master/userSlice";
import messageReducer from "./slice/messageSlice";
import weeklyScheduleReducer from "./slice/master/weeklyScheduleSlice";
import enrollmentReducer from "./slice/master/enrollmentSlice";
import employeeReducer from "./slice/master/employeeSlice";
import dashboardReducer from "./slice/dashboardSlice";
import busLogReducer from "./slice/busLogSlice";
import busTrackingReducer from "./slice/busTrackingSlice";
import notificationReducer from "./slice/notificationSlice";

import createSagaMiddleware from "redux-saga";
import rootSaga from "../store/sagas";
import thunk from "redux-thunk";

const saga = createSagaMiddleware();
export const Store = configureStore({
  reducer: {
    auth: authReducer,
    layout: layoutReducer,
    profile: profileReducer,
    bus: busReducer,
    busProvider: busproviderReducer,
    device: deviceReducer,
    area: areaReducer,
    factory: factoryReducer,
    role: roleReducer,
    route: routeReducer,
    shift: shiftReducer,
    station: stationReducer,
    user: userReducer,
    weeklySchedule: weeklyScheduleReducer,
    message: messageReducer,
    enrollment: enrollmentReducer,
    employee: employeeReducer,
    dashboard: dashboardReducer,
    busLog: busLogReducer,
    busTracking: busTrackingReducer,
    notification: notificationReducer
  },
  middleware: [saga, thunk],
});
saga.run(rootSaga);
