import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ModalComponent from '../../../components/Content/Modal'
import { openModalRole, openModalUser } from '../../../redux/slice/master/roleSlice'
import { Col } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { DualListBox } from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { useTranslation } from 'react-i18next'

const FormUserList = ({ dataListUser, selectedUser, onChange }) => {

    const { isOpenModalUser } = useSelector((state) => state.role)
    const dispatch = useDispatch()

    const {t} = useTranslation()
    return (
        <ModalComponent
            isOpen={isOpenModalUser}
            close={() => { dispatch(openModalUser({ open: !isOpenModalUser })) }}
            title={"Data "+t("User")}
            size="md"
            toggle={() => { dispatch(openModalUser({ open: true, type: 'add' })) }}
        >
            <div className="row">
                <Col xs={12} sm={12} lg={12} md={12}>
                    <DualListBox
                        options={dataListUser}
                        selected={selectedUser}
                        onChange={onChange}
                        canFilter
                        showOrderButtons
                        filterPlaceholder="Filter..."
                        icons={{
                            moveLeft: <i className="fa fa-angle-left" />,
                            moveAllLeft: <i className="fa fa-angle-double-left" />,
                            moveRight: <i className="fa fa-angle-right" />,
                            moveAllRight: <i className="fa fa-angle-double-right" />,
                            moveDown: <i className="fa fa-angle-down" />,
                            moveUp: <i className="fa fa-angle-up" />,
                        }}
                    />
                </Col>
            </div>
        </ModalComponent>
    )
}

export default FormUserList