import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ModalComponent from '../../../../components/Content/Modal'
import { openFormRoute, openRouteStation } from '../../../../redux/slice/master/routeSlice'
import { Col, FormFeedback, FormGroup, Input, Label } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { DualListBox } from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';

const FormEmployeeSchedule = ({dataEmployee,selectedStation, onChange}) => {
    return  (
        <DualListBox
            options={dataEmployee}
            selected={selectedStation}
            onChange={onChange}
            canFilter
            showOrderButtons
            filterPlaceholder="Filter..."
            icons={{
            moveLeft: <i className="fa fa-chevron-left" />,
            moveAllLeft: <i className="fas fa-angle-double-left" />,
            moveRight: <i className="fa fa-chevron-right" />,
            moveAllRight: <i className="fas fa-angle-double-right" />,
            moveDown: <i className="fa fa-chevron-down" />,
            moveUp: <i className="fa fa-chevron-up" />,
            }}
        />
    )
}

export default FormEmployeeSchedule